import { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { TbCopy } from "react-icons/tb";
import Tooltip from "../../components/Tooltip";
import { getUser } from "../../utils/helpers";
import { useDispatch } from "react-redux";
import useTDLibController from "../../controllers/useTDLibController";
import { getApiKey } from "../../redux/slices/chatSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FaPlus, FaSpinner } from "react-icons/fa";
import { Button } from "../../components/Button";
import {
  cancelSubscription,
  checkPaymentInfo,
} from "../../redux/slices/authSlice";
import SixVeriticleDots from "../../icons/SixVerticleDots";
import { Input } from "../../components/Input";
import { TrashIcon } from "../../icons";
import {
  deleteStages,
  getStages,
  postCreateStage,
  updateStages,
} from "../../redux/slices/stageSlice";

const SettingsPage = () => {
  const { tdClient } = useTDLibController();
  const dispatch = useDispatch();
  const [tooltipText, setTooltipText] = useState("Copy");
  const {
    apiKeyState,
    apiKeyLoading,
    getStagesState,
    updateStagesLoading,
    getStagesLoading,
  } = useSelector((state: RootState) => ({
    apiKeyState: state.chat.apiKeyState.data,
    apiKeyLoading: state.chat.apiKeyState.loading,
    getStagesState: state.stage.getStagesState.data,
    updateStagesLoading: state.stage.updateStagesState.loading,
    getStagesLoading: state.stage.getStagesState.loading,
  }));
  const handleCopyKey = (key: string) => {
    navigator.clipboard.writeText(key);
    setTooltipText("Copied!");
    setTimeout(() => {
      setTooltipText("Copy");
    }, 2000);
  };

  const getApiKeyData = async () => {
    let user = getUser();
    if (!user?.id) {
      user = await tdClient?.send({ "@type": "getMe" });
    }

    if (user?.id) {
      localStorage.setItem("user", JSON.stringify(user));
      dispatch(
        // @ts-ignore
        getApiKey({
          // @ts-ignore
          data: { telegramId: user.id },
          onSuccess: (res: any) => {},
          onError: (err: any) => {
            console.log(err);
          },
        })
      );
    }
  };

  const callGetStages = async () => {
    let user = getUser();
    if (!user?.id) {
      user = await tdClient?.send({ "@type": "getMe" });
    }
    if (user?.id) {
      localStorage.setItem("user", JSON.stringify(user));
      dispatch(
        // @ts-ignore
        getStages({
          // @ts-ignore
          data: { telegramId: user.id },
          onSuccess: (res: any) => {},
          onError: (err: any) => {
            console.log(err);
          },
        })
      );
    }
  };
  useEffect(() => {
    getApiKeyData();
    callGetStages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [items, setItems] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [focusedInputId, setFocusedInputId] = useState<number | null>(null);
  const [deletingStageId, setDeletingStageId] = useState<string | null>(null);
  const [newStages, setNewStages] = useState<any[]>([]);
  const [postedStages, setPostedStages] = useState<any[]>([]);

  useEffect(() => {
    if (getStagesState?.data) {
      const transformedItems = getStagesState.data.map((stage: any) => ({
        ...stage,
        isDragging: false,
      }));
      setItems(transformedItems);
    }
  }, [getStagesState]);

  const handleDragStart = (id: string) => (event: any) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.stageId === id ? { ...item, isDragging: true } : item
      )
    );
    event.dataTransfer.setData("text/plain", id);
    event.dataTransfer.effectAllowed = "move";
  };

  const handleDragEnd = (id: string) => () => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.stageId === id ? { ...item, isDragging: false } : item
      )
    );
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (targetId: string) => (event: any) => {
    event.preventDefault();
    const draggedItemId = event.dataTransfer.getData("text/plain");

    if (draggedItemId && draggedItemId !== targetId) {
      setItems((prevItems) => {
        const draggedIndex = prevItems.findIndex(
          (item) => item.stageId === draggedItemId
        );
        const targetIndex = prevItems.findIndex(
          (item) => item.stageId === targetId
        );
        const reorderedItems = [...prevItems];
        const [draggedItem] = reorderedItems.splice(draggedIndex, 1);
        reorderedItems.splice(targetIndex, 0, draggedItem);
        const updatedItems = reorderedItems.map((item, index) => ({
          ...item,
          stageRank: index + 1,
        }));

        return updatedItems;
      });
    }
  };

  const handleDeleteStage = (index: number, id: string | null) => {
    if (id) {
      setDeletingStageId(id);
      dispatch(
        // @ts-ignore
        deleteStages({
          data: {
            telegramId: getUser()?.id,
            stageIds: [id],
          },
          onSuccess: (res: any) => {
            setItems(items.filter((item) => item.stageId !== id));
            setDeletingStageId(null);
          },
          onError: (err: any) => {
            setDeletingStageId(null);
          },
        })
      );
    } else {
      const updatedItems = [...items];
      updatedItems.splice(index, 1);
      setItems(updatedItems);
    }
  };

  useEffect(() => {
    const calculatedNewStages = items.filter(
      (item) =>
        !item.stageId &&
        item.stageName.trim() &&
        !postedStages.some((posted) => posted.stageName === item.stageName)
    );
    setNewStages(calculatedNewStages);
  }, [items, postedStages]);

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleFocus = (id: any) => {
    setFocusedInputId(id);
  };

  const handleBlur = () => {
    setFocusedInputId(null);
  };

  const handleAddStage = () => {
    const newStage = {
      stageName: "",
      isDragging: false,
    };

    setItems([...items, newStage]);
  };

  const handleStageNameChange = (index: number, value: string) => {
    const updatedItems = [...items];
    updatedItems[index].stageName = value;
    setItems(updatedItems);
  };

  const handlePostStage = () => {
    const unpostedStages = newStages.filter(
      (item) =>
        !postedStages.some((posted) => posted.stageName === item.stageName)
    );

    if (unpostedStages.length === 0) return;
    unpostedStages.forEach((item) => {
      dispatch(
        // @ts-ignore
        postCreateStage({
          data: {
            telegramId: getUser()?.id,
            stageName: item.stageName,
          },
          onSuccess: (res: any) => {
            const newStageId = res.data.stageId;
            setItems((prevItems) =>
              prevItems.map((stage) =>
                stage.stageName === item.stageName
                  ? { ...stage, stageId: newStageId }
                  : stage
              )
            );
            setPostedStages((prevPosted) => [...prevPosted, item]);
          },
          onError: (err: any) => {
            console.error("Error creating stage:", err);
          },
        })
      );
    });
  };

  const handleUpdateStages = () => {
    const updatedStages = items.map((item) => ({
      stageId: item.stageId,
      stageName: item.stageName,
      stageRank: item.stageRank,
    }));

    dispatch(
      // @ts-ignore
      updateStages({
        data: {
          telegramId: getUser()?.id,
          updateData: updatedStages,
        },
        onSuccess: (res: any) => {},
        onError: (err: any) => {},
      })
    );
  };

  return (
    <main className="min-h-screen">
      <Header />
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col my-16 w-[400px] relative bg-[#0E0F10] rounded-2xl p-6">
          <h1 className="text-xl leading-[25.2px] font-plus-jakarta text-tgpro-white-1 font-semibold">
            API Key
          </h1>
          <span className="text-sm text-tgpro-white-3 mt-1 mb-3">
            Your identifier for this organization sometimes used in API requests
          </span>
          <div className="text-tgpro-grey-6 flex gap-2 items-center justify-between bg-tgpro-black-2 px-4 py-3 rounded-lg">
            {apiKeyLoading ? (
              <FaSpinner className="text-tgpro-grey-6 animate-spin" />
            ) : (
              <span className="text-tgpro-grey-6 text-sm truncate">
                {apiKeyState?.data?.apiKey}
              </span>
            )}
            <Tooltip text={tooltipText}>
              <TbCopy
                className="w-5 h-5 cursor-pointer"
                onClick={() => handleCopyKey(apiKeyState?.data?.apiKey)}
              />
            </Tooltip>
          </div>
          <div className="border-b border-tgpro-black-3 w-full my-10" />
          <span className="text-sm font-semibold text-white">Pipeline</span>
          {getStagesLoading ? (
            <FaSpinner className="text-tgpro-grey-6 animate-spin w-full my-3" />
          ) : (
            <div>
              <div className="flex items-center justify-between mt-[26px]">
                <span className="text-sm font-semibold text-white">
                  Stage Name
                </span>

                {focusedInputId === null && (
                  <button
                    className="text-xs font-semibold text-tgpro-blue-1"
                    onClick={toggleEditMode}
                  >
                    Edit
                  </button>
                )}
              </div>
              <div>
                {items?.map((item, index) => (
                  <div key={item.stageId}>
                    <div
                      className={` ${
                        item.isDragging ? "dragging" : ""
                      } mt-3 flex gap-4 items-center`}
                      draggable={isEditing}
                      onDragStart={
                        isEditing ? handleDragStart(item.stageId) : undefined
                      }
                      onDragEnd={
                        isEditing ? handleDragEnd(item.stageId) : undefined
                      }
                      onDragOver={isEditing ? handleDragOver : undefined}
                      onDrop={isEditing ? handleDrop(item.stageId) : undefined}
                    >
                      <div>
                        <SixVeriticleDots
                          className={`text-tgpro-grey-6 w-2.5 h-4 ${
                            isEditing ? "cursor-pointer" : "cursor-not-allowed"
                          }`}
                        />
                      </div>
                      <div className="w-full">
                        <Input
                          placeholder=""
                          postFix={
                            deletingStageId === item.stageId ? (
                              <FaSpinner className="h-5 w-5 text-tgpro-blue-1 animate-spin" />
                            ) : (
                              <TrashIcon
                                className={`${
                                  isEditing
                                    ? "text-tgpro-red-1 cursor-pointer"
                                    : "text-tgpro-grey-6 opacity-50 cursor-not-allowed"
                                }`}
                                onClick={() => {
                                  if (isEditing) {
                                    handleDeleteStage(index, item.stageId);
                                  }
                                }}
                              />
                            )
                          }
                          value={item.stageName}
                          onChange={(e) =>
                            handleStageNameChange(index, e.target.value)
                          }
                          className={`placeholder-tgpro-grey-6 text-sm ${
                            !isEditing
                              ? "bg-tgpro-black-3 cursor-not-allowed"
                              : ""
                          }`}
                          disabled={!isEditing}
                          onFocus={() => handleFocus(item.stageId)}
                          onBlur={() => {
                            handleBlur();
                            if (!item.stageId && item.stageName.trim()) {
                              handlePostStage();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <span className="text-10 text-tgpro-grey-6 ml-6">
                      Select to edit the name
                    </span>
                  </div>
                ))}
              </div>
              <Button
                className="bg-tgpro-blue-1 bg-opacity-[15%] py-3 rounded-md w-full flex gap-2 items-center justify-center mt-3"
                onClick={handleAddStage}
                name="Add a New Stage"
                preFix={<FaPlus className="text-tgpro-blue-1 w-2.5 h-2.5" />}
                textClassName="!text-tgpro-blue-1 !text-xs !font-medium"
              />
              <Button
                className={`py-3 w-full rounded-lg mt-4`}
                onClick={handleUpdateStages}
                name="Confirm Changes"
                textClassName="!text-white text-white text-11 font-medium"
                loading={updateStagesLoading}
                disabled={updateStagesLoading}
              />
              <div className="border-b border-tgpro-black-3 w-full mt-10 mb-9" />
            </div>
          )}
          <Button
            className="bg-transparent px-10 rounded-lg"
            textClassName="text-sm !text-tgpro-red-1 font-semibold"
            onClick={async () => {
              const user = getUser();
              dispatch(
                // @ts-ignore
                cancelSubscription({
                  data: {
                    telegramId: user.id,
                    telegram_handle: user.username,
                  },
                  onSuccess: () => {
                    dispatch(
                      // @ts-ignore
                      checkPaymentInfo({
                        data: {
                          telegramId: user.id,
                          telegram_handle: user.username,
                        },
                      })
                    );
                  },
                })
              );
            }}
            name="Unsubscribe"
          />{" "}
          <Button
            className="bg-tgpro-red-1 py-3 px-10 rounded-lg mt-5"
            textClassName="text-sm text-white font-semibold"
            onClick={async () => {
              localStorage.clear();
              sessionStorage.clear();

              const dbs = await indexedDB.databases();
              dbs.forEach((db) => indexedDB.deleteDatabase(db.name as string));

              tdClient?.send({ "@type": "logOut" }).then(() => {
                window.location.reload();
              });
            }}
            name="Logout"
          />
        </div>
      </div>
    </main>
  );
};
export default SettingsPage;
