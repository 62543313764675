/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { MdClose, MdStar } from "react-icons/md";
import { RiAttachment2, RiSendPlaneFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedTimeFromUTC, getUser } from "../../utils/helpers";
import useTDLibController from "../../controllers/useTDLibController";
import { RootState } from "../../redux/store";
import {
  addFavouriteChat,
  deleteChatMessages,
  getLinkedChats,
  resetChatMessages,
  setChatMessages,
  setOpenChat,
  unlinkChats,
  updateForwardMessagesModalState,
  updateMessageText,
} from "../../redux/slices/chatSlice";
import { Button } from "../../components/Button";
import InfiniteScroll from "react-infinite-scroll-component";
import { Message } from "./Message";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FollowUpTag } from "../../components/FollowUpTag";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { HiEmojiHappy, HiOutlineTrash } from "react-icons/hi";
import { FaCheck } from "react-icons/fa";
import { LuForward, LuReply } from "react-icons/lu";
import { IoCopyOutline } from "react-icons/io5";
import { BsCheckAll } from "react-icons/bs";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Textarea } from "../../components/Textarea";
import UserMinusIcon from "../../icons/UserMinusIcon";
import Tooltip from "../../components/Tooltip";
import { FollowUp } from "../../types";
import { ReplyMarkup } from "./ReplyMarkup";

const MessageReadIcon = ({ chat, message }: any) => {
  const { chatReadInboxOutboxData } = useSelector((state: RootState) => ({
    chatReadInboxOutboxData: state.chat.chatReadInboxOutboxData,
  }));

  const isMessageUnread = () =>
    message?.is_outgoing
      ? message?.id > chatReadInboxOutboxData?.lastReadOutboxMessageId!
      : message?.id > chat?.last_read_inbox_message_id;

  return !isMessageUnread() ? (
    <BsCheckAll className="h-5 w-5" />
  ) : (
    <FaCheck className="h-3 w-3" />
  );
};

export const Chat = () => {
  const dispatch = useDispatch();
  const user = getUser();

  const { tdClient, isReady } = useTDLibController();

  const {
    openedChatState,
    chatMessagesState,
    addFavouriteChatStateLoading,
    messageText,
  } = useSelector((state: RootState) => ({
    openedChatState: state.chat.openedChatState,
    chatMessagesState: state.chat.chatMessagesState.data,
    addFavouriteChatStateLoading: state.chat.addFavouriteChatState.loading,
    messageText: state.chat.messageText,
  }));

  const [supergroupFullInfo, setSupergroupFullInfo] = useState<any>();
  const [basicgroupFullInfo, setBasicgroupFullInfo] = useState<any>();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [firstTime, setFirstTime] = useState(true);
  const [followUp, setFollowUp] = useState<any>({});
  const [messageForContextMenu, setMessageForContextMenu] = useState<any>(null);

  const messageForContextMenuRef = useRef(null);

  const pickerRef = useRef<HTMLInputElement>(null);
  useOutsideClick(pickerRef, () => setShowEmojiPicker(false));

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useOutsideClick(messageForContextMenuRef, () =>
    setMessageForContextMenu(null)
  );

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (openedChatState && openedChatState.data.id) {
      dispatch(
        updateMessageText(
          openedChatState.data?.draft_message?.input_message_text?.text?.text ??
            ""
        )
      );
    }
  }, [openedChatState]);

  const handleMessageChange = (e: any) => {
    dispatch(updateMessageText(e.target.value));
  };

  const followUps: FollowUp[] = openedChatState?.data?.dbData?.followUps;

  const incompleteFollowUps = followUps.filter((x: any) => !x.isCompleted);

  const expiredIncompleteFollowUps = incompleteFollowUps.filter(
    (x: any) => x.isExpired
  );

  let activeFollowUp: FollowUp | undefined;

  if (expiredIncompleteFollowUps.length > 0) {
    expiredIncompleteFollowUps.sort(
      (a: any, b: any) =>
        new Date(a.followUpDate).getTime() - new Date(b.followUpDate).getTime()
    );
    activeFollowUp = expiredIncompleteFollowUps[0];
  } else {
    activeFollowUp = incompleteFollowUps[0];
  }

  useEffect(() => {
    if (firstTime && activeFollowUp?.followUpId) {
      setFollowUp(activeFollowUp);
      setFirstTime(false);
    }
  }, [activeFollowUp]);

  useEffect(() => {
    if (!firstTime) {
      if (activeFollowUp?.followUpId && !followUp) {
        setFollowUp(activeFollowUp);
      }
      if (followUp && activeFollowUp?.followUpId === followUp.followUpId) {
        setFollowUp(activeFollowUp);
      }
      if (
        followUp &&
        activeFollowUp?.followUpId &&
        activeFollowUp?.followUpId !== followUp.followUpId
      ) {
        setFollowUp(activeFollowUp);
      }
      if (!activeFollowUp?.followUpId && followUp) {
        setFollowUp(
          openedChatState.data?.dbData?.followUps.find(
            (f: any) => f.followUpId === followUp.followUpId
          )
        );
      }
    }
  }, [activeFollowUp, firstTime, followUp]);

  const [replyOnMessage, setReplyOnMessage] = useState<{
    started: boolean;
    senderId: number;
    message: any;
  }>({
    started: false,
    senderId: 0,
    message: {},
  });

  // const [data, setData] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
  const MAX_DATA = 3000;
  const hasMore = chatMessagesState?.data?.length < MAX_DATA;

  // function fetchData(limit = 10) {
  // 	const start = data.length + 1
  // 	const end = data.length + limit >= MAX_DATA ? MAX_DATA : data.length + limit
  // 	let newData = [...data]

  // 	for (var i = start; i <= end; i++) {
  // 		newData = [...newData, i]
  // 	}

  // 	// fake delay to simulate a time-consuming network request
  // 	setTimeout(() => setData(newData), 1500)
  // }

  const [againGetMessage, setAgainGetMessage] = useState(false);
  const [unLinkLoading, setUnLinkLoading] = useState(false);
  const getMessagesAgain = async () => {
    tdClient
      ?.send({
        "@type": "getChatHistory",
        chat_id: openedChatState?.data?.id,
        from_message_id: 0,
        offset: 0,
        limit: 99,
        only_local: false,
      })
      .then((result: any) => {
        dispatch(setChatMessages(result?.messages || []));
        tdClient?.send({
          "@type": "viewMessages",
          chat_id: openedChatState.data?.id,
          message_ids: [result?.messages?.[0].id],
        });
        // .then((res) => console.log({ vm: res }))
        // .catch((err) => console.log({ evm: err }));
      })
      .catch((error: any) => console.log({ error }));
  };

  useEffect(() => {
    if (chatMessagesState.data?.length) {
      tdClient?.send({
        "@type": "viewMessages",
        chat_id: openedChatState.data?.id,
        message_ids: [chatMessagesState.data?.[0].id],
      });
    }
  }, [chatMessagesState]);

  useEffect(() => {
    if (againGetMessage) {
      const interval = setTimeout(() => {
        getMessagesAgain();
        clearInterval(interval);
      }, 200);
    }
  }, [againGetMessage]);

  const getMessages = async () => {
    tdClient
      ?.send({
        "@type": "getChatHistory",
        chat_id: openedChatState?.data?.id,
        from_message_id: 0,
        offset: 0,
        limit: 99,
        only_local: false,
      })
      .then(() => {
        const interval = setTimeout(() => {
          setAgainGetMessage(true);
          clearInterval(interval);
        }, 200);
      })
      .catch((error: any) => console.log({ error }));
  };

  useEffect(() => {
    if (isReady) {
      setAgainGetMessage(false);
      dispatch(resetChatMessages([]));
      getMessages();
    }
  }, [tdClient, openedChatState]);

  const getSuperGroupFullInfo = async () => {
    tdClient
      ?.send({
        "@type": "getSupergroupFullInfo",
        supergroup_id: openedChatState?.data?.type?.supergroup_id,
      })
      .then((result: any) => {
        tdClient
          ?.send({
            "@type": "getSupergroupMembers",
            supergroup_id: openedChatState?.data?.type?.supergroup_id,
            limit: 200,
          })
          .then(async (result: any) => {
            setSupergroupFullInfo({
              ...result,
              members: await Promise.all(
                result?.members.map(async (member: any) => ({
                  ...member,
                  data: await tdClient?.send({
                    "@type": "getUser",
                    user_id: member?.member_id?.user_id,
                  }),
                }))
              ),
            });
          })
          .catch((error: any) => console.log({ error }));
      })
      .catch((error: any) => console.log({ error }));
  };

  useEffect(() => {
    if (
      isReady &&
      openedChatState?.data?.type?.["@type"] === "chatTypeSupergroup"
    ) {
      getSuperGroupFullInfo();
    }
  }, [tdClient, openedChatState]);

  const getBasicGroupFullInfo = async () => {
    tdClient
      ?.send({
        "@type": "getBasicGroupFullInfo",
        basic_group_id: openedChatState?.data?.type?.basic_group_id,
      })
      .then(async (result: any) => {
        setBasicgroupFullInfo({
          ...result,
          members: await Promise.all(
            result?.members.map(async (member: any) => ({
              ...member,
              data: await tdClient?.send({
                "@type": "getUser",
                user_id: member?.member_id?.user_id,
              }),
            }))
          ),
        });
      })
      .catch((error: any) => console.log({ error }));
  };

  useEffect(() => {
    if (
      isReady &&
      openedChatState?.data?.type?.["@type"] === "chatTypeBasicGroup"
    ) {
      getBasicGroupFullInfo();
    }
  }, [tdClient, openedChatState]);

  const sendMessage = async () => {
    if (messageText) {
      setReplyOnMessage({ started: false, senderId: 0, message: null });
      await tdClient?.send({
        "@type": "sendMessage",
        chat_id: openedChatState.data?.id,
        reply_to_message_id: replyOnMessage?.started
          ? replyOnMessage?.message?.id
          : null,
        // reply_to: { message_id: 0 },
        input_message_content: {
          "@type": "inputMessageText",
          text: {
            "@type": "formattedText",
            text: messageText,
            entities: [],
          },
          disable_web_page_preview: false,
          clear_draft: true,
        },
      });
      dispatch(updateMessageText(""));
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }
    }
  };

  const sendMedia = async (media: File) => {
    setReplyOnMessage({ started: false, senderId: 0, message: null });
    if (media.type.startsWith("image/")) {
      var fr = new FileReader();

      fr.onload = function () {
        var img = new Image();

        img.onload = async function () {
          await tdClient?.send({
            "@type": "sendMessage",
            chat_id: openedChatState.data?.id,
            reply_to_message_id: replyOnMessage?.started
              ? replyOnMessage?.message?.id
              : null,
            // @ts-ignore
            input_message_content: {
              "@type": "inputMessagePhoto",
              photo: {
                "@type": "inputFileBlob",
                name: media?.name,
                data: media,
              },
              width: img.width,
              height: img.height,
              caption: {
                "@type": "formattedText",
                text: "",
                entities: [],
              },
            },
          });
        };

        // @ts-ignore
        img.src = fr.result;
      };

      fr.readAsDataURL(media);
    } else {
      // Handle non-image files
      await tdClient?.send({
        "@type": "sendMessage",
        chat_id: openedChatState.data?.id,
        reply_to_message_id: replyOnMessage?.started
          ? replyOnMessage?.message?.id
          : null,
        // @ts-ignore
        input_message_content: {
          "@type": "inputMessageDocument",
          document: {
            "@type": "inputFileBlob",
            name: media?.name,
            data: media,
          },
          caption: {
            "@type": "formattedText",
            text: "",
            entities: [],
          },
        },
      });
    }

    dispatch(updateMessageText(""));
  };

  const colors: string[] = [
    "text-cyan-500",
    "text-amber-500",
    "text-pink-500",
    "text-green-500",
    "text-yellow-500",
    "text-fuchsia-500",
    // Add more colors as needed
  ];

  let usedColors: string[] = [];

  const getRandomColor = () => {
    if (usedColors.length < colors.length) {
      const unusedColors = colors.filter(
        (color) => !usedColors.includes(color)
      );
      const color =
        unusedColors[Math.floor(Math.random() * unusedColors.length)];
      usedColors.push(color);
      return color;
    } else {
      return colors[Math.floor(Math.random() * colors.length)];
    }
  };

  interface UserColorMap {
    [key: number]: string;
  }
  const userColorMapRef = useRef<UserColorMap>({});

  const getUserColor = (userId: number): string => {
    if (user?.id === userId) {
      return "text-tgpro-blue-1";
    }
    if (!userColorMapRef.current[userId]) {
      userColorMapRef.current[userId] = getRandomColor();
    }
    return userColorMapRef.current[userId];
  };

  const getMessageTitleUsername = ({ messageUserId = 0 }) => {
    return user?.id === messageUserId
      ? "You"
      : openedChatState?.data?.type?.["@type"] === "chatTypeSupergroup"
      ? supergroupFullInfo?.members?.find(
          (member: any) => member?.data?.id === messageUserId
        )?.data?.first_name || openedChatState.data?.title
      : openedChatState?.data?.type?.["@type"] === "chatTypeBasicGroup"
      ? basicgroupFullInfo?.members?.find(
          (member: any) => member?.data?.id === messageUserId
        )?.data?.first_name
      : openedChatState.data?.title;
  };

  const getLinkedChatsData = async () => {
    let user = getUser();
    if (!user?.id) {
      user = await tdClient?.send({ "@type": "getMe" });
    }

    if (user?.id) {
      localStorage.setItem("user", JSON.stringify(user));
      setUnLinkLoading(true);
      dispatch(
        // @ts-ignore
        getLinkedChats({
          // @ts-ignore
          data: { telegramId: user.id },
          onSuccess: (res: any) => {
            setUnLinkLoading(false);
          },
          onError: () => {
            setUnLinkLoading(false);
          },
        })
      );
    }
  };

  const onUnLinkChats = () => {
    setUnLinkLoading(true);
    const user = getUser();
    dispatch(
      // @ts-ignore
      unlinkChats({
        data: {
          telegramId: user.id,
          chatIds: [openedChatState.data.id] || [],
        },
        onSuccess: () => {
          dispatch(setOpenChat(null));
          dispatch(setChatMessages([]));
          getLinkedChatsData();
          setUnLinkLoading(false);
        },
        onError: () => {
          setUnLinkLoading(false);
        },
      })
    );
  };

  console.log({ msgs: chatMessagesState });

  return (
    <div className="flex-1 flex flex-col pt-4 max-h-[calc(100vh-49px)]">
      <div className="flex flex-col items-center gap-2 w-full relative p-4">
        <div className="flex items-center justify-between w-full">
          <h2 className="text-13 leading-16 font-normal font-plus-jakarta text-tgpro-white-1">
            {openedChatState.data?.title}
          </h2>
          <div className="flex items-center gap-2">
            {/* <PiMagnifyingGlass className="text-tgpro-grey-1" /> */}
            <button onClick={onUnLinkChats} disabled={unLinkLoading}>
              <Tooltip text="Unsync chat">
                <UserMinusIcon />
              </Tooltip>
            </button>

            {addFavouriteChatStateLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin text-tgpro-blue-1 h-4 w-4" />
            ) : (
              <Tooltip text="Favorite">
                <MdStar
                  className={classNames(
                    "cursor-pointer",
                    openedChatState?.data?.dbData?.isFavorite
                      ? "text-[#FCAF03]"
                      : "text-tgpro-grey-1"
                  )}
                  onClick={() => {
                    const user = getUser();
                    dispatch(
                      // @ts-ignore
                      addFavouriteChat({
                        // @ts-ignore
                        data: {
                          telegramId: user.id,
                          chatId: openedChatState?.data?.id,
                        },
                      })
                    );
                  }}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between w-full">
          <h2 className="text-xs leading-15 font-normal font-plus-jakarta text-tgpro-grey-4">
            {openedChatState?.data?.type?.["@type"] === "chatTypeSupergroup" &&
            supergroupFullInfo?.member_count > 0
              ? `${supergroupFullInfo?.member_count} members`
              : ""}
          </h2>
          {openedChatState?.data?.dbData?.followUps?.length
            ? (() => {
                if (followUp && followUp.followUpId)
                  return <FollowUpTag followUp={followUp} />;
                return null;
              })()
            : null}
        </div>
      </div>
      {/* <div
        className="flex-1 relative flex flex-col"
        style={{ backgroundImage: "url(images/chat-bg.png)" }}
      > */}
      <div
        id="scrollableDiv"
        style={{
          width: "100%",
          // height: "calc(100vh - 220px)",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column-reverse",
          margin: "auto",
          backgroundImage: "url(images/chat-bg.png)",
        }}
        className="bg-body-tertiary p-3 flex-1"
      >
        <InfiniteScroll
          dataLength={chatMessagesState?.data?.length || 0}
          next={getMessages}
          hasMore={hasMore}
          loader={<p className="text-center m-5">⏳&nbsp;Loading...</p>}
          endMessage={
            <p className="text-center m-5">That&apos;s all folks!🐰🥕</p>
          }
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            overflow: "visible",
          }}
          scrollableTarget="scrollabfetchDataleDiv"
          inverse={true}
        >
          {chatMessagesState?.map((message: any, i: number) => (
            <div
              className={classNames(
                "w-full flex mt-2",
                user?.id === message?.sender_id?.user_id ? "justify-end" : ""
              )}
              key={i}
            >
              <div className="flex flex-col">
                <div
                  className={classNames(
                    "text-white max-w-[392px] break-words  px-3 py-2 rounded-lg relative",
                    user?.id === message?.sender_id?.user_id
                      ? "rounded-br-none"
                      : "rounded-bl-none bg-tgpro-grey-7",
                    messageForContextMenu?.id === message?.id
                      ? "bg-tgpro-grey-7"
                      : "bg-tgpro-black-2"
                  )}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setMessageForContextMenu(message);
                  }}
                >
                  {messageForContextMenu?.id === message?.id && (
                    <div
                      ref={messageForContextMenuRef}
                      className={classNames(
                        "absolute z-50",
                        user?.id === message?.sender_id?.user_id
                          ? "bottom-10 right-10"
                          : "bottom-10 left-10"
                      )}
                    >
                      <div className="py-2 flex flex-col rounded-xl bg-tgpro-black-1 shadow-md sticky bottom-0 right-10 z-50 w-40">
                        <div
                          className="text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1 px-4 py-2 hover:bg-tgpro-blue-1/15 cursor-pointer flex items-center gap-2"
                          onClick={() => {
                            setReplyOnMessage(() => ({
                              started: true,
                              senderId: message?.sender_id?.user_id,
                              message: message,
                            }));
                            setMessageForContextMenu(null);
                            textareaRef.current?.focus();
                          }}
                        >
                          <LuReply className="h-3.5 w-3.5" />
                          Reply
                        </div>
                        <div
                          className="text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1 px-4 py-2 hover:bg-tgpro-blue-1/15 cursor-pointer flex items-center gap-2"
                          onClick={() => {
                            dispatch(
                              updateForwardMessagesModalState({
                                visible: true,
                                data: {
                                  messageIds: [message.id],
                                  fromChatId: openedChatState.data.id,
                                },
                              })
                            );
                            setMessageForContextMenu(null);
                          }}
                        >
                          <LuForward className="h-3.5 w-3.5" />
                          Forward
                        </div>
                        {message?.content?.text?.text && (
                          <div
                            className="text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1 px-4 py-2 hover:bg-tgpro-blue-1/15 cursor-pointer flex items-center gap-2"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                message?.content?.text?.text
                              );
                              setMessageForContextMenu(null);
                            }}
                          >
                            <IoCopyOutline className="h-3.5 w-3.5" />
                            Copy Text
                          </div>
                        )}
                        {user?.id === message?.sender_id?.user_id && (
                          <div
                            className="text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1 px-4 py-2 hover:bg-tgpro-blue-1/15 cursor-pointer flex items-center gap-2"
                            onClick={() => {
                              tdClient
                                ?.send({
                                  "@type": "deleteMessages",
                                  chat_id: openedChatState.data?.id,
                                  message_ids: [message.id],
                                  revoke: true,
                                })
                                .then(() => {
                                  dispatch(deleteChatMessages([message.id]));
                                });
                              setMessageForContextMenu(null);
                            }}
                          >
                            <HiOutlineTrash className="h-3.5 w-3.5" />
                            Delete
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className={`text-11 leading-13 font-bold font-plus-jakarta ${getUserColor(
                      message?.sender_id?.user_id
                    )} mb-2`}
                  >
                    {chatMessagesState?.[i + 1]?.sender_id?.user_id !==
                    message?.sender_id?.user_id
                      ? getMessageTitleUsername({
                          messageUserId: message?.sender_id?.user_id,
                        })
                      : ""}
                  </div>
                  <Message message={message} />
                  <div className="text-right text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1 mt-2 flex items-center justify-end gap-2">
                    {getFormattedTimeFromUTC(message?.date)}
                    {user?.id === message?.sender_id?.user_id && (
                      <MessageReadIcon
                        chat={openedChatState.data}
                        message={message}
                      />
                    )}
                  </div>
                </div>
                <ReplyMarkup message={message} />
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
      {/* </div> */}
      {openedChatState?.data?.permissions?.can_send_messages ? (
        <div className="relative h-auto">
          {replyOnMessage?.started && (
            <div className="absolute bottom-full w-full flex items-center justify-between p-4 bg-tgpro-black-1 border border-tgpro-black-2">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex-1 text-11 leading-13 text-tgpro-blue-1 font-normal font-plus-jakarta">
                  Reply To{" "}
                  {getMessageTitleUsername({
                    messageUserId: replyOnMessage.senderId,
                  })}
                </div>
                <div className="text-11 leading-13 text-tgpro-grey-1 font-normal font-plus-jakarta w-[90%] break-words">
                  {replyOnMessage.message?.content?.text?.text}
                </div>
              </div>
              <div
                onClick={() =>
                  setReplyOnMessage({
                    started: false,
                    senderId: 0,
                    message: null,
                  })
                }
                className="cursor-pointer"
              >
                <MdClose className="text-white h-5 w-5" />
              </div>
            </div>
          )}
          <div className="flex items-end gap-[22px] p-4 bg-tgpro-black-1 border border-tgpro-black-2">
            <div className="relative">
              <label htmlFor="file-picker" className="cursor-pointer">
                <RiAttachment2 className="text-tgpro-grey-5 h-4 w-4 mb-4" />
              </label>
              <input
                id="file-picker"
                type="file"
                className="hidden"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  sendMedia(file!);
                }}
              />
            </div>
            <div className="relative">
              <HiEmojiHappy
                className="text-tgpro-grey-5 h-5 w-5 cursor-pointer select-none mb-4"
                onClick={() => setShowEmojiPicker((prev) => !prev)}
              />
              {showEmojiPicker && (
                <div
                  className="absolute bottom-[calc(100%+16px)]"
                  ref={pickerRef}
                >
                  <Picker
                    data={data}
                    onEmojiSelect={(emoji: any) =>
                      dispatch(updateMessageText(messageText + emoji?.native))
                    }
                  />
                </div>
              )}
            </div>
            <div className="flex-1">
              <Textarea
                placeholder="Message"
                value={messageText}
                onChange={handleMessageChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    sendMessage();
                  }
                }}
                ref={textareaRef}
              />
            </div>
            <div className="w-20 mb-2">
              <Button
                name="Send"
                preFix={<RiSendPlaneFill className="h-3 w-3" />}
                onClick={sendMessage}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-[60px] w-full bg-tgpro-black-1 border border-tgpro-black-2 text-13 leading-15 font-medium font-plus-jakarta text-tgpro-grey-5">
          You have no permission to send message
        </div>
      )}
    </div>
  );
};
