import { useSelector } from "react-redux";
import useTDLibController from "../../controllers/useTDLibController";
import { RootState } from "../../redux/store";

export const ReplyMarkup = ({ message }: { message: any }) => {
  const { tdClient } = useTDLibController();

  const { openedChatState } = useSelector((state: RootState) => ({
    openedChatState: state.chat.openedChatState.data,
  }));

  const onMarkupClick = async ({ markup }: { markup: any }) => {
    if (markup.type["@type"] === "inlineKeyboardButtonTypeCallback") {
      await tdClient
        ?.send({
          "@type": "getCallbackQueryAnswer",
          chat_id: openedChatState.id,
          message_id: message.id,
          payload: {
            "@type": "callbackQueryPayloadData",
            data: markup?.type?.data,
          },
        })
        .then((res) => console.log({ res }))
        .catch((err) => console.log({ err }));
    }
  };

  return (
    <div className="flex flex-col gap-1 mt-1">
      {message?.reply_markup?.rows?.length
        ? message.reply_markup.rows?.map((markup: any) => (
            <div
              className="text-white text-11 leading-13 font-medium font-plus-jakarta text-center rounded-lg p-2 bg-tgpro-grey-7/75 cursor-pointer"
              onClick={() => onMarkupClick({ markup: markup?.[0] })}
            >
              {markup?.[0]?.text}
            </div>
          ))
        : null}
    </div>
  );
};
