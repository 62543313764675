import { useState } from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { Checkbox } from "./Checkbox";
import { Button } from "./Button";
import { updateForwardMessagesModalState } from "../redux/slices/chatSlice";
import { HiUserGroup } from "react-icons/hi";
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";
import { Input } from "./Input";
import { PiMagnifyingGlass } from "react-icons/pi";
import useTDLibController from "../controllers/useTDLibController";
import { MdStar } from "react-icons/md";

export const ForwardMessages = () => {
  const dispatch = useDispatch();
  const { tdClient } = useTDLibController();

  const { tdChatListState, forwardMessagesModalState } = useSelector(
    (state: RootState) => ({
      openedChatState: state.chat.openedChatState.data,
      tdChatListState: state.chat.tdChatListState.data,
      forwardMessagesModalState: state.chat.forwardMessagesModalState,
    })
  );

  const [searchText, setSearchText] = useState("");
  const [selectedChats, setSelectedChats] = useState<any[]>([]);

  const onForward = async () => {
    console.log({ forwardMessagesModalState });
    for (const chat of selectedChats) {
      console.log({ chat });
      tdClient
        ?.send({
          "@type": "forwardMessages",
          chat_id: chat.id,
          message_ids: forwardMessagesModalState.data!?.messageIds,
          from_chat_id: forwardMessagesModalState.data!?.fromChatId,
        })
        .then((res) => {
          console.log({ res });
          dispatch(
            updateForwardMessagesModalState({
              visible: false,
              data: undefined,
            })
          );
        })
        .catch((err) => console.log({ err }));
    }
  };

  return (
    <div className="absolute top-0 left-0 h-full w-full bg-black/50 flex items-center justify-center">
      <div className="min-w-[415px] p-6 bg-tgpro-black-1 border border-tgpro-black-2 rounded-xl flex flex-col gap-[26px]">
        <div>
          <h3 className="text-sm font-semibold font-plus-jakarta text-white flex items-center justify-between">
            Forward to...
            <IoMdCloseCircleOutline
              className="h-6 w-6 text-[#51627b] cursor-pointer"
              onClick={() => {
                dispatch(
                  updateForwardMessagesModalState({
                    visible: false,
                    data: undefined,
                  })
                );
              }}
            />
          </h3>
        </div>

        <div className="w-full">
          <Input
            placeholder="Search contact"
            postFix={<PiMagnifyingGlass className="text-tgpro-grey-1" />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="space-y-2 max-h-[288px] overflow-auto">
          {([...tdChatListState] || [])
            .filter((chat) => {
              let searchFlag = true;
              if (searchText) {
                searchFlag = chat.title
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
              }
              return searchFlag && chat?.permissions?.can_send_messages;
            })
            .map((chat) => (
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={async () => {
                  let temp = [...selectedChats];
                  const index = temp.findIndex((x) => x.id === chat.id);
                  if (index !== -1) {
                    temp.splice(index, 1);
                  } else {
                    temp.push(chat);
                  }
                  setSelectedChats(temp);
                }}
              >
                <div className="ml-1.5">
                  <Checkbox
                    checked={selectedChats?.find((x) => x?.id === chat?.id)}
                  />
                </div>
                <div
                  className={classNames(
                    "flex-1 flex flex-col gap-4 px-4 py-2 hover:bg-tgpro-black-2"
                  )}
                >
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between">
                      <div className="flex items-center gap-[9px]">
                        {chat?.dbData?.isFavorite && (
                          <MdStar className="text-[#FCAF03] cursor-pointer" />
                        )}
                        {chat?.type?.["@type"] === "chatTypeSupergroup" && (
                          <HiUserGroup className="text-tgpro-grey-1" />
                        )}
                        <HiMiniChatBubbleOvalLeft className="text-tgpro-grey-1 [transform:rotateY(180deg)]" />
                        <div className="text-xs leading-4 text-white font-normal font-plus-jakarta text-ellipsis whitespace-nowrap w-64 overflow-hidden">
                          {chat?.title}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="self-end">
          <Button name="Forward" className="!w-[132px]" onClick={onForward} />
        </div>
      </div>
    </div>
  );
};
