import { IconProps } from ".";

const UserMinusIcon = (props: IconProps) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00007 1.125C4.68839 1.125 3.62507 2.18832 3.62507 3.5C3.62507 4.81168 4.68839 5.875 6.00007 5.875C7.31174 5.875 8.37507 4.81168 8.37507 3.5C8.37507 2.18832 7.31174 1.125 6.00007 1.125ZM4.37507 3.5C4.37507 2.60254 5.1026 1.875 6.00007 1.875C6.89753 1.875 7.62507 2.60254 7.62507 3.5C7.62507 4.39746 6.89753 5.125 6.00007 5.125C5.1026 5.125 4.37507 4.39746 4.37507 3.5Z"
        fill="#FF3838"
      />
      <path
        d="M8.50007 8.125C8.29296 8.125 8.12507 8.29289 8.12507 8.5C8.12507 8.70711 8.29296 8.875 8.50007 8.875H9.50007C9.70717 8.875 9.87507 8.70711 9.87507 8.5C9.87507 8.29289 9.70717 8.125 9.50007 8.125H8.50007Z"
        fill="#FF3838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00007 6.625C6.4843 6.625 6.95103 6.67103 7.38739 6.75645C7.81092 6.36453 8.37752 6.125 9.00007 6.125C10.3117 6.125 11.3751 7.18832 11.3751 8.5C11.3751 9.38004 10.8964 10.1483 10.1852 10.5586C9.9987 10.9633 9.6493 11.2848 9.05987 11.5043C8.40502 11.748 7.43412 11.875 6.00007 11.875C4.98718 11.875 4.2097 11.8115 3.61317 11.6907C3.01831 11.5703 2.57801 11.3881 2.26327 11.1318C1.62376 10.611 1.62447 9.8819 1.62503 9.30098L1.62507 9.25C1.62507 8.43303 2.20981 7.76978 2.98779 7.33217C3.77753 6.88794 4.84329 6.625 6.00007 6.625ZM2.37507 9.25C2.37507 8.82433 2.68576 8.36257 3.35549 7.98585C4.01347 7.61574 4.9477 7.375 6.00007 7.375C6.30309 7.375 6.59705 7.39498 6.87792 7.4325C6.71617 7.75343 6.62507 8.11608 6.62507 8.5C6.62507 9.69464 7.50711 10.6833 8.65548 10.8502C8.12011 11.0183 7.28905 11.125 6.00007 11.125C5.01295 11.125 4.29044 11.0626 3.76196 10.9556C3.23183 10.8483 2.92213 10.7011 2.73686 10.5502C2.39522 10.272 2.37507 9.9039 2.37507 9.25ZM9.00007 6.875C8.1026 6.875 7.37507 7.60254 7.37507 8.5C7.37507 9.39746 8.1026 10.125 9.00007 10.125C9.89753 10.125 10.6251 9.39746 10.6251 8.5C10.6251 7.60254 9.89753 6.875 9.00007 6.875Z"
        fill="#FF3838"
      />
    </svg>
  );
};

export default UserMinusIcon;
