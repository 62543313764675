import { useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { RiPriceTag3Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Input } from "../Input";
import { PiMagnifyingGlass } from "react-icons/pi";
import { updateChatListFilter } from "../../redux/slices/chatSlice";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { Checkbox } from "../Checkbox";

export const Tags = ({ className }: { className?: string }) => {
  const dispatch = useDispatch();

  const { getUserTagsState, chatListFilterState } = useSelector(
    (state: RootState) => ({
      getUserTagsState: state.chat.getUserTagsState.data,
      chatListFilterState: state.chat.chatListFilterState,
    })
  );

  const [showUserTagsPopover, setShowUserTagsPopover] = useState(false);
  const [tagText, setTagText] = useState("");

  const handleSelectAll = () => {
    const allTagIds = getUserTagsState?.data.map((tag: any) => tag.tagId);
    dispatch(updateChatListFilter({ tagIds: allTagIds }));
  };

  const tagsModalRef = useRef<HTMLInputElement>(null);
  useOutsideClick(tagsModalRef, () => setShowUserTagsPopover(false));

  return (
    <div className="relative" ref={tagsModalRef}>
      <div
        className={classNames(
          "flex items-center justify-center gap-2 px-4 py-2 text-11 leading-13 font-normal font-plus-jakarta text-tgpro-blue-1 bg-tgpro-blue-1/15 w-max rounded-full cursor-pointer",
          className,
          showUserTagsPopover || chatListFilterState?.tagIds?.length > 0
            ? "text-tgpro-blue-1 bg-tgpro-blue-1/15"
            : "text-tgpro-grey-1 bg-tgpro-grey-1/15"
        )}
        onClick={() => setShowUserTagsPopover((prev) => !prev)}
      >
        <RiPriceTag3Line className="h-4 w-4" />
        Tags
      </div>
      <div className="w-full">
        {getUserTagsState?.data?.length && showUserTagsPopover ? (
          <div className="p-2 absolute top-full left-0 z-[999] bg-tgpro-black-1 w-max">
            <div className="flex flex-col gap-2">
              <Input
                placeholder="Search"
                postFix={<PiMagnifyingGlass className="text-tgpro-grey-1" />}
                value={tagText}
                onChange={(e) => setTagText(e.target.value)}
              />

              {chatListFilterState?.tagIds?.length >= 1 ? (
                <div
                  className="text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1 underline cursor-pointer"
                  onClick={() => dispatch(updateChatListFilter({ tagIds: [] }))}
                >
                  Unselect all
                </div>
              ) : (
                <div
                  className="text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1 underline cursor-pointer"
                  onClick={handleSelectAll}
                >
                  Select all
                </div>
              )}
            </div>
            <div className="max-h-[300px] overflow-auto w-52 mt-2">
              <>
                <div
                  className={classNames(
                    "flex items-center gap-2 relative px-4 py-2 w-full outline-none text-11 leading-13 font-normal font-plus-jakarta rounded-lg text-tgpro-grey-1 bg-tgpro-black-2",
                    chatListFilterState.tagIds.includes("no-tags")
                      ? "bg-tgpro-blue-1/15 text-tgpro-blue-1"
                      : "text-tgpro-grey-1 bg-tgpro-black-2"
                  )}
                  onClick={() => {
                    const newTagIds = chatListFilterState.tagIds.includes(
                      "no-tags"
                    )
                      ? chatListFilterState.tagIds.filter(
                          (tag) => tag !== "no-tags"
                        )
                      : [...chatListFilterState.tagIds, "no-tags"];
                    dispatch(updateChatListFilter({ tagIds: newTagIds }));
                  }}
                >
                  <Checkbox
                    checked={chatListFilterState.tagIds.includes("no-tags")}
                  />
                  No Tags
                </div>
                <div className="h-[0.5px] my-2 bg-tgpro-black-3"></div>

                {/* @ts-ignore */}
                {([...getUserTagsState?.data] || [])
                  .filter((tag) =>
                    tag.tagName.toLowerCase().includes(tagText.toLowerCase())
                  )
                  .map((tag: any, i: number) => (
                    <div key={tag.tagId}>
                      <div
                        className={classNames(
                          "flex items-center gap-2 relative px-4 py-2 w-full outline-none text-11 leading-13 font-normal font-plus-jakarta rounded-lg",
                          chatListFilterState.tagIds.includes(tag.tagId)
                            ? "bg-tgpro-blue-1/15 text-tgpro-blue-1"
                            : "text-tgpro-grey-1 bg-tgpro-black-2"
                        )}
                        onClick={() => {
                          let temp = [...chatListFilterState.tagIds];
                          const index = temp.findIndex(
                            (id) => id === tag.tagId
                          );
                          if (index !== -1) {
                            temp.splice(index, 1);
                          } else {
                            temp.push(tag.tagId);
                          }
                          dispatch(updateChatListFilter({ tagIds: [...temp] }));
                        }}
                      >
                        <Checkbox
                          checked={chatListFilterState.tagIds.includes(
                            tag.tagId
                          )}
                        />
                        {tag?.tagName}
                      </div>
                      {i !== getUserTagsState?.data?.length - 1 && (
                        <div className="h-[0.5px] my-2 bg-tgpro-black-3"></div>
                      )}
                    </div>
                  ))}
              </>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
