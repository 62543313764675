import classNames from "classnames";
import moment from "moment";
import { FiCalendar } from "react-icons/fi";
import { Draft } from "../../types";

const ShowFollowUpTag = ({ draftData }: { draftData: Draft }) => {
  const createdAt = moment(draftData.createdAt);
  const followUpDate = createdAt.add(draftData?.followUpDays, "days");

  const calculateFollowUpDays = () => {
    const currentDate = moment();
    const daysRemaining = followUpDate
      .startOf("day")
      .diff(currentDate.startOf("day"), "days");
    return daysRemaining;
  };
  const daysRemaining = calculateFollowUpDays();

  return (
    <>
      <div
        className={classNames(
          "flex items-center gap-4 justify-between px-2 py-1 text-11 leading-13 font-normal font-plus-jakarta w-max rounded-full",
          daysRemaining > 0
            ? "text-tgpro-blue-1 bg-tgpro-blue-1/15"
            : draftData?.followUpDays && "text-tgpro-red-1 bg-tgpro-red-1/15"
        )}
      >
        <div className="flex items-center gap-2 relative">
          {draftData?.followUpDays && (
            <>
              <span>
                <FiCalendar />
              </span>
              {daysRemaining >= 0 ? (
                <span>
                  Follow up{" "}
                  {daysRemaining === 0
                    ? "today"
                    : `in ${daysRemaining} day${daysRemaining > 1 ? "s" : ""}`}
                </span>
              ) : (
                <span>
                  {draftData?.isExpired
                    ? `Followed up on ${moment(followUpDate).format(
                        "DD/MM/YYYY"
                      )}`
                    : `Follow up past ${-daysRemaining} days`}
                </span>
              )}
            </>
          )}
          {draftData?.isExpired && (
            <div
              className={classNames(
                "absolute top-1/2 -translate-y-1/2 left-0 h-px w-full",
                daysRemaining > 0
                  ? "bg-tgpro-blue-1"
                  : draftData?.followUpDays && "bg-tgpro-red-1"
              )}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default ShowFollowUpTag;
