import { FaCheck } from "react-icons/fa";
import { Header } from "../../components/Header";
import { Button } from "../../components/Button";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUser } from "../../utils/helpers";
import {
  createCheckoutSession,
  getPaymentPlans,
} from "../../redux/slices/authSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const BENIFITS = [
  "Add tags, followups and notes",
  "Same security as Telegram",
  "Sync your chats in minutes",
  "Co-pilot bot for mobile",
  "Advanced chat filtering",
  "Bulk messaging",
  "Pipeline views",
  "Priority support",
];

export const PaymentPage = () => {
  const dispatch = useDispatch();

  const { getPaymentPlansState, checkPaymentInfoState } = useSelector(
    (state: RootState) => ({
      getPaymentPlansState: state.auth.getPaymentPlansState,
      checkPaymentInfoState: state.auth.checkPaymentInfoState.data?.data,
    })
  );

  useEffect(() => {
    let user = getUser();
    // @ts-ignore
    dispatch(getPaymentPlans({ data: { telegramId: user.id } }));
  }, []);

  if (getPaymentPlansState.loading) {
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <AiOutlineLoading3Quarters className="text-white h-6 w-6 animate-spin" />
      </div>
    );
  }

  return (
    <main className="min-h-screen">
      <Header />
      <h1 className="text-xl leading-[25.2px] font-normal font-plus-jakarta text-tgpro-white-1 text-center mt-[22.5px]">
        Payment Checkout
      </h1>
      <div className="flex items-center justify-center gap-6 mt-[67px]">
        {[...(getPaymentPlansState?.data?.data || [])]
          ?.sort((a: any, b: any) => a.amount - b.amount)
          ?.map((plan: any, i: number) => (
            <div
              className="w-[400px] p-10 border border-tgpro-blue-1 rounded-3xl"
              key={plan.priceId}
            >
              <h3 className="flex items-center gap-2 text-13 leading-16 font-bold font-plus-jakarta text-white">
                {i === 1 ? "Annually" : "Monthly"}{" "}
                {i === 1 && (
                  <div className="text-11 leading-13 font-semibold bg-tgpro-blue-1 px-4 py-1 rounded-full w-max">
                    Save 30%
                  </div>
                )}
              </h3>
              <div className="text-11 leading-13 font-medium font-plus-jakarta text-tgpro-grey-2 mt-2">
                {i === 1 ? "Scale with us" : "Get started"}
              </div>
              <div className="text-2xl leading-[30px] font-semibold font-plus-jakarta text-white mt-6">
                ${plan.amount} USD
              </div>
              <div className="flex flex-col gap-4 mt-8">
                {BENIFITS.map((benifit, i) => (
                  <div key={i} className="flex items-center gap-2">
                    <div className="flex items-center justify-center h-4 w-4 bg-tgpro-blue-1 rounded-full">
                      <FaCheck className="text-white h-2.5 w-2.5" />{" "}
                    </div>
                    <div className="text-11 leading-13 font-medium font-plus-jakarta text-tgpro-grey-3">
                      {benifit}
                    </div>
                  </div>
                ))}
              </div>
              {checkPaymentInfoState?.trialApplicable ? (
                <Button
                  name="Start 14 days trial"
                  className="mt-6"
                  onClick={() => {
                    const user = getUser();
                    dispatch(
                      // @ts-ignore
                      createCheckoutSession({
                        data: { telegramId: user?.id, priceId: plan.priceId },
                        onSuccess: (res) => {
                          if (res?.data?.checkoutUrl)
                            window.open(res?.data?.checkoutUrl, "_self");
                        },
                      })
                    );
                  }}
                />
              ) : (
                <Button
                  name="Subscribe"
                  className="mt-6"
                  onClick={() => {
                    const user = getUser();
                    dispatch(
                      // @ts-ignore
                      createCheckoutSession({
                        data: { telegramId: user?.id, priceId: plan.priceId },
                        onSuccess: (res) => {
                          if (res?.data?.checkoutUrl)
                            window.open(res?.data?.checkoutUrl, "_self");
                        },
                      })
                    );
                  }}
                />
              )}
            </div>
          ))}
      </div>
    </main>
  );
};
