"use client";

import { PiMagnifyingGlass } from "react-icons/pi";
import { HiUserGroup } from "react-icons/hi";
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";
import { MdStar } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTDLibController from "../../controllers/useTDLibController";
import {
  checkConn,
  getLinkedChats,
  setOpenChat,
  setSelectedChats,
  setTdChatList,
  unlinkChats,
  updateAddBulkFollowUpsModalState,
  updateAddBulkTagsModalState,
  updateChatReadInboxOutboxData,
} from "../../redux/slices/chatSlice";
import { RootState } from "../../redux/store";
import {
  epochToUTC,
  getFormattedTimeFromUTC,
  getUser,
} from "../../utils/helpers";
import { Input } from "../../components/Input";
import { FaChevronUp, FaListUl, FaPlus, FaSpinner } from "react-icons/fa";
import classNames from "classnames";
import { FollowUpTag } from "../../components/FollowUpTag";
import { Checkbox } from "../../components/Checkbox";
import useOutsideClick from "../../hooks/useOutsideClick";
import moment from "moment";
import Tooltip from "../../components/Tooltip";
import { FollowUp } from "../../types";
import {
  setShowBulkMessagePage,
  setShowChatSyncPage,
  setShowDraftSyncPage,
  setShowPaymentPage,
  setShowSettingsPage,
} from "../../redux/slices/authSlice";

const UserTile = ({
  chat,
  selectMode,
  selected,
  onSelect,
}: {
  chat: any;
  selectMode: boolean;
  selected: boolean;
  onSelect?: (chatId: number) => void;
}) => {
  const dispatch = useDispatch();

  const { openedChatState, messageText } = useSelector((state: RootState) => ({
    openedChatState: state.chat.openedChatState.data,
    messageText: state.chat.messageText,
  }));

  const { tdClient } = useTDLibController();

  const [firstTime, setFirstTime] = useState(true);

  const [followUp, setFollowUp] = useState<any>({});

  const followUps: FollowUp[] = chat?.dbData?.followUps;

  const incompleteFollowUps = followUps.filter((x: any) => !x.isCompleted);

  const expiredIncompleteFollowUps = incompleteFollowUps.filter(
    (x: any) => x.isExpired
  );

  let activeFollowUp: FollowUp | undefined;

  if (expiredIncompleteFollowUps.length > 0) {
    expiredIncompleteFollowUps.sort(
      (a: any, b: any) =>
        new Date(a.followUpDate).getTime() - new Date(b.followUpDate).getTime()
    );
    activeFollowUp = expiredIncompleteFollowUps[0];
  } else {
    activeFollowUp = incompleteFollowUps[0];
  }
  useEffect(() => {
    if (firstTime && activeFollowUp?.followUpId) {
      setFollowUp(activeFollowUp);
      setFirstTime(false);
    }
  }, [activeFollowUp]);

  useEffect(() => {
    if (!firstTime) {
      if (activeFollowUp?.followUpId && !followUp) {
        setFollowUp(activeFollowUp);
      }
      if (followUp && activeFollowUp?.followUpId === followUp.followUpId) {
        setFollowUp(activeFollowUp);
      }
      if (
        followUp &&
        activeFollowUp?.followUpId &&
        activeFollowUp?.followUpId !== followUp.followUpId
      ) {
        setFollowUp(activeFollowUp);
      }
      if (!activeFollowUp?.followUpId && followUp) {
        setFollowUp(
          chat?.dbData?.followUps.find(
            (f: any) => f.followUpId === followUp.followUpId
          )
        );
      }
    }
  }, [activeFollowUp, firstTime, followUp]);

  return (
    <>
      <div
        className="flex items-center gap-2 border border-tgpro-black-2 cursor-pointer"
        onClick={async () => {
          if (selectMode) {
            onSelect?.(chat.id);
          } else {
            if (
              openedChatState?.id &&
              openedChatState?.permissions?.can_send_messages
            )
              tdClient
                ?.send({
                  "@type": "setChatDraftMessage",
                  chat_id: openedChatState.id,
                  draft_message: {
                    "@type": "draftMessage",
                    input_message_text: {
                      "@type": "inputMessageText",
                      text: {
                        "@type": "formattedText",
                        text: messageText,
                        entities: [],
                      },
                    },
                  },
                })
                .then(async (res) => {
                  const openedChat = await tdClient?.send({
                    "@type": "openChat",
                    chat_id: chat.id,
                  });
                  dispatch(
                    updateChatReadInboxOutboxData({
                      lastReadOutboxMessageId:
                        chat?.last_read_outbox_message_id,
                      lastReadInboxMessageId: chat?.last_read_inbox_message_id,
                    })
                  );
                  dispatch(setOpenChat(chat));
                })
                .catch((err) => console.log({ err }));
            else {
              const openedChat = await tdClient?.send({
                "@type": "openChat",
                chat_id: chat.id,
              });
              dispatch(
                updateChatReadInboxOutboxData({
                  lastReadOutboxMessageId: chat?.last_read_outbox_message_id,
                  lastReadInboxMessageId: chat?.last_read_inbox_message_id,
                })
              );
              dispatch(setOpenChat(chat));
            }
          }
        }}
      >
        {selectMode && (
          <div className="ml-1.5">
            <Checkbox checked={selected} />
          </div>
        )}
        <div
          className={classNames(
            "flex-1 flex flex-col gap-4 px-4 py-2 hover:bg-tgpro-black-2",
            chat.id === openedChatState?.id ? "bg-tgpro-black-2" : ""
          )}
        >
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <div className="flex items-center gap-[9px]">
                {chat?.dbData?.isFavorite && (
                  <MdStar className="text-[#FCAF03] cursor-pointer" />
                )}
                {chat?.type?.["@type"] === "chatTypeSupergroup" && (
                  <HiUserGroup className="text-tgpro-grey-1" />
                )}
                <HiMiniChatBubbleOvalLeft className="text-tgpro-grey-1 [transform:rotateY(180deg)]" />
                <div className="text-xs leading-4 text-white font-normal font-plus-jakarta text-ellipsis whitespace-nowrap w-64 overflow-hidden">
                  {chat?.title}
                </div>
              </div>
              <div className="text-11 leading-13 text-tgpro-grey-1 font-normal font-plus-jakarta">
                {getFormattedTimeFromUTC(chat?.last_message?.date)}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-xs leading-13 text-tgpro-grey-3 font-normal font-plus-jakarta text-ellipsis whitespace-nowrap w-80 overflow-hidden">
                {chat?.draft_message ? (
                  <div>
                    <span className="text-tgpro-red-1">Draft:</span>{" "}
                    {chat?.draft_message?.input_message_text?.text.text}
                  </div>
                ) : (
                  chat?.last_message?.content?.text?.text
                )}
              </div>
              {chat?.unread_count > 0 && (
                <div className="h-3 w-3 bg-tgpro-red-2 rounded-full" />
              )}
            </div>
          </div>
          <div className="flex items-start justify-between">
            <div className="flex items-center gap-2">
              {(chat?.dbData?.chatTags || []).slice(0, 2).map((tag: any) => (
                <div
                  key={tag.tag_id}
                  className="flex items-center justify-center px-2 py-1 text-11 leading-13 font-normal font-plus-jakarta text-tgpro-blue-1 bg-tgpro-blue-1/15 w-max rounded-full"
                >
                  {tag.tag_name}
                </div>
              ))}
              {chat?.dbData?.chatTags?.length - 2 > 0 && (
                <div className="flex items-center gap-px text-11 leading-13 font-normal font-plus-jakarta text-tgpro-blue-1">
                  <FaPlus className="h-2 w-2" />{" "}
                  {chat?.dbData?.chatTags?.length - 2}
                </div>
              )}
            </div>
            {chat?.dbData?.followUps?.length
              ? (() => {
                  if (followUp && followUp.followUpId)
                    return <FollowUpTag followUp={followUp} chatId={chat.id} />;
                  return null;
                })()
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export const ChatList = () => {
  const dispatch = useDispatch();
  const { tdClient, isReady } = useTDLibController();

  const [searchText, setSearchText] = useState("");
  const [selectMode, setSelectMode] = useState(false);
  const [
    multipleSelectionChatActionModal,
    setMultipleSelectionChatActionModal,
  ] = useState(false);

  const multipleSelectionChatActionModalRef = useRef(null);

  useOutsideClick(multipleSelectionChatActionModalRef, () =>
    setMultipleSelectionChatActionModal(false)
  );

  const {
    getLinkedChatsState,
    tdChatListState,
    chatListFilterState,
    openedChatState,
    getLinkedChatsLoading,
    selectedChats,
  } = useSelector((state: RootState) => ({
    getLinkedChatsState: state.chat.getLinkedChatsState.data,
    tdChatListState: state.chat.tdChatListState.data,
    chatListFilterState: state.chat.chatListFilterState,
    openedChatState: state.chat.openedChatState.data,
    getLinkedChatsLoading: state.chat.getLinkedChatsState.loading,
    selectedChats: state.chat.selectedChats,
  }));

  const getLinkedChatsData = () => {
    const user = getUser();
    if (user && user.id) {
      // @ts-ignore
      dispatch(getLinkedChats({ data: { telegramId: user.id } }));
      dispatch(
        // @ts-ignore
        checkConn({
          data: { telegramId: user.id, telegram_handle: user.username },
        })
      );
    }
  };

  useEffect(() => {
    getLinkedChatsData();
  }, []);

  useEffect(() => {
    if (getLinkedChatsState && getLinkedChatsState.data) {
    }
  }, [getLinkedChatsState]);

  const getTDChats = async () => {
    const temp = [];
    for (const chat of getLinkedChatsState?.data) {
      try {
        const result = await tdClient?.send({
          "@type": "getChat",
          chat_id: chat.chatId,
        });
        // @ts-ignore
        const index = temp.findIndex((x) => x?.id === result?.id);
        if (index === -1) {
          temp.push({ ...result, dbData: { ...chat } });
        }
      } catch (err) {
        console.log({ err });
      }
    }
    dispatch(setTdChatList(temp));
  };

  useEffect(() => {
    if (getLinkedChatsState?.data?.length && isReady) {
      getTDChats();
    }
  }, [tdClient, isReady, getLinkedChatsState]);

  const onUnLinkChats = () => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      unlinkChats({
        data: {
          telegramId: user.id,
          chatIds: selectedChats?.map((chat) => chat.id) || [],
        },
        onSuccess: () => {
          let temp = [...tdChatListState];
          let updatedChatList: typeof tdChatListState = [];
          const selectedChatIds = selectedChats?.map((chat) => chat.id);
          for (let chat of temp) {
            if (!selectedChatIds.includes(chat.id)) {
              updatedChatList.push(chat);
            } else {
              if (chat.id === openedChatState?.id) {
                dispatch(setOpenChat(null));
              }
            }
          }
          dispatch(setSelectedChats([]));
          setSelectMode(false);
          dispatch(setTdChatList(updatedChatList));
        },
      })
    );
  };

  const categorizeChatsByDate = (chats: any[]) => {
    const categorizedChats: Record<string, any[]> = {
      Today: [],
      Yesterday: [],
      "Last 7 days": [],
      "Last 30 days": [],
      "Last 3 months": [],
      "Last 6 months": [],
      "Last year": [],
      "More than a year": [],
    };

    const today = moment();

    chats.forEach((chat) => {
      const chatDate = moment.unix(chat.last_message.date);

      if (chatDate.isSame(today, "day")) {
        categorizedChats["Today"].push(chat);
      } else if (chatDate.isSame(today.clone().subtract(1, "day"), "day")) {
        categorizedChats["Yesterday"].push(chat);
      } else if (chatDate.isAfter(today.clone().subtract(7, "days"))) {
        categorizedChats["Last 7 days"].push(chat);
      } else if (chatDate.isAfter(today.clone().subtract(30, "days"))) {
        categorizedChats["Last 30 days"].push(chat);
      } else if (chatDate.isAfter(today.clone().subtract(3, "months"))) {
        categorizedChats["Last 3 months"].push(chat);
      } else if (chatDate.isAfter(today.clone().subtract(6, "months"))) {
        categorizedChats["Last 6 months"].push(chat);
      } else if (chatDate.isAfter(today.clone().subtract(1, "year"))) {
        categorizedChats["Last year"].push(chat);
      } else {
        categorizedChats["More than a year"].push(chat);
      }
    });

    return categorizedChats;
  };
  const categorizedChats = categorizeChatsByDate(tdChatListState || []);
  const categoryKeys = Object.keys(categorizedChats);
  const sortedCategoryKeys =
    chatListFilterState?.sorting?.value === "oldest"
      ? categoryKeys.reverse()
      : categoryKeys;

  return (
    <div className="relative">
      <div
        className={classNames(
          "pt-4 pl-4 h-[calc(100vh-50px)] flex flex-col gap-2 w-fit xl:w-[464px] border-l border-tgpro-black-2",
          selectedChats?.length ? "pb-[60px]" : ""
        )}
      >
        <div className="w-full relative flex items-center justify-between gap-4">
          <div className="w-full">
            <Input
              placeholder="Search"
              postFix={<PiMagnifyingGlass className="text-tgpro-grey-1" />}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <Tooltip text="Multi select">
            <div
              className={classNames(
                "text-11 leading-13 font-semibold font-plus-jakarta text-tgpro-blue-1 cursor-pointer rounded-lg",
                selectMode
                  ? "bg-tgpro-blue-1/15 p-3"
                  : "border border-tgpro-blue-1/50 p-[11px]"
              )}
              onClick={() =>
                setSelectMode((prev) => {
                  if (prev) {
                    dispatch(setSelectedChats([]));
                    return false;
                  } else return true;
                })
              }
            >
              <FaListUl className="h-4 w-4" />
            </div>
          </Tooltip>
        </div>

        {getLinkedChatsLoading ? (
          <div className="flex items-center justify-center h-full w-[390px]">
            <FaSpinner className="text-tgpro-blue-1 animate-spin w-10 h-10" />
          </div>
        ) : (
          <>
            {tdChatListState && tdChatListState?.length ? (
              <div className="flex-1 overflow-auto relative">
                {chatListFilterState.sorting.value === "follow-up-due" ? (
                  ([...tdChatListState] || [])
                    .filter((chat) => {
                      let searchflag = true;
                      let tagIdflag = true;
                      let favoriteflag = true;
                      let followupsflag = true;
                      let followupDueflag = true;
                      let unansweredbymeflag = true;
                      let unreadflag = true;
                      let unansweredbythemflag = true;
                      if (searchText) {
                        searchflag = chat.title
                          .toLowerCase()
                          .includes(searchText.toLowerCase());
                      }
                      const tagIds = chat?.dbData?.chatTags?.map(
                        (x: any) => x.tag_id
                      );
                      if (chatListFilterState?.tagIds?.length) {
                        if (chatListFilterState?.tagIds?.includes("no-tags")) {
                          if (chatListFilterState.tagIds.length === 1) {
                            tagIdflag = tagIds.length === 0;
                          } else {
                            tagIdflag =
                              tagIds.length === 0 ||
                              chatListFilterState.tagIds.some(
                                (x) => x !== "no-tags" && tagIds?.includes(x)
                              );
                          }
                        } else {
                          tagIdflag = chatListFilterState.tagIds.some((x) =>
                            tagIds?.includes(x)
                          );
                        }
                      }

                      if (chatListFilterState.favorite) {
                        favoriteflag = chat?.dbData?.isFavorite;
                      }
                      if (chatListFilterState.followups) {
                        followupsflag =
                          chat?.dbData?.followUps?.length >= 1 &&
                          chat?.dbData?.followUps.some(
                            (followUp: any) => !followUp.isCompleted
                          );
                      }
                      if (
                        chatListFilterState.sorting.value === "follow-up-due"
                      ) {
                        followupDueflag = chat?.dbData?.followUps?.some(
                          (followUp: any) => !followUp.isCompleted
                        );
                      }
                      if (chatListFilterState.unansweredByMe) {
                        const user = getUser();
                        unansweredbymeflag =
                          chat?.unread_count === 0 &&
                          chat?.last_message?.sender_id?.user_id !== user?.id;
                      }
                      if (chatListFilterState.unread) {
                        unreadflag = chat?.unread_count > 0;
                      }

                      if (chatListFilterState.unansweredByThem) {
                        const user = getUser();
                        unansweredbythemflag =
                          chat?.unread_count === 0 &&
                          chat?.last_message?.sender_id?.user_id === user?.id;
                      }
                      return (
                        searchflag &&
                        tagIdflag &&
                        favoriteflag &&
                        followupsflag &&
                        followupDueflag &&
                        unansweredbymeflag &&
                        unreadflag &&
                        unansweredbythemflag
                      );
                    })
                    ?.sort((a, b) => {
                      if (
                        chatListFilterState.sorting.value === "follow-up-due"
                      ) {
                        const aFollowUp = a?.dbData?.followUps?.find(
                          (followUp: any) => !followUp.isCompleted
                        );
                        const bFollowUp = b?.dbData?.followUps?.find(
                          (followUp: any) => !followUp.isCompleted
                        );
                        if (aFollowUp && bFollowUp) {
                          const aDate = new Date(aFollowUp.followUpDate);
                          const bDate = new Date(bFollowUp.followUpDate);
                          if (
                            !isNaN(aDate.getTime()) &&
                            !isNaN(bDate.getTime())
                          ) {
                            return aDate.getTime() - bDate.getTime();
                          }
                        } else {
                          if (aFollowUp) return -1;
                          if (bFollowUp) return 1;
                        }
                      }

                      return chatListFilterState.sorting.value === "oldest"
                        ? epochToUTC(a?.last_message?.date) -
                            epochToUTC(b?.last_message?.date)
                        : epochToUTC(b?.last_message?.date) -
                            epochToUTC(a?.last_message?.date);
                    })
                    .map((chat) => (
                      <UserTile
                        chat={chat}
                        key={chat.id}
                        selectMode={selectMode}
                        selected={!!selectedChats.find((x) => x.id === chat.id)}
                        onSelect={(chatId) => {
                          let temp = [...selectedChats];
                          const index = temp.findIndex((x) => x.id === chatId);
                          if (index !== -1) {
                            temp.splice(index, 1);
                          } else {
                            temp.push(chat);
                          }
                          dispatch(setSelectedChats(temp));
                        }}
                      />
                    ))
                ) : (
                  <>
                    {sortedCategoryKeys.map((category) =>
                      categorizedChats[category].length > 0 ? (
                        <div key={category}>
                          <div className="bg-tgpro-black-1 rounded-full sticky top-0 z-10">
                            <div className="bg-tgpro-blue-1/15 text-tgpro-blue-1 text-11 text-center rounded-full py-1 my-2">
                              {category}
                            </div>
                          </div>
                          {categorizedChats[category]
                            .filter((chat) => {
                              let searchflag = true;
                              let tagIdflag = true;
                              let favoriteflag = true;
                              let followupsflag = true;
                              let followupDueflag = true;
                              let unansweredbymeflag = true;
                              let unreadflag = true;
                              let unansweredbythemflag = true;

                              if (searchText) {
                                searchflag = chat.title
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase());
                              }
                              const tagIds = chat?.dbData?.chatTags?.map(
                                (x: any) => x.tag_id
                              );
                              if (chatListFilterState?.tagIds?.length) {
                                if (
                                  chatListFilterState?.tagIds?.includes(
                                    "no-tags"
                                  )
                                ) {
                                  if (chatListFilterState.tagIds.length === 1) {
                                    tagIdflag = tagIds.length === 0;
                                  } else {
                                    tagIdflag =
                                      tagIds.length === 0 ||
                                      chatListFilterState.tagIds.some(
                                        (x) =>
                                          x !== "no-tags" && tagIds?.includes(x)
                                      );
                                  }
                                } else {
                                  tagIdflag = chatListFilterState.tagIds.some(
                                    (x) => tagIds?.includes(x)
                                  );
                                }
                              }

                              if (chatListFilterState.favorite) {
                                favoriteflag = chat?.dbData?.isFavorite;
                              }
                              if (chatListFilterState.followups) {
                                followupsflag =
                                  chat?.dbData?.followUps?.length >= 1 &&
                                  chat?.dbData?.followUps.some(
                                    (followUp: any) => !followUp.isCompleted
                                  );
                              }
                              if (
                                chatListFilterState.sorting.value ===
                                "follow-up-due"
                              ) {
                                followupDueflag = chat?.dbData?.followUps?.some(
                                  (followUp: any) => !followUp.isCompleted
                                );
                              }
                              if (chatListFilterState.unansweredByMe) {
                                const user = getUser();
                                unansweredbymeflag =
                                  chat?.unread_count === 0 &&
                                  chat?.last_message?.sender_id?.user_id !==
                                    user?.id;
                              }
                              if (chatListFilterState.unread) {
                                unreadflag = chat?.unread_count > 0;
                              }
                              if (chatListFilterState.unansweredByThem) {
                                const user = getUser();
                                unansweredbythemflag =
                                  chat?.unread_count === 0 &&
                                  chat?.last_message?.sender_id?.user_id ===
                                    user?.id;
                              }

                              return (
                                searchflag &&
                                tagIdflag &&
                                favoriteflag &&
                                followupsflag &&
                                followupDueflag &&
                                unansweredbymeflag &&
                                unreadflag &&
                                unansweredbythemflag
                              );
                            })
                            ?.sort((a, b) => {
                              if (
                                chatListFilterState.sorting.value ===
                                "follow-up-due"
                              ) {
                                const aFollowUp = a?.dbData?.followUps?.find(
                                  (followUp: any) => !followUp.isCompleted
                                );
                                const bFollowUp = b?.dbData?.followUps?.find(
                                  (followUp: any) => !followUp.isCompleted
                                );
                                if (aFollowUp && bFollowUp) {
                                  const aDate = new Date(
                                    aFollowUp.followUpDate
                                  );
                                  const bDate = new Date(
                                    bFollowUp.followUpDate
                                  );
                                  if (
                                    !isNaN(aDate.getTime()) &&
                                    !isNaN(bDate.getTime())
                                  ) {
                                    return aDate.getTime() - bDate.getTime();
                                  }
                                } else {
                                  if (aFollowUp) return -1;
                                  if (bFollowUp) return 1;
                                }
                              }

                              return chatListFilterState.sorting.value ===
                                "oldest"
                                ? epochToUTC(a?.last_message?.date) -
                                    epochToUTC(b?.last_message?.date)
                                : epochToUTC(b?.last_message?.date) -
                                    epochToUTC(a?.last_message?.date);
                            })
                            .map((chat) => (
                              <UserTile
                                chat={chat}
                                key={chat.id}
                                selectMode={selectMode}
                                selected={
                                  !!selectedChats.find((x) => x.id === chat.id)
                                }
                                onSelect={(chatId) => {
                                  let temp = [...selectedChats];
                                  const index = temp.findIndex(
                                    (x) => x.id === chatId
                                  );
                                  if (index !== -1) {
                                    temp.splice(index, 1);
                                  } else {
                                    temp.push(chat);
                                  }
                                  dispatch(setSelectedChats(temp));
                                }}
                              />
                            ))}
                        </div>
                      ) : null
                    )}
                  </>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
      {selectMode && selectedChats?.length ? (
        <div
          className="absolute bottom-0 border-t border-tgpro-black-2 w-full bg-tgpro-black-1"
          onClick={() => setMultipleSelectionChatActionModal((prev) => !prev)}
        >
          <div className="w-full px-6 py-[18px] text-sm leading-6 font-semibold font-plus-jakarta text-white flex items-center justify-between relative">
            Select Action ({selectedChats.length} chats selected)
            <div className="relative">
              <FaChevronUp />
              {multipleSelectionChatActionModal && (
                <div
                  className="p-3 bg-tgpro-black-1 border border-tgpro-black-2 rounded absolute bottom-[calc(100%+12px)] right-0 w-[180px] space-y-4 z-20"
                  ref={multipleSelectionChatActionModalRef}
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(setShowDraftSyncPage(false));
                      dispatch(setShowChatSyncPage(false));
                      dispatch(setShowPaymentPage(false));
                      dispatch(setShowSettingsPage(false));
                      dispatch(setShowBulkMessagePage(true));
                    }}
                  >
                    Bulk Message
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(
                        updateAddBulkFollowUpsModalState({
                          visible: true,
                          chats: selectedChats,
                          onAddFollowUpSuccess: () => {
                            dispatch(setSelectedChats([]));
                            setSelectMode(false);
                          },
                        })
                      );
                    }}
                  >
                    Bulk followup
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(
                        updateAddBulkTagsModalState({
                          visible: true,
                          chats: selectedChats,
                          onAddTagSuccess: () => {
                            dispatch(setSelectedChats([]));
                            setSelectMode(false);
                          },
                        })
                      );
                    }}
                  >
                    Add Tags
                  </div>
                  <div className="cursor-pointer" onClick={onUnLinkChats}>
                    Archive Chats
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
