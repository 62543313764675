
export function getBrowser() {
  const { userAgent } = window.navigator;

  let browser_name = '';
  // @ts-ignore
  let isIE = false || !!document.documentMode;
  // @ts-ignore
  let isEdge = !isIE && !!window.StyleMedia;
  if (userAgent.indexOf('Chrome') !== -1 && !isEdge) {
    browser_name = 'Chrome';
  } else if (userAgent.indexOf('Safari') !== -1 && !isEdge) {
    browser_name = 'Safari';
  } else if (userAgent.indexOf('Firefox') !== -1) {
    browser_name = 'Firefox';
    // @ts-ignore
  } else if (userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
    //IF IE > 10
    browser_name = 'IE';
  } else if (isEdge) {
    browser_name = 'Edge';
  } else {
    browser_name = 'Unknown';
  }

  return browser_name;
}

export function getOSName() {
  const { userAgent } = window.navigator;

  let OSName = 'Unknown';
  if (userAgent.indexOf('Windows NT 10.0') !== -1) OSName = 'Windows 10';
  if (userAgent.indexOf('Windows NT 6.2') !== -1) OSName = 'Windows 8';
  if (userAgent.indexOf('Windows NT 6.1') !== -1) OSName = 'Windows 7';
  if (userAgent.indexOf('Windows NT 6.0') !== -1) OSName = 'Windows Vista';
  if (userAgent.indexOf('Windows NT 5.1') !== -1) OSName = 'Windows XP';
  if (userAgent.indexOf('Windows NT 5.0') !== -1) OSName = 'Windows 2000';
  if (userAgent.indexOf('Mac') !== -1) OSName = 'Mac/iOS';
  if (userAgent.indexOf('X11') !== -1) OSName = 'UNIX';
  if (userAgent.indexOf('Linux') !== -1) OSName = 'Linux';

  return OSName;
}