import { useEffect, useRef, useState } from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import useOutsideClick from "../hooks/useOutsideClick";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUser } from "../utils/helpers";
import { MdClose, MdStar } from "react-icons/md";
import classNames from "classnames";
import { Checkbox } from "./Checkbox";
import { Button } from "./Button";
import {
  addTag,
  getUserTags,
  linkTags,
  setOpenChat,
  setTdChatList,
  updateAddBulkTagsModalState,
} from "../redux/slices/chatSlice";
import { HiUserGroup } from "react-icons/hi";
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";

export const AddBulkTags = () => {
  const dispatch = useDispatch();

  const {
    getUserTagsState,
    openedChatState,
    tdChatListState,
    addBulkTagsModalState,
    linkTagsStateLoading,
  } = useSelector((state: RootState) => ({
    getUserTagsState: state.chat.getUserTagsState.data,
    openedChatState: state.chat.openedChatState.data,
    tdChatListState: state.chat.tdChatListState.data,
    addBulkTagsModalState: state.chat.addBulkTagsModalState,
    linkTagsStateLoading: state.chat.linkTagsState.loading,
  }));

  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [showUserTagsPopover, setShowUserTagsPopover] = useState(false);
  const [tagText, setTagText] = useState("");

  const tagsModalRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  useOutsideClick(tagsModalRef, () => setShowUserTagsPopover(false));

  const getCurrentUserTags = () => {
    const user = getUser();
    // @ts-ignore
    dispatch(getUserTags({ data: { telegramId: user.id } }));
  };

  useEffect(() => {
    getCurrentUserTags();
  }, []);

  const onAddTag = (value: string) => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      addTag({
        data: {
          telegramId: user.id,
          tagName: value,
        },
        onSuccess: (res: any) => {
          inputRef.current!.value = "";
          inputRef.current?.blur();
          setTagText("");
          getCurrentUserTags();
        },
      })
    );
  };

  const onLinkTags = () => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      linkTags({
        data: {
          telegramId: user.id,
          chatId: addBulkTagsModalState?.chats.map((chat) => chat.id) || [],
          tagIds: selectedTags.map((tag) => tag.tagId) || [],
        },
        onSuccess: (res) => {
          let temp = [...tdChatListState];
          let updatedChatList: typeof tdChatListState = [];
          for (let chat of temp) {
            const index = temp.findIndex((x) => x.id === chat.id);
            if (index !== -1) {
              let dbData = { ...chat.dbData };
              const chatTags = getUserTagsState?.data
                ?.filter(
                  (x: any) => !!selectedTags.find((y) => y.tagId === x.tagId)
                )
                .map((x: any) => ({
                  tag_id: x.tagId,
                  tag_name: x.tagName,
                }));
              let dbChatTags = [...dbData.chatTags];
              dbChatTags = [...dbChatTags];
              (chatTags || []).forEach((x: any) => {
                if (!dbChatTags.find((tag) => x.tag_id === tag.tag_id)) {
                  dbChatTags.push(x);
                }
              });
              dbData = { ...dbData, chatTags: dbChatTags };

              updatedChatList.push({ ...chat, dbData });

              if (chat.id === openedChatState?.id) {
                dispatch(setOpenChat({ ...openedChatState, dbData }));
              }
            } else {
              updatedChatList.push({ ...chat });
            }
          }

          dispatch(setTdChatList(updatedChatList));
          setSelectedTags([]);
          addBulkTagsModalState?.onAddTagSuccess?.();
          dispatch(updateAddBulkTagsModalState({ visible: false, chats: [] }));
        },
      })
    );
  };

  return (
    <div className="absolute top-0 left-0 h-screen w-screen bg-black/50 flex items-center justify-center">
      <div className="p-6 bg-tgpro-black-1 border border-tgpro-black-2 rounded-xl flex flex-col gap-[26px]">
        <div>
          <h3 className="text-sm font-semibold font-plus-jakarta text-white flex items-center justify-between">
            Add new tags ({addBulkTagsModalState?.chats?.length} chats selected)
            <IoMdCloseCircleOutline
              className="h-6 w-6 text-[#51627b] cursor-pointer"
              onClick={() => {
                dispatch(
                  updateAddBulkTagsModalState({ visible: false, chats: [] })
                );
              }}
            />
          </h3>
        </div>
        <div className="space-y-2 max-h-[153px] overflow-auto">
          {addBulkTagsModalState?.chats?.map((chat) => (
            <div className="flex items-center gap-[9px]" key={chat?.id}>
              {chat?.dbData?.isFavorite && (
                <MdStar className="text-[#FCAF03] cursor-pointer" />
              )}
              {chat?.type?.["@type"] === "chatTypeSupergroup" && (
                <HiUserGroup className="text-tgpro-grey-1" />
              )}
              <HiMiniChatBubbleOvalLeft className="text-tgpro-grey-1 [transform:rotateY(180deg)]" />
              <div className="text-xs leading-4 text-white font-normal font-plus-jakarta text-ellipsis whitespace-nowrap w-64 overflow-hidden">
                {chat?.title}
              </div>
            </div>
          ))}
        </div>
        <div className="bg-tgpro-black-2 rounded-lg gap-2 px-4 py-2">
          <div
            className="flex items-center flex-wrap gap-2"
            onClick={() => inputRef.current?.focus()}
          >
            {(selectedTags || []).map((tag: any) => (
              <div
                key={tag.tagId}
                className="flex items-center justify-center gap-1 px-2 py-1 text-11 leading-13 font-normal font-plus-jakarta text-tgpro-blue-1 bg-tgpro-blue-1/15 w-max rounded-full"
              >
                {tag.tagName}
                <div
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let temp = [...selectedTags];
                    console.log({ temp, tag });
                    const index = temp.findIndex((x) => x.tagId === tag.tagId);
                    if (index !== -1) {
                      temp.splice(index, 1);
                    }
                    setSelectedTags(temp);
                  }}
                >
                  <MdClose />
                </div>
              </div>
            ))}
          </div>
          <div
            className="relative w-[367px]"
            onFocus={() => setShowUserTagsPopover(true)}
          >
            <input
              ref={inputRef}
              className="bg-transparent outline-none border-none text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-5 w-full"
              value={tagText}
              onChange={(e) => setTagText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onAddTag((e.target as any).value);
                }
              }}
              onFocus={() => setShowUserTagsPopover(true)}
            />
            {/* @ts-ignore */}
            {getUserTagsState?.data?.length && showUserTagsPopover ? (
              <div
                className="p-2 absolute top-full left-0 z-50 bg-tgpro-black-1 w-full"
                ref={tagsModalRef}
              >
                <div className="max-h-[300px] overflow-auto w-full">
                  <>
                    {/* @ts-ignore */}
                    {([...getUserTagsState?.data] || [])
                      .filter((tag) =>
                        tag.tagName
                          .toLowerCase()
                          .includes(tagText.toLowerCase())
                      )
                      .map((tag: any, i: number) => (
                        <div key={tag.tagId}>
                          <div
                            className={classNames(
                              "flex items-center justify-between px-4 py-2 cursor-pointer",
                              selectedTags.find((x) => x.tagId === tag.tagId)
                                ? "bg-tgpro-blue-1/15 text-tgpro-blue-1"
                                : "text-tgpro-grey-1 bg-tgpro-black-2"
                            )}
                            onClick={() => {
                              // if (selectedTagIds.includes(tag.tagId)) {
                              //   onUnLinkTag(tag.tagId);
                              // } else onLinkTags(tag.tagId, tag.tagName);
                              let temp = [...selectedTags];
                              const index = temp.findIndex(
                                (x) => x.tagId === tag.tagId
                              );
                              if (index !== -1) {
                                temp.splice(index, 1);
                              } else {
                                temp.push(tag);
                              }
                              setSelectedTags(temp);
                            }}
                          >
                            {/* {editTagId === tag.tagId ? (
                              <input
                                className="h-full bg-transparent outline-none border-none text-11 leading-13 font-normal font-plus-jakarta"
                                defaultValue={tag.tagName}
                                id={`${tag.tagName}-${tag.tagId}`}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    onEditTag(
                                      tag.tagId,
                                      (e.target as any).value
                                    );
                                  }
                                }}
                              />
                            ) : ( */}
                            <div
                              className={classNames(
                                "flex items-center gap-2 relative w-full outline-none text-11 leading-13 font-normal font-plus-jakarta rounded-lg"
                              )}
                            >
                              <Checkbox
                                checked={selectedTags.find(
                                  (x) => x.tagId === tag.tagId
                                )}
                              />
                              {tag?.tagName}
                            </div>
                            {/* )} */}
                            {/* {editTagId === tag.tagId ? (
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  const value = (
                                    document.getElementById(
                                      `${tag.tagName}-${tag.tagId}`
                                    )! as HTMLInputElement
                                  )?.value;
                                  onEditTag(tag.tagId, value);
                                }}
                              >
                                <FaCheck />
                              </div>
                            ) : (
                              <div
                                className="cursor-pointer"
                                onClick={() =>
                                  setEditTagId((prev) =>
                                    prev === tag.tagId ? "" : tag.tagId
                                  )
                                }
                              >
                                <MdMoreHoriz />
                              </div>
                            )} */}
                          </div>
                          {/* {editTagId === tag.tagId && (
                            <div
                              className="flex items-center justify-center gap-1 px-4 py-2 text-11 leading-13 font-normal font-plus-jakarta text-tgpro-red-1 bg-tgpro-red-1/15 w-max rounded-lg mt-4 cursor-pointer"
                              onClick={() => onRemoveTag(tag.tagId)}
                            >
                              <HiOutlineTrash className="h-4 w-4" />
                              <div>Delete</div>
                            </div>
                          )} */}
                          {i !==
                            ((getUserTagsState as []) || [])?.length - 1 && (
                            <div className="h-[0.5px] my-2 bg-tgpro-black-3"></div>
                          )}
                        </div>
                      ))}
                  </>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="self-end">
          <Button
            name="Add Tags"
            className="!w-[132px]"
            onClick={onLinkTags}
            loading={linkTagsStateLoading}
            disabled={linkTagsStateLoading || selectedTags?.length === 0}
          />
        </div>
      </div>
    </div>
  );
};
