import { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { FaChevronDown, FaSpinner } from "react-icons/fa";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getUser } from "../../utils/helpers";
import useTDLibController from "../../controllers/useTDLibController";
import { useDispatch } from "react-redux";
import { getStages, linkStage } from "../../redux/slices/stageSlice";

const Sorting = () => {
  const [showSortbyPopover, setShowSortbyPopover] = useState(false);

  const {
    getStagesState,
    openedChatState,
    linkStageLoading,
    getStagesStateLoading,
    showPipelinePage,
  } = useSelector((state: RootState) => ({
    getStagesState: state.stage.getStagesState.data,
    openedChatState: state.chat.openedChatState.data,
    linkStageLoading: state.stage.linkStageState.loading,
    getStagesStateLoading: state.stage.getStagesState.loading,
    showPipelinePage: state.auth.showPipelinePage,
  }));
  const { tdClient } = useTDLibController();
  const dispatch = useDispatch();
  const [selectedFilter, setSelectFilter] = useState({
    stageId: "",
    stageName: null,
  });

  const sortingModalRef = useRef<HTMLInputElement>(null);
  useOutsideClick(sortingModalRef, () => setShowSortbyPopover(false));

  const handleFilterChange = (data: any) => {
    setSelectFilter(data);
    setShowSortbyPopover(false);
    !showPipelinePage && handleLinkStage(data?.stageId);
  };
  const handleLinkStage = (stageId: string) => {
    if (!selectedFilter) return;
    dispatch(
      // @ts-ignore
      linkStage({
        data: {
          telegramId: getUser()?.id,
          stageId: stageId,
          chatId: openedChatState?.id,
        },
        onSuccess: (res: any) => {},
        onError: (err: any) => {
          console.error("Error creating stage:", err);
        },
      })
    );
  };

  const callGetStages = async () => {
    let user = getUser();
    if (!user?.id) {
      user = await tdClient?.send({ "@type": "getMe" });
    }
    if (user?.id) {
      localStorage.setItem("user", JSON.stringify(user));
      dispatch(
        // @ts-ignore
        getStages({
          // @ts-ignore
          data: { telegramId: user.id },
          onSuccess: (res: any) => {},
          onError: (err: any) => {
            console.log(err);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (!showPipelinePage) {
      callGetStages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="relative" ref={sortingModalRef}>
      {linkStageLoading || getStagesStateLoading ? (
        <FaSpinner className="h-6 w-6 text-tgpro-blue-1 animate-spin mx-auto" />
      ) : (
        <>
          <div
            className={classNames(
              "flex items-center justify-between gap-2 px-4 py-3 text-xs leading-13 font-normal font-plus-jakarta w-full rounded cursor-pointer text-tgpro-white-3 bg-tgpro-black-2"
            )}
            onClick={() => setShowSortbyPopover(!showSortbyPopover)}
          >
            <span>
              {selectedFilter?.stageName
                ? selectedFilter?.stageName
                : "Select Stage"}
            </span>
            <FaChevronDown className="text-tgpro-grey-1" />
          </div>
          <div className="w-full">
            {showSortbyPopover && !showPipelinePage && (
              <div className="z-[999] w-full bg-tgpro-black-1 rounded">
                <div className="max-h-[170px] overflow-auto mt-1 py-2 rounded px-2 bg-tgpro-black-2">
                  {getStagesState?.data?.map((data: any, index: number) => (
                    <div key={data?.stageId}>
                      <div
                        onClick={() => handleFilterChange(data)}
                        className={classNames(
                          "flex items-center gap-2 relative px-4 py-2 w-full outline-none text-11 leading-13 font-normal font-plus-jakarta bg-tgpro-black-2 cursor-pointer rounded text-tgpro-grey-1 hover:text-tgpro-blue-1 hover:bg-tgpro-blue-1/15",
                          selectedFilter?.stageName === data?.stageName
                            ? ""
                            : " bg-tgpro-black-2"
                        )}
                      >
                        {data?.stageName}
                      </div>
                      {index < getStagesState?.data?.length - 1 && (
                        <div className="h-[0.5px] my-1 bg-tgpro-black-3"></div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default Sorting;
