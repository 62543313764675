import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IStageSliceInitialState } from "../../types";
import {
  deleteStageApi,
  getPipelineStagesApi,
  postCreateStageApi,
  postLinkStageApi,
  postUnLinkStageApi,
  updateStagesApi,
} from "../../utils/apis/stage";

const initialState: IStageSliceInitialState = {
  getStagesState: {
    loading: false,
    data: null,
  },
  postCreateStageState: {
    loading: false,
    data: null,
  },
  deleteStageState: {
    loading: false,
    data: null,
  },
  updateStagesState: {
    loading: false,
    data: null,
  },
  linkStageState: {
    loading: false,
    data: null,
  },
  unlinkStageState: {
    loading: false,
    data: null,
  },
};

export const getStages = createAsyncThunk(
  "pipeline/stages",
  getPipelineStagesApi
);

export const postCreateStage = createAsyncThunk(
  "/pipeline/create_stage",
  postCreateStageApi
);
export const deleteStages = createAsyncThunk(
  "pipeline/delete_stages",
  deleteStageApi
);
export const updateStages = createAsyncThunk(
  "/pipeline/update_stages",
  updateStagesApi
);

export const linkStage = createAsyncThunk(
  "/pipeline/link_stage",
  postLinkStageApi
);
export const unlinkStage = createAsyncThunk(
  "/pipeline/unlink_stage",
  postUnLinkStageApi
);

const stageSlice = createSlice({
  name: "stage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStages.pending, (state) => {
        state.getStagesState.loading = true;
      })
      .addCase(getStages.fulfilled, (state, action) => {
        state.getStagesState.loading = false;
        state.getStagesState.data = action.payload;
      })
      .addCase(getStages.rejected, (state, action) => {
        state.getStagesState.loading = false;
        state.getStagesState.data = null;
      })
      .addCase(postCreateStage.pending, (state) => {
        state.postCreateStageState.loading = true;
      })
      .addCase(postCreateStage.fulfilled, (state, action) => {
        state.postCreateStageState.loading = false;
        state.postCreateStageState.data = action.payload;
      })
      .addCase(postCreateStage.rejected, (state, action) => {
        state.postCreateStageState.loading = false;
        state.postCreateStageState.data = null;
      })
      .addCase(deleteStages.pending, (state) => {
        state.deleteStageState.loading = true;
      })
      .addCase(deleteStages.fulfilled, (state, action) => {
        state.deleteStageState.loading = false;
        state.deleteStageState.data = action.payload;
      })
      .addCase(deleteStages.rejected, (state, action) => {
        state.deleteStageState.loading = false;
        state.deleteStageState.data = null;
      })
      .addCase(updateStages.pending, (state) => {
        state.updateStagesState.loading = true;
      })
      .addCase(updateStages.fulfilled, (state, action) => {
        state.updateStagesState.loading = false;
        state.updateStagesState.data = action.payload;
      })
      .addCase(updateStages.rejected, (state, action) => {
        state.updateStagesState.loading = false;
        state.updateStagesState.data = null;
      })
      .addCase(linkStage.pending, (state) => {
        state.linkStageState.loading = true;
      })
      .addCase(linkStage.fulfilled, (state, action) => {
        state.linkStageState.loading = false;
        state.linkStageState.data = action.payload;
      })
      .addCase(linkStage.rejected, (state, action) => {
        state.linkStageState.loading = false;
        state.linkStageState.data = null;
      })
      .addCase(unlinkStage.pending, (state) => {
        state.unlinkStageState.loading = true;
      })
      .addCase(unlinkStage.fulfilled, (state, action) => {
        state.unlinkStageState.loading = false;
        state.unlinkStageState.data = action.payload;
      })
      .addCase(unlinkStage.rejected, (state, action) => {
        state.unlinkStageState.loading = false;
        state.unlinkStageState.data = null;
      });
  },
});

export default stageSlice.reducer;
