import { PiMagnifyingGlass, PiPaperPlaneTiltFill } from "react-icons/pi";
import { Header } from "../../components/Header";
import { Textarea } from "../../components/Textarea";
import { RiAttachment2 } from "react-icons/ri";
import { Input } from "../../components/Input";
import { useEffect, useRef, useState } from "react";
import { Tags } from "../../components/Header/Tags";
import classNames from "classnames";
import { Checkbox } from "../../components/Checkbox";
import { MdStar } from "react-icons/md";
import { HiEmojiHappy, HiUserGroup } from "react-icons/hi";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useDispatch } from "react-redux";
import Picker from "@emoji-mart/react";
import useOutsideClick from "../../hooks/useOutsideClick";
import data from "@emoji-mart/data";
import { Button } from "../../components/Button";
import useTDLibController from "../../controllers/useTDLibController";
import { setShowBulkMessagePage } from "../../redux/slices/authSlice";
import { setOpenChat, setSelectedChats } from "../../redux/slices/chatSlice";

const BulkMessagePage = () => {
  const dispatch = useDispatch();
  const { tdClient } = useTDLibController();

  const { tdChatListState, selectedChats } = useSelector(
    (state: RootState) => ({
      tdChatListState: state.chat.tdChatListState.data,
      selectedChats: state.chat.selectedChats,
    })
  );

  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [messageText, setMessageText] = useState("");
  const [showSelected, setShowSelected] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageText(e.target.value);
  };
  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0];
  //   if (file) {
  //     setMessageText(messageText + "\n" + file.name);
  //   }
  // };

  const pickerRef = useRef<HTMLInputElement>(null);
  useOutsideClick(pickerRef, () => setShowEmojiPicker(false));
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowEmojiPicker(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const sendMessage = async () => {
    if (messageText) {
      setLoading(true);
      for (const chat of selectedChats) {
        await tdClient?.send({
          "@type": "sendMessage",
          chat_id: chat?.id,
          input_message_content: {
            "@type": "inputMessageText",
            text: {
              "@type": "formattedText",
              text: messageText,
              entities: [],
            },
            disable_web_page_preview: false,
            clear_draft: true,
          },
        });
      }

      dispatch(setShowBulkMessagePage(false));
      dispatch(setOpenChat(null));
      setLoading(false);
    }
  };

  return (
    <>
      <main className="min-h-screen">
        <Header />
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col items-center justify-center mt-24 gap-4 w-[800px] relative">
            <h1 className="text-xl leading-[25.2px] font-normal font-plus-jakarta text-tgpro-white-1">
              Bulk Message
            </h1>
            <div className="flex gap-4 items-center bg-tgpro-black-2 py-3 px-4 rounded-lg">
              <PiPaperPlaneTiltFill className="text-tgpro-blue-1 w-4 h-4" />
              <span className="text-tgpro-white-1 text-xs max-w-[396px]">
                Messages are sent to all contact selected. There’s a few seconds
                gap between each sending to avoid being flagged as spam by
                Telegram
              </span>
            </div>
            <div className="w-full relative mt-2">
              <div className="grid grid-cols-2 gap-16 items-start">
                <div className="flex flex-col gap-2">
                  <span className="text-white font-semibold text-xs">
                    Compose Message{" "}
                  </span>
                  <span className="text-xs text-tgpro-grey-6">
                    Create a message you want to send to your contats
                  </span>
                  <div className="bg-tgpro-black-2 rounded-lg flex items-center flex-wrap gap-2 relative mt-2">
                    <Textarea
                      className="!h-[188px]"
                      placeholder="Type Message"
                      value={messageText}
                      onChange={handleMessageChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          // sendMessage();
                        }
                      }}
                    />
                    <div className="w-full mt-5 ">
                      <div className="absolute right-6 bottom-2">
                        <div className="flex items-center gap-2">
                          <HiEmojiHappy
                            className="w-4 h-4 text-tgpro-grey-6 cursor-pointer"
                            onClick={() => setShowEmojiPicker((prev) => !prev)}
                          />
                          {showEmojiPicker && (
                            <div
                              className="absolute bottom-[calc(100%+16px)] z-10"
                              ref={pickerRef}
                            >
                              <Picker
                                data={data}
                                onEmojiSelect={(emoji: any) =>
                                  setMessageText(messageText + emoji?.native)
                                }
                              />
                            </div>
                          )}
                          {/* <div className="relative">
                            <label
                              htmlFor="file-picker"
                              className="cursor-pointer"
                            >
                              <RiAttachment2 className="text-tgpro-grey-5 h-4 w-4 cursor-pointer" />
                            </label>
                            <input
                              id="file-picker"
                              type="file"
                              className="hidden"
                              onChange={handleFileChange}
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    name={`Send to ${selectedChats?.length} contacts`}
                    className="mt-8"
                    onClick={sendMessage}
                    loading={loading}
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <span className="text-white text-xs ">
                        List of contacts ({selectedChats?.length} contacts
                        selected){" "}
                      </span>
                    </div>
                    <button
                      className="text-tgpro-blue-1 underline underline-offset-2 text-xs"
                      onClick={() => setShowSelected(!showSelected)}
                    >
                      {showSelected ? "Show All" : "Show Selected"}
                    </button>
                  </div>
                  <div className="flex gap-4 items-center">
                    <div className="w-full">
                      <Input
                        placeholder="Search"
                        postFix={
                          <PiMagnifyingGlass className="text-tgpro-grey-1" />
                        }
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                    {/* <Tags className="rounded-lg" /> */}
                  </div>
                  <div className="max-h-[330px] overflow-auto flex flex-col">
                    {(tdChatListState || [])
                      .filter((x: any) => {
                        let searchTextFlag = true;
                        if (searchText)
                          searchTextFlag = x.title
                            .toLowerCase()
                            .includes(searchText.toLowerCase());
                        if (showSelected) {
                          return selectedChats.includes(x) && searchTextFlag;
                        }
                        return searchTextFlag;
                      })
                      .map((chat: any, i) => (
                        <div key={chat.id} className="mr-2">
                          <div
                            className={classNames(
                              "flex items-center gap-2 relative p-4 w-full outline-none text-xs leading-13 font-normal font-plus-jakarta text-white border border-tgpro-black-2 cursor-pointer"
                            )}
                            onClick={() => {
                              let temp = [...selectedChats];
                              const index = temp.findIndex(
                                (x) => x.id === chat.id
                              );
                              if (index !== -1) {
                                temp.splice(index, 1);
                              } else {
                                temp.push(chat);
                              }
                              dispatch(setSelectedChats(temp));
                            }}
                          >
                            <Checkbox
                              checked={selectedChats.find(
                                (x) => x.id === chat.id
                              )}
                            />
                            {chat?.dbData?.isFavorite && (
                              <MdStar className="text-[#FCAF03] cursor-pointer" />
                            )}
                            <HiUserGroup className="text-tgpro-grey-1" />
                            {chat?.title}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default BulkMessagePage;
