"use client";

import { useSelector } from "react-redux";
import useTDLibController from "../../controllers/useTDLibController";
import { RootState } from "../../redux/store";
import { useEffect } from "react";
import { FaSpinner } from "react-icons/fa";

export const QRScannerPage = () => {
  const { tdClient, isReady } = useTDLibController();
  const { qrLoading } = useSelector((state: RootState) => ({
    qrLoading: state.auth.qrLoading,
  }));

  useEffect(() => {
    (async () => {
      if (isReady) {
        await tdClient
          ?.send({
            "@type": "requestQrCodeAuthentication",
          })
          .then((res: any) => console.log({ res }))
          .catch((err: any) => console.log({ err }));
      }
    })();
  }, [isReady]);

  const onMobileLoginRequest = async () => {
    // dispatch(setShowPhoneNumberLoginPage(true));
    localStorage.clear();
    sessionStorage.clear();

    const dbs = await indexedDB.databases();
    dbs.forEach((db) => indexedDB.deleteDatabase(db.name as string));
    window.location.reload();
  };

  return (
    <main className="min-h-screen pt-20">
      <span className="flex items-center p-4 bg-tgpro-blue-1 text-white rounded-lg sm:w-max text-13 leading-[16.38px] font-normal font-plus-jakarta sm:mx-auto w-auto mx-4">
        TGpro will never have access to your chats and conversations.
        <a
          href="https://core.telegram.org/tdlib"
          target="_blank"
          rel="noopener noreferrer"
          className="underline cursor-pointer pl-0.5 text-13 underline-offset-2 font-semibold"
        >
          Learn more here
        </a>
      </span>
      <div className="flex flex-col items-center justify-center gap-10 p-24">
        <div className="flex items-center relative">
          <h1 className="text-xl leading-[25.2px] font-bold font-plus-jakarta text-tgpro-white-1 z-20">
            Welcome to TGpro
          </h1>
          <div className="h-[36.95px] w-[73.74px] bg-tgpro-blue-1 absolute top-1/2 -translate-y-1/2 z-10 -right-2 -rotate-[1.63deg]"></div>
        </div>
        <div className="flex items-center gap-3">
          <span className="text-tgpro-white-3 text-sm ">
            By logging I accept TGpro's{" "}
            <a
              href="https://www.tgpro.xyz/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="underline underline-offset-4 cursor-pointer"
            >
              privacy policy
            </a>{" "}
            &{" "}
            <a
              href="https://www.tgpro.xyz/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className="underline underline-offset-4 cursor-pointer"
            >
              terms and conditions
            </a>
            .
          </span>
        </div>
        <div className="flex flex-col items-center gap-8">
          <div className="text-[13px] leading-[16.38px] font-normal font-plus-jakarta text-white text-center space-y-2">
            <p>1. Open Telegram on your phone</p>
            <p>{"2. Go to Setting > Devices > Link Desktop Device"}</p>
            <p>3. Scan this image to connect</p>
          </div>
        </div>
        <div className="flex items-center justify-center h-[168px] w-[168px] relative bg-white p-2 rounded-2xl overflow-hidden">
          <div
            id="qr-canvas"
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-[0.9]"
          />
          {qrLoading ? (
            <FaSpinner className="h-6 w-6 text-tgpro-blue-1 animate-spin" />
          ) : (
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-[0.9] bg-white">
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 160 160"
                version="1.1"
                fill="#03a9f4"
              >
                <g id="Login" stroke="none" strokeWidth="1" fillRule="evenodd">
                  <g
                    id="Login_5_Phone"
                    transform="translate(-760.000000, -186.000000)"
                  >
                    <path d="M840,346 C795.81722,346 760,310.18278 760,266 C760,221.81722 795.81722,186 840,186 C884.18278,186 920,221.81722 920,266 C920,310.18278 884.18278,346 840,346 Z M794.596303,264.66919 C790.659174,266.234556 788.596733,267.765936 788.408978,269.263329 C788.048509,272.138166 792.188142,273.033142 797.396667,274.725906 C801.643184,276.106018 807.355495,277.720609 810.325092,277.784753 C813.018799,277.842938 816.025284,276.732617 819.344546,274.453789 C841.998088,259.16492 853.691946,251.437186 854.426119,251.270588 C854.944065,251.153055 855.661811,251.005273 856.148118,251.437434 C856.634425,251.869594 856.586618,252.688022 856.535106,252.907586 C856.123224,254.663156 834.84286,274.015606 833.61816,275.287388 C828.938462,280.146996 823.615302,283.120543 831.826781,288.530755 C838.932147,293.212193 843.067503,296.19863 850.387542,300.996094 C855.065474,304.061953 858.733031,307.697601 863.562866,307.253237 C865.785287,307.048765 868.080902,304.959403 869.246762,298.727987 C872.002037,284.001293 877.417883,252.09303 878.669527,238.944397 C878.779187,237.792407 878.641237,236.318088 878.530455,235.670896 C878.419672,235.023705 878.188168,234.101592 877.346762,233.418979 C876.350285,232.610561 874.811937,232.440083 874.123923,232.4522 C870.995743,232.507295 866.196346,234.175802 843.099163,243.78089 C835.009657,247.144954 818.842037,254.107721 794.596303,264.66919 Z" />
                  </g>
                </g>
              </svg>
            </div>
          )}
        </div>
      </div>

      {/* <div className="text-13 leading-16 text-tgpro-grey-2 font-normal font-plus-jakarta text-center">
        Login with phone number{" "}
        <span
          className="text-tgpro-blue-1 cursor-pointer"
          onClick={onMobileLoginRequest}
        >
          here
        </span>
      </div> */}
    </main>
  );
};
