import { IconProps } from ".";

const TrashIcon = (props: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.59146 1.87502H11.4093C11.5897 1.8749 11.7468 1.8748 11.8951 1.89849C12.4812 1.99208 12.9884 2.35762 13.2625 2.88403C13.3319 3.01727 13.3814 3.16634 13.4384 3.33745L13.5314 3.61654C13.5471 3.66378 13.5516 3.67715 13.5554 3.68768C13.7014 4.09111 14.0797 4.36383 14.5086 4.3747C14.5199 4.37498 14.5337 4.37503 14.5837 4.37503H17.0837C17.4289 4.37503 17.7087 4.65485 17.7087 5.00003C17.7087 5.34521 17.4289 5.62503 17.0837 5.62503H2.91699C2.57181 5.62503 2.29199 5.34521 2.29199 5.00003C2.29199 4.65485 2.57181 4.37503 2.91699 4.37503H5.41706C5.4671 4.37503 5.48091 4.37498 5.49219 4.3747C5.92106 4.36383 6.29941 4.09113 6.44534 3.6877C6.44918 3.67709 6.45359 3.66401 6.46942 3.61654L6.56242 3.33747C6.61934 3.16637 6.66893 3.01728 6.73831 2.88403C7.01243 2.35762 7.5196 1.99208 8.10568 1.89849C8.25403 1.8748 8.41113 1.8749 8.59146 1.87502ZM7.50712 4.37503C7.55004 4.29085 7.58808 4.20337 7.62081 4.1129C7.63074 4.08543 7.64049 4.05619 7.65301 4.01861L7.73618 3.7691C7.81215 3.54118 7.82965 3.49469 7.847 3.46137C7.93837 3.2859 8.10743 3.16405 8.30279 3.13285C8.33989 3.12693 8.38952 3.12503 8.62978 3.12503H11.371C11.6113 3.12503 11.6609 3.12693 11.698 3.13285C11.8934 3.16405 12.0624 3.2859 12.1538 3.46137C12.1711 3.49469 12.1886 3.54117 12.2646 3.7691L12.3477 4.01846L12.38 4.11292C12.4127 4.20338 12.4507 4.29085 12.4937 4.37503H7.50712Z"
        fill="currentColor"
      />
      <path
        d="M4.92957 7.04179C4.90661 6.69738 4.60879 6.43679 4.26438 6.45975C3.91996 6.48271 3.65937 6.78053 3.68234 7.12494L4.06854 12.9181C4.13979 13.987 4.19734 14.8505 4.33232 15.528C4.47265 16.2324 4.71133 16.8208 5.20433 17.2821C5.69732 17.7433 6.30028 17.9423 7.01249 18.0355C7.69751 18.1251 8.56286 18.125 9.63416 18.125H10.3666C11.4379 18.125 12.3033 18.1251 12.9883 18.0355C13.7005 17.9423 14.3035 17.7433 14.7965 17.2821C15.2895 16.8208 15.5281 16.2324 15.6685 15.528C15.8034 14.8505 15.861 13.987 15.9322 12.9181L16.3185 7.12494C16.3414 6.78053 16.0808 6.48271 15.7364 6.45975C15.392 6.43679 15.0942 6.69738 15.0712 7.04179L14.6879 12.7911C14.6131 13.9143 14.5597 14.6958 14.4426 15.2838C14.3289 15.8542 14.1703 16.1561 13.9425 16.3692C13.7146 16.5824 13.4028 16.7206 12.8262 16.796C12.2317 16.8738 11.4483 16.875 10.3226 16.875H9.67816C8.55246 16.875 7.76912 16.8738 7.17462 16.796C6.59795 16.7206 6.28615 16.5824 6.05831 16.3692C5.83046 16.1561 5.67185 15.8542 5.55823 15.2838C5.44109 14.6958 5.38773 13.9143 5.31285 12.7911L4.92957 7.04179Z"
        fill="currentColor"
      />
      <path
        d="M7.85488 8.5448C8.19834 8.51045 8.50462 8.76104 8.53897 9.10451L8.95563 13.2712C8.98998 13.6146 8.73939 13.9209 8.39593 13.9553C8.05246 13.9896 7.74618 13.739 7.71184 13.3956L7.29517 9.22889C7.26082 8.88542 7.51141 8.57915 7.85488 8.5448Z"
        fill="currentColor"
      />
      <path
        d="M12.1459 8.5448C12.4894 8.57915 12.74 8.88542 12.7056 9.22889L12.289 13.3956C12.2546 13.739 11.9483 13.9896 11.6049 13.9553C11.2614 13.9209 11.0108 13.6146 11.0452 13.2712L11.4618 9.10451C11.4962 8.76104 11.8025 8.51045 12.1459 8.5448Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default TrashIcon;
