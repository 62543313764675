import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  cancelSubscriptionApi,
  checkPaymentInfoApi,
  createCheckoutSessionApi,
  getPaymentPlansApi,
} from "../../utils/apis/auth";
import { IAuthSliceInitialState } from "../../types";

const initialState: IAuthSliceInitialState = {
  // THUNK STATES
  getPaymentPlansState: {
    loading: false,
    data: null,
  },
  createCheckoutSessionState: {
    loading: false,
    data: null,
  },
  checkPaymentInfoState: {
    loading: false,
    data: null,
  },
  cancelSubscriptionState: {
    loading: false,
    data: null,
  },

  // REDUCER STATES
  qrLoading: true,
  authState: null,
  showPhoneNumberLoginPage: true,
  showChatSyncPage: true,
  showDraftSyncPage: false,
  me: null,
  showPaymentPage: false,
  showSettingsPage: false,
  showBulkMessagePage: false,
  showPipelinePage: false,
};

export const getPaymentPlans = createAsyncThunk(
  "auth/get-payment-plans",
  getPaymentPlansApi
);

export const createCheckoutSession = createAsyncThunk(
  "auth/create-checkout-session",
  createCheckoutSessionApi
);

export const checkPaymentInfo = createAsyncThunk(
  "auth/check-payment",
  checkPaymentInfoApi
);

export const cancelSubscription = createAsyncThunk(
  "auth/cancel-subscription",
  cancelSubscriptionApi
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getPaymentPlans.pending, (state, action) => {
        state.getPaymentPlansState.loading = true;
      })
      .addCase(getPaymentPlans.fulfilled, (state, action) => {
        state.getPaymentPlansState.loading = false;
        state.getPaymentPlansState.data = action.payload;
      })
      .addCase(getPaymentPlans.rejected, (state, action) => {
        state.getPaymentPlansState.loading = false;
      })
      .addCase(createCheckoutSession.pending, (state, action) => {
        state.createCheckoutSessionState.loading = true;
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.createCheckoutSessionState.loading = false;
        state.createCheckoutSessionState.data = action.payload;
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.createCheckoutSessionState.loading = false;
      })
      .addCase(checkPaymentInfo.pending, (state, action) => {
        state.checkPaymentInfoState.loading = true;
      })
      .addCase(checkPaymentInfo.fulfilled, (state, action) => {
        state.checkPaymentInfoState.loading = false;
        state.checkPaymentInfoState.data = action.payload;
      })
      .addCase(checkPaymentInfo.rejected, (state, action) => {
        state.checkPaymentInfoState.loading = false;
      })
      .addCase(cancelSubscription.pending, (state, action) => {
        state.cancelSubscriptionState.loading = true;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.cancelSubscriptionState.loading = false;
        state.cancelSubscriptionState.data = action.payload;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.cancelSubscriptionState.loading = false;
      });
  },
  reducers: {
    setQrLoading: (state, action) => {
      state.qrLoading = action.payload;
    },
    updateAuthState: (state, action) => {
      state.authState = action.payload;
    },
    setShowPhoneNumberLoginPage: (state, action) => {
      state.showPhoneNumberLoginPage = action.payload;
    },
    setShowChatSyncPage: (state, action) => {
      state.showChatSyncPage = action.payload;
    },
    setShowDraftSyncPage: (state, action) => {
      state.showDraftSyncPage = action.payload;
    },
    setShowSettingsPage: (state, action) => {
      state.showSettingsPage = action.payload;
    },
    setShowBulkMessagePage: (state, action) => {
      state.showBulkMessagePage = action.payload;
    },
    setShowPipelinePage: (state, action) => {
      state.showPipelinePage = action.payload;
    },
    setMe: (state, action) => {
      state.me = action.payload;
    },
    setShowPaymentPage: (state, action) => {
      state.showPaymentPage = action.payload;
    },
  },
});

export const {
  setQrLoading,
  updateAuthState,
  setShowPhoneNumberLoginPage,
  setShowChatSyncPage,
  setShowDraftSyncPage,
  setShowSettingsPage,
  setMe,
  setShowPaymentPage,
  setShowBulkMessagePage,
  setShowPipelinePage,
} = authSlice.actions;
export default authSlice.reducer;
