import {
  IAddBulkFollowupReq,
  IAddFollowupReq,
  ICompleteFollowupReq,
  ReduxOptions,
} from "../../types";
import { axiosInterceptors } from "../helpers";

export const addFolloupApi = async (
  option: ReduxOptions<IAddFollowupReq, { followUpId: string }>
) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/add_followup`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const completeFolloupApi = async (
  option: ReduxOptions<ICompleteFollowupReq, { followUpId: string }>
) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/complete_followup`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const removeFolloupApi = async (
  option: ReduxOptions<
    Omit<{ followup_id: string }, "tagName">,
    { tagId: string }
  >
) => {
  try {
    const response = await axiosInterceptors(
      `${process.env.REACT_APP_API_URL}/delete_followup`,
      { method: "delete", params: { ...option.data } }
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const addBulkFolloupApi = async (
  option: ReduxOptions<IAddBulkFollowupReq, { followUpId: string }>
) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/add_bulk_followup`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};
