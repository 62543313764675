import { useEffect, useState } from "react";
import useTDLibController from "../../controllers/useTDLibController";
import { FaSpinner } from "react-icons/fa";
import PreviewImageModal from "../../components/PreviewImageModal";
import {
  closeImageModal,
  openImageModal,
  updateMessageText,
} from "../../redux/slices/chatSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useDispatch } from "react-redux";
import { AiFillFile } from "react-icons/ai";

const FormatReplyMessage = ({
  replyToMessageId,
  replyInChatId,
}: {
  replyToMessageId: number;
  replyInChatId: number;
}) => {
  const { tdClient, isReady } = useTDLibController();

  const [message, setMessage] = useState<any>(undefined);

  useEffect(() => {
    if (isReady)
      tdClient
        ?.send({
          "@type": "getMessage",
          chat_id: replyInChatId,
          message_id: replyToMessageId,
        })
        .then((message: any) => {
          if (message?.sender_id?.user_id)
            tdClient
              ?.send({
                "@type": "getUser",
                user_id: message?.sender_id?.user_id,
              })
              .then((user: any) => {
                setMessage({ ...message, user });
              })
              .catch((error: any) => console.log({ error }));
          else setMessage({ ...message });
        })
        .catch((error: any) => console.log({ error }));
  }, [isReady]);

  return (
    <div className="p-1 px-2 bg-tgpro-black-3 border-l border-tgpro-blue-1">
      <div className="text-xs leading-5 text-tgpro-blue-1">
        {message?.user?.first_name}
      </div>
      {generateMessage({
        text: message?.content?.text?.text || message?.content?.emoji,
        entities: message?.content?.text?.entities,
      })}
    </div>
  );
};

const ForwardInfo = ({ forwardInfo }: { forwardInfo: any }) => {
  const { tdClient, isReady } = useTDLibController();

  const [user, setUser] = useState<any>(undefined);

  useEffect(() => {
    if (isReady && forwardInfo?.origin?.sender_user_id)
      tdClient
        ?.send({
          "@type": "getUser",
          user_id: forwardInfo?.origin?.sender_user_id,
        })
        .then((user: any) => {
          setUser(user);
        })
        .catch((error: any) => console.log({ error }));
  }, [isReady]);

  if (!forwardInfo?.origin?.sender_user_id) return null;

  return (
    <div className="text-11 leading-13 text-tgpro-blue-1">
      Forwarded from {user?.first_name}
    </div>
  );
};

const detectCommands = (
  text: string,
  sendMessage: (command: string) => void
) => {
  const commandPattern = /\/(\w+)/g;
  const parts = text.split(/(https?:\/\/[^\s]+|\/\w+)/g);
  return parts.map((part, index) => {
    if (commandPattern.test(part)) {
      const command = `/${part.slice(1)}`;
      return (
        <span
          key={index}
          className="text-tgpro-blue-1 underline underline-offset-2 cursor-pointer"
          onClick={() => sendMessage(command)}
        >
          {command}
        </span>
      );
    } else {
      return part;
    }
  });
};

const detectLinks = (text: string) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  return text?.split(urlPattern)?.map((part, index) => {
    if (urlPattern.test(part)) {
      return (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="underline underline-offset-2"
        >
          {part}
        </a>
      );
    }
    return part;
  });
};

const formatText = ({
  text,
  type,
  entity,
  sendMessage,
}: {
  text: string;
  type?: string;
  entity?: any;
  sendMessage: (command: string) => void;
}) => {
  if (type === "textEntityTypeMention") {
    return <span className="text-tgpro-blue-1">{text}</span>;
  } else if (type === "textEntityTypeBold") {
    return <span className="font-semibold">{text}</span>;
  } else if (type === "textEntityTypeTextUrl") {
    return (
      <a
        href={entity?.type?.url}
        className="text-tgpro-blue-1 font-semibold"
        target="_"
      >
        {" "}
        {text}
      </a>
    );
  } else if (type === "textEntityTypeBotCommand") {
    return <span>{detectCommands(text, sendMessage)}</span>;
  }

  return <span>{detectLinks(text)}</span>;
};

const generateMessage = ({
  text = "",
  entities = [],
  replyToMessageId,
  replyInChatId,
  forwardInfo,
  sendMessage,
}: {
  text: string;
  entities: any[];
  replyToMessageId?: number;
  replyInChatId?: number;
  forwardInfo?: any;
  sendMessage?: any;
}) => {
  const data: any[] = [];
  let lastIndex = 0;

  entities.forEach((entity, i) => {
    if (entity.offset > lastIndex) {
      data.push(
        detectCommands(text.slice(lastIndex, entity.offset), sendMessage)
      );
    }

    data.push(
      formatText({
        text: text.slice(entity.offset, entity.offset + entity.length),
        type: entity.type["@type"],
        entity,
        sendMessage,
      })
    );

    lastIndex = entity.offset + entity.length;
  });

  if (lastIndex < text.length) {
    data.push(detectCommands(text.slice(lastIndex), sendMessage));
  }

  const updatedText = (
    <span className="whitespace-pre-wrap">{data.flat()}</span>
  );

  return (
    <div className="flex flex-col gap-1.5">
      {forwardInfo ? <ForwardInfo forwardInfo={forwardInfo} /> : null}
      {replyToMessageId && replyInChatId ? (
        <FormatReplyMessage
          replyToMessageId={replyToMessageId}
          replyInChatId={replyInChatId!}
        />
      ) : null}
      {updatedText}
    </div>
  );
};

export const Message = ({ message }: { message: any }) => {
  const { tdClient } = useTDLibController();
  const [messagePhotoUrl, setMessagePhotoUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const imageUrl =
    messagePhotoUrl ||
    `data:image/png;base64, ${message?.content?.photo?.minithumbnail?.data}`;

  const { currentImage, isModalOpen, openedChatState } = useSelector(
    (state: RootState) => ({
      currentImage: state.chat.showSelectedImage.currentImage,
      isModalOpen: state.chat.showSelectedImage.isModalOpen,
      openedChatState: state.chat.openedChatState,
    })
  );

  const sendMessage = async (messageText: string) => {
    if (messageText) {
      await tdClient?.send({
        "@type": "sendMessage",
        chat_id: openedChatState.data?.id,

        input_message_content: {
          "@type": "inputMessageText",
          text: {
            "@type": "formattedText",
            text: messageText,
            entities: [],
          },
          disable_web_page_preview: false,
          clear_draft: true,
        },
      });
      dispatch(updateMessageText(""));
    }
  };

  useEffect(() => {
    const setMessagePhotoUrlString = async (fileId: number) => {
      setIsLoading(true);
      try {
        const file: any = await tdClient?.send({
          "@type": "readFile",
          file_id: fileId,
        });

        blobToDataURL(file?.data, (src) => {
          setMessagePhotoUrl(src);
          setIsLoading(false);
        });
      } catch (error) {
        try {
          await tdClient?.send({
            "@type": "downloadFile",
            file_id: fileId,
            priority: 1,
            offset: 0,
            synchronous: true,
          });

          const file: any = await tdClient?.send({
            "@type": "readFile",
            file_id: fileId,
          });

          blobToDataURL(file?.data, (src) => {
            setMessagePhotoUrl(src);
            setIsLoading(false);
          });
        } catch (error) {
          console.log({ error });
          setIsLoading(false);
        }
      }
    };

    if (message?.content?.["@type"] === "messagePhoto") {
      let size =
        message?.content?.photo?.sizes?.length > 1
          ? message?.content?.photo?.sizes?.[1]
          : message?.content?.photo?.sizes?.[0];
      setMessagePhotoUrlString(size?.photo?.id);
    } else if (
      message?.content?.["@type"] === "messageAnimation" &&
      message?.content?.animation?.animation?.id
    ) {
      setMessagePhotoUrlString(message?.content?.animation?.animation?.id);
    }
  }, [message, tdClient]);

  function blobToDataURL(blob: Blob, callback: (args: any) => void) {
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(e.target!?.result);
    };
    reader.readAsDataURL(blob);
  }

  if (message?.content?.["@type"] === "messageAnimation") {
    return (
      <div className="space-y-1">
        {isLoading ? (
          <FaSpinner className="h-6 w-6 text-tgpro-blue-1 animate-spin mx-auto" />
        ) : (
          <div className="relative">
            <video
              width={message?.content?.animation?.width}
              height={message?.content?.animation?.height}
              autoPlay
              loop
            >
              <source src={imageUrl} type="video/mp4" />
            </video>
            <div className="absolute top-2 left-2 p-1 rounded-3xl w-8 bg-black/40 text-11 leading-13 font-medium font-plus-jakarta text-center">
              GIF
            </div>
          </div>
        )}
      </div>
    );
  } else if (message?.content?.["@type"] === "messagePhoto") {
    <div className="space-y-1">
      {isLoading ? (
        <FaSpinner className="h-6 w-6 text-tgpro-blue-1 animate-spin mx-auto" />
      ) : (
        <img
          src={imageUrl}
          height={"auto"}
          width={"auto"}
          className="max-h-[500px]"
          alt=""
          onClick={() => {
            dispatch(
              // @ts-ignore
              openImageModal(imageUrl)
            );
          }}
        />
      )}{" "}
      <PreviewImageModal
        isOpen={isModalOpen}
        onClose={() =>
          dispatch(
            // @ts-ignore
            closeImageModal()
          )
        }
        imageUrl={currentImage}
      />
      <div className="text-xs font-normal font-plus-jakarta text-white">
        {generateMessage({
          text: message?.content?.caption?.text,
          entities: message?.content?.caption?.entities,
          replyToMessageId: message?.reply_to_message_id,
          replyInChatId: message?.reply_in_chat_id,
          forwardInfo: message?.forward_info,
          sendMessage,
        })}
      </div>
    </div>;
    return (
      <div className="space-y-1">
        {isLoading ? (
          <FaSpinner className="h-6 w-6 text-tgpro-blue-1 animate-spin mx-auto" />
        ) : (
          <img
            src={imageUrl}
            height={"auto"}
            width={"auto"}
            className="max-h-[500px]"
            alt=""
            onClick={() => {
              dispatch(
                // @ts-ignore
                openImageModal(imageUrl)
              );
            }}
          />
        )}{" "}
        <PreviewImageModal
          isOpen={isModalOpen}
          onClose={() =>
            dispatch(
              // @ts-ignore
              closeImageModal()
            )
          }
          imageUrl={currentImage}
        />
        <div className="text-xs font-normal font-plus-jakarta text-white">
          {generateMessage({
            text: message?.content?.caption?.text,
            entities: message?.content?.caption?.entities,
            replyToMessageId: message?.reply_to_message_id,
            replyInChatId: message?.reply_in_chat_id,
            forwardInfo: message?.forward_info,
            sendMessage,
          })}
        </div>
      </div>
    );
  } else if (message?.content?.["@type"] === "messageAnimatedEmoji") {
    return (
      <div className="text-6xl font-normal font-plus-jakarta text-white">
        {message?.content?.emoji}
      </div>
    );
  } else if (message?.content?.["@type"] === "messageDocument") {
    return (
      <div className="text-xs font-normal font-plus-jakarta text-white flex gap-2 items-center">
        <AiFillFile className="w-4 h-4" />
        {message?.content?.document?.file_name}
      </div>
    );
  }

  return (
    <div className="text-xs font-normal font-plus-jakarta text-white">
      {generateMessage({
        text: message?.content?.text?.text,
        entities: message?.content?.text?.entities,
        replyToMessageId: message?.reply_to_message_id,
        replyInChatId: message?.reply_in_chat_id,
        forwardInfo: message?.forward_info,
        sendMessage,
      })}
    </div>
  );
};
