import moment from "moment";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addFolloup,
  setOpenChat,
  setTdChatList,
} from "../../redux/slices/chatSlice";
import { getDaysDifference, getUser } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FiCalendar } from "react-icons/fi";

export const AddFollowUp = () => {
  const dispatch = useDispatch();

  const { openedChatState, tdChatListState } = useSelector(
    (state: RootState) => ({
      openedChatState: state.chat.openedChatState.data,
      tdChatListState: state.chat.tdChatListState.data,
    })
  );

  const dateTimeRef = useRef<HTMLInputElement>(null);
  const [date, setDate] = useState(new Date().toString());

  const onAddFollowup = () => {
    if (date && getDaysDifference(date) > 0) {
      const user = getUser();

      dispatch(
        // @ts-ignore
        addFolloup({
          data: {
            telegramId: user.id,
            chatId: openedChatState?.id,
            followUpDate: moment(date).format("YYYY-MM-DDT00:00:00"),
          },
          onSuccess: (res) => {
            const temp = JSON.parse(JSON.stringify(tdChatListState));
            const index = temp.findIndex(
              (x: any) => x.id === openedChatState.id
            );
            const followUps = [...temp[index].dbData.followUps];
            const followUp = {
              createdAt: new Date(),
              followUpDate: moment(date).format("YYYY-MM-DDT00:00:00"),
              followUpId: res.data?.followUpId,
              isCompleted: false,
              isExpired: false,
            };
            temp[index] = {
              ...temp[index],
              dbData: {
                ...temp[index].dbData,
                followUps: [followUp, ...followUps],
              },
            };
            dispatch(setTdChatList(temp));
            dispatch(
              setOpenChat({
                ...openedChatState,
                dbData: {
                  ...temp[index].dbData,
                  followUps: [followUp, ...followUps],
                },
              })
            );
          },
        })
      );
    }
  };

  return (
    <div className="relative mt-8 flex items-center justify-between">
      <div className="flex items-center gap-[9px] text-xs leading-15 font-medium text-tgpro-blue-1 relative">
        <div>
          <FiCalendar />
        </div>
        Add a follow up in{" "}
        <span
          className="underline"
          onClick={() => dateTimeRef.current?.showPicker()}
        >
          <input
            type="text"
            className="w-3 bg-transparent outline-none border-b border-tgpro-blue-1"
            value={getDaysDifference(date)}
            readOnly
          />
        </span>{" "}
        days
        <input
          ref={dateTimeRef}
          type="date"
          className="invisible top-0 absolute bg-black"
          onChange={(e) => {
            setDate(e.target.value);
          }}
          min={(() => {
            return moment()
              .add(1, "day")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .toISOString()
              .split("T")[0];
          })()}
        />
      </div>
      <div
        className="flex items-center justify-center gap-1 px-2 py-1 text-11 leading-13 font-normal font-plus-jakarta text-tgpro-blue-1 bg-tgpro-blue-1/15 w-max rounded-lg cursor-pointer"
        onClick={onAddFollowup}
      >
        Add Follow-up
      </div>
    </div>
  );
};
