// import { configureStore } from '@reduxjs/toolkit'

// export const makeStore = () => {
// 	return configureStore({
// 		reducer: {},
// 	})
// }

// export type AppStore = ReturnType<typeof makeStore>

// export type RootState = ReturnType<AppStore['getState']>
// export type AppDispatch = AppStore['dispatch']

import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import chatSlice from "./slices/chatSlice";
import stageSlice from "./slices/stageSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    chat: chatSlice,
    stage: stageSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
