import { FaSpinner } from "react-icons/fa";
import classNames from "classnames";
import { HtmlHTMLAttributes, ReactNode } from "react";

interface IButtonProps extends HtmlHTMLAttributes<HTMLButtonElement> {
  name: string;
  loading?: boolean;
  preFix?: ReactNode;
  textClassName?: string;
  disabled?: boolean;
}

export const Button = ({
  name,
  loading,
  preFix,
  textClassName,
  className,
  disabled,
  ...props
}: IButtonProps) => {
  return (
    <button
      className={classNames(
        "flex items-center justify-center gap-2 relative h-[38px] w-full rounded-lg bg-tgpro-blue-1 text-white",
        loading ? "bg-opacity-50" : "bg-opacity-100",
        className
      )}
      disabled={disabled}
      {...props}
    >
      {preFix}
      {!loading ? (
        <span
          className={classNames(
            "text-11 leading-13 font-normal font-plus-jakarta text-white",
            textClassName
          )}
        >
          {name}
        </span>
      ) : (
        <FaSpinner className="text-white animate-spin" />
      )}
    </button>
  );
};
