import { IconProps } from ".";

const DraftIcon = (props: IconProps) => {
  return (
    <svg
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M56.0054 40.5L40.0054 56.4893L10.6779 56.5C9.2019 56.5 8.00537 55.3136 8.00537 53.8509V11.1491C8.00537 9.68603 9.1919 8.5 10.6544 8.5H53.3563C54.8192 8.5 56.0054 9.71536 56.0054 11.1733V40.5ZM50.672 13.8333H13.3387V51.1667H34.672V37.8333C34.672 36.4659 35.7014 35.3387 37.0278 35.1845L37.3387 35.1667L50.672 35.164V13.8333ZM48.4614 40.4973L40.0054 40.5V48.9507L48.4614 40.4973Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default DraftIcon;
