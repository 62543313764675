import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addFavouriteChatApi,
  checkConnection,
  getDraftsAPI,
  getKeyAPI,
  getLinkedChatsApi,
  linkChatsApi,
  postLinkDraftsApi,
  unlinkChatsApi,
} from "../../utils/apis/chat";
import {
  addTagApi,
  editTagApi,
  getUserTagsApi,
  linkTagsApi,
  removeTagApi,
  unlinkTagApi,
} from "../../utils/apis/tag";
import { IChatSliceInitialState } from "../../types";
import { addNoteApi, removeNoteApi } from "../../utils/apis/note";
import {
  addBulkFolloupApi,
  addFolloupApi,
  completeFolloupApi,
  removeFolloupApi,
} from "../../utils/apis/follow-up";

const initialState: IChatSliceInitialState = {
  // THUNK STATES
  linkChatsState: {
    loading: false,
    data: null,
  },
  unlinkChatsState: {
    loading: false,
    data: null,
  },
  getLinkedChatsState: {
    loading: false,
    data: null,
  },
  postConnectionState: {
    loading: false,
    data: null,
  },
  addFavouriteChatState: {
    loading: false,
    data: null,
  },
  addTagState: {
    loading: false,
    data: null,
  },
  editTagState: {
    loading: false,
    data: null,
  },
  removeTagState: {
    loading: false,
    data: null,
  },
  getUserTagsState: {
    loading: false,
    data: null,
  },
  linkTagsState: {
    loading: false,
    data: null,
  },
  unlinkTagState: {
    loading: false,
    data: null,
  },
  addNoteState: {
    loading: false,
    data: null,
  },
  removeNoteState: {
    loading: false,
    data: null,
  },
  addFolloupState: {
    loading: false,
    data: null,
  },
  completeFolloupState: {
    loading: false,
    data: null,
  },
  removeFollowUpState: {
    loading: false,
    data: null,
  },
  getDraftsState: {
    loading: false,
    data: null,
  },
  postLinkDraftsState: {
    loading: false,
    data: null,
  },

  // REDUCER STATES
  openedChatState: {
    loading: false,
    data: null,
  },
  tdChatListState: {
    loading: false,
    data: [],
  },
  chatMessagesState: {
    loading: false,
    data: [],
  },
  chatListFilterState: {
    tagIds: [],
    favorite: false,
    followups: false,
    sorting: {
      name: "Recent",
      value: "recent",
    },
    unansweredByMe: false,
    unread: false,
    unansweredByThem: false,
  },
  addBulkTagsModalState: {
    visible: false,
    chats: [],
  },
  addBulkFollowUpsModalState: { visible: false, chats: [] },
  addBulkFollowupState: {
    loading: false,
    data: null,
  },
  chatFoldersState: {
    data: [],
  },
  forwardMessagesModalState: {
    visible: false,
    data: undefined,
  },
  showSelectedImage: {
    isModalOpen: false,
    currentImage: null,
  },
  chatReadInboxOutboxData: {
    lastReadOutboxMessageId: undefined,
    lastReadInboxMessageId: undefined,
  },
  apiKeyState: {
    loading: false,
    data: null,
  },
  messageText: "",
  selectedChats: [],
};

export const linkChats = createAsyncThunk("chat/link", linkChatsApi);

export const checkConn = createAsyncThunk("conn/post", checkConnection);

export const unlinkChats = createAsyncThunk("chat/unlink", unlinkChatsApi);

export const getLinkedChats = createAsyncThunk("chat/get", getLinkedChatsApi);

export const addFavouriteChat = createAsyncThunk(
  "chat/favorite",
  addFavouriteChatApi
);

export const addTag = createAsyncThunk("tag/add", addTagApi);

export const editTag = createAsyncThunk("tag/edit", editTagApi);

export const removeTag = createAsyncThunk("tag/remove", removeTagApi);

export const getUserTags = createAsyncThunk(
  "tag/user-tags/get",
  getUserTagsApi
);

export const linkTags = createAsyncThunk("tag/link", linkTagsApi);

export const unlinkTag = createAsyncThunk("tag/unlink", unlinkTagApi);

export const addNote = createAsyncThunk("note/add", addNoteApi);

export const removeNote = createAsyncThunk("note/remove", removeNoteApi);

export const addFolloup = createAsyncThunk("followup/add", addFolloupApi);
export const addBulkFolloup = createAsyncThunk(
  "followup/add-bulk",
  addBulkFolloupApi
);

export const getDrafts = createAsyncThunk("drafts/get", getDraftsAPI);

export const getApiKey = createAsyncThunk("get_api/get", getKeyAPI);

export const postLinkDrafts = createAsyncThunk(
  "link_drafts/post",
  postLinkDraftsApi
);

export const completeFolloup = createAsyncThunk(
  "followup/complete",
  completeFolloupApi
);

export const removeFollowUp = createAsyncThunk(
  "followup/remove",
  removeFolloupApi
);

const chatSlice = createSlice({
  name: "chat",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(linkChats.pending, (state, action) => {
        state.linkChatsState.loading = true;
      })
      .addCase(linkChats.fulfilled, (state, action) => {
        state.linkChatsState.loading = false;
        state.linkChatsState.data = action.payload;
      })
      .addCase(linkChats.rejected, (state, action) => {
        state.linkChatsState.loading = false;
      })
      .addCase(unlinkChats.pending, (state, action) => {
        state.unlinkChatsState.loading = true;
      })
      .addCase(unlinkChats.fulfilled, (state, action) => {
        state.unlinkChatsState.loading = false;
        state.unlinkChatsState.data = action.payload;
      })
      .addCase(unlinkChats.rejected, (state, action) => {
        state.unlinkChatsState.loading = false;
      })
      .addCase(checkConn.pending, (state, action) => {
        state.postConnectionState.loading = true;
      })
      .addCase(checkConn.fulfilled, (state, action) => {
        state.postConnectionState.loading = false;
        state.postConnectionState.data = action.payload;
      })
      .addCase(checkConn.rejected, (state, action) => {
        state.postConnectionState.loading = false;
      })
      .addCase(getLinkedChats.pending, (state, action) => {
        state.getLinkedChatsState.loading = true;
      })
      .addCase(getLinkedChats.fulfilled, (state, action) => {
        state.getLinkedChatsState.loading = false;
        state.getLinkedChatsState.data = action.payload;
      })
      .addCase(getLinkedChats.rejected, (state, action) => {
        state.getLinkedChatsState.loading = false;
      })
      .addCase(addFavouriteChat.pending, (state, action) => {
        state.addFavouriteChatState.loading = true;
      })
      .addCase(addFavouriteChat.fulfilled, (state, action) => {
        state.addFavouriteChatState.loading = false;
        state.addFavouriteChatState.data = action.payload;
        let temp = [...state.tdChatListState.data];
        const index = temp.findIndex(
          (x) => x?.id === state?.openedChatState?.data?.id
        );
        if (index !== -1) {
          let lm = { ...temp[index]?.dbData };
          lm.isFavorite = !lm.isFavorite;
          temp[index] = { ...temp[index], dbData: { ...lm } };
        }
        state.openedChatState.data = { ...temp[index] };
        state.tdChatListState.data = [...temp];
      })
      .addCase(addFavouriteChat.rejected, (state, action) => {
        state.addFavouriteChatState.loading = false;
      })
      .addCase(addTag.pending, (state, action) => {
        state.addTagState.loading = true;
      })
      .addCase(addTag.fulfilled, (state, action) => {
        state.addTagState.loading = false;
        state.addTagState.data = action.payload;
      })
      .addCase(addTag.rejected, (state, action) => {
        state.addTagState.loading = false;
      })
      .addCase(editTag.pending, (state, action) => {
        state.editTagState.loading = true;
      })
      .addCase(editTag.fulfilled, (state, action) => {
        state.editTagState.loading = false;
        state.editTagState.data = action.payload;
      })
      .addCase(editTag.rejected, (state, action) => {
        state.editTagState.loading = false;
      })
      .addCase(removeTag.pending, (state, action) => {
        state.removeTagState.loading = true;
      })
      .addCase(removeTag.fulfilled, (state, action) => {
        state.removeTagState.loading = false;
        state.removeTagState.data = action.payload;
      })
      .addCase(removeTag.rejected, (state, action) => {
        state.removeTagState.loading = false;
      })
      .addCase(getUserTags.pending, (state, action) => {
        state.getUserTagsState.loading = true;
      })
      .addCase(getUserTags.fulfilled, (state, action) => {
        state.getUserTagsState.loading = false;
        state.getUserTagsState.data = action.payload;
      })
      .addCase(getUserTags.rejected, (state, action) => {
        state.getUserTagsState.loading = false;
      })
      .addCase(linkTags.pending, (state, action) => {
        state.linkTagsState.loading = true;
      })
      .addCase(linkTags.fulfilled, (state, action) => {
        state.linkTagsState.loading = false;
        state.linkTagsState.data = action.payload;
      })
      .addCase(linkTags.rejected, (state, action) => {
        state.linkTagsState.loading = false;
      })
      .addCase(unlinkTag.pending, (state, action) => {
        state.unlinkTagState.loading = true;
      })
      .addCase(unlinkTag.fulfilled, (state, action) => {
        state.unlinkTagState.loading = false;
        state.unlinkTagState.data = action.payload;
      })
      .addCase(unlinkTag.rejected, (state, action) => {
        state.unlinkTagState.loading = false;
      })
      .addCase(addNote.pending, (state, action) => {
        state.addNoteState.loading = true;
      })
      .addCase(addNote.fulfilled, (state, action) => {
        state.addNoteState.loading = false;
        state.addNoteState.data = action.payload;
      })
      .addCase(addNote.rejected, (state, action) => {
        state.addNoteState.loading = false;
      })
      .addCase(removeNote.pending, (state, action) => {
        state.removeNoteState.loading = true;
      })
      .addCase(removeNote.fulfilled, (state, action) => {
        state.removeNoteState.loading = false;
        state.removeNoteState.data = action.payload;
      })
      .addCase(removeNote.rejected, (state, action) => {
        state.removeNoteState.loading = false;
      })
      .addCase(addFolloup.pending, (state, action) => {
        state.addFolloupState.loading = true;
      })
      .addCase(addFolloup.fulfilled, (state, action) => {
        state.addFolloupState.loading = false;
        state.addFolloupState.data = action.payload;
      })
      .addCase(addFolloup.rejected, (state, action) => {
        state.addFolloupState.loading = false;
      })
      .addCase(completeFolloup.pending, (state, action) => {
        state.completeFolloupState.loading = true;
      })
      .addCase(completeFolloup.fulfilled, (state, action) => {
        state.completeFolloupState.loading = false;
        state.completeFolloupState.data = action.payload;
      })
      .addCase(completeFolloup.rejected, (state, action) => {
        state.completeFolloupState.loading = false;
      })
      .addCase(removeFollowUp.pending, (state, action) => {
        state.removeFollowUpState.loading = true;
      })
      .addCase(removeFollowUp.fulfilled, (state, action) => {
        state.removeFollowUpState.loading = false;
        state.removeFollowUpState.data = action.payload;
      })
      .addCase(removeFollowUp.rejected, (state, action) => {
        state.removeFollowUpState.loading = false;
      })
      .addCase(getDrafts.pending, (state, action) => {
        state.getDraftsState.loading = true;
      })
      .addCase(getDrafts.fulfilled, (state, action) => {
        state.getDraftsState.loading = false;
        state.getDraftsState.data = action.payload;
      })
      .addCase(getDrafts.rejected, (state, action) => {
        state.getDraftsState.loading = false;
      })
      .addCase(postLinkDrafts.pending, (state, action) => {
        state.postLinkDraftsState.loading = true;
      })
      .addCase(postLinkDrafts.fulfilled, (state, action) => {
        state.postLinkDraftsState.loading = false;
        state.postLinkDraftsState.data = action.payload;
      })
      .addCase(postLinkDrafts.rejected, (state, action) => {
        state.postLinkDraftsState.loading = false;
      })
      .addCase(addBulkFolloup.pending, (state, action) => {
        state.addBulkFollowupState.loading = true;
      })
      .addCase(addBulkFolloup.fulfilled, (state, action) => {
        state.addBulkFollowupState.loading = false;
        state.addBulkFollowupState.data = action.payload;
      })
      .addCase(addBulkFolloup.rejected, (state, action) => {
        state.addBulkFollowupState.loading = false;
      })
      .addCase(getApiKey.pending, (state, action) => {
        state.apiKeyState.loading = true;
      })
      .addCase(getApiKey.fulfilled, (state, action) => {
        state.apiKeyState.loading = false;
        state.apiKeyState.data = action.payload;
      })
      .addCase(getApiKey.rejected, (state, action) => {
        state.apiKeyState.loading = false;
      });
  },
  reducers: {
    setOpenChat: (state, action) => {
      state.openedChatState.data = action.payload;
    },
    setTdChatList: (state, action) => {
      state.tdChatListState.data = action.payload;
    },
    updateChatLastMessage: (state, action) => {
      let temp = [...state.tdChatListState.data];
      const index = temp.findIndex((x) => x?.id === action?.payload?.chat_id);
      if (index !== -1) {
        let lm = { ...temp[index] };
        lm.last_message = { ...action?.payload?.last_message };
        temp[index] = { ...lm };
      }
      state.tdChatListState.data = [...temp];
    },
    setChatMessages: (state, action) => {
      let temp = [...state.chatMessagesState?.data];
      (action?.payload || []).forEach((message: any) => {
        if (!temp.find((x) => x?.id === message?.id)) {
          temp.push(message);
        }
      });
      state.chatMessagesState.data = [...temp];
    },
    deleteChatMessages: (state, action) => {
      let temp = [...state.chatMessagesState?.data];
      state.chatMessagesState.data = temp.filter(
        (x: any) => !action.payload?.includes(x.id)
      );
    },
    updateNewMessage: (state, action) => {
      if (
        state.openedChatState?.data?.id === action?.payload?.message?.chat_id
      ) {
        state.chatMessagesState.data = [
          action?.payload?.message,
          ...state.chatMessagesState.data,
        ];
      }
    },
    resetChatMessages: (state, action) => {
      state.chatMessagesState.data = [];
    },
    updateChatListFilter: (state, action) => {
      state.chatListFilterState = {
        ...state.chatListFilterState,
        ...action.payload,
      };
    },
    updateAddBulkTagsModalState: (state, action) => {
      state.addBulkTagsModalState = {
        ...state.addBulkTagsModalState,
        ...action.payload,
      };
    },
    updateAddBulkFollowUpsModalState: (state, action) => {
      state.addBulkFollowUpsModalState = {
        ...state.addBulkFollowUpsModalState,
        ...action.payload,
      };
    },
    updateChatUnreadCount: (state, action) => {
      let temp = [...state.tdChatListState.data];
      const index = temp.findIndex((x) => x.id === action.payload?.chat_id);
      if (index !== -1) {
        temp[index].unread_count = action.payload?.unread_count;
      }
      state.tdChatListState.data = [...temp];
    },
    updateChatFolders: (state, action) => {
      localStorage.setItem(
        "chat-folders",
        JSON.stringify(action.payload.chat_filters)
      );
      state.chatFoldersState.data = action.payload.chat_filters;
    },
    updateForwardMessagesModalState: (state, action) => {
      state.forwardMessagesModalState = {
        ...action.payload,
      };
    },
    openImageModal: (state, action) => {
      state.showSelectedImage.isModalOpen = true;
      state.showSelectedImage.currentImage = action.payload;
    },
    closeImageModal: (state) => {
      state.showSelectedImage.isModalOpen = false;
      state.showSelectedImage.currentImage = null;
    },
    updateChatReadInboxOutboxData: (state, action) => {
      state.chatReadInboxOutboxData = {
        ...state.chatReadInboxOutboxData,
        ...action.payload,
      };
    },
    updateChatReadOutbox: (state, action) => {
      let temp = [...state.tdChatListState.data];
      const index = temp.findIndex((x) => x.id === action.payload?.chat_id);
      if (index !== -1) {
        temp[index].last_read_outbox_message_id =
          action.payload?.last_read_outbox_message_id;
        // state.openedChatState.data = { ...temp[index] };
        state.chatReadInboxOutboxData.lastReadOutboxMessageId =
          action.payload?.last_read_outbox_message_id;
      }
      state.tdChatListState.data = [...temp];
    },
    updateChatDraftMessage: (state, action) => {
      let temp = [...state.tdChatListState.data];
      const index = temp.findIndex((x) => x.id === action.payload?.chat_id);
      if (index !== -1) {
        temp[index].draft_message = action.payload?.draft_message;
      }
      if (
        state.openedChatState?.data &&
        state.openedChatState?.data?.id === action.payload?.chat_id
      ) {
        state.messageText =
          action.payload?.draft_message?.input_message_text?.text?.text;
      }
      state.tdChatListState.data = [...temp];
    },
    updateMessageText: (state, action) => {
      state.messageText = action.payload;
    },
    setSelectedChats: (state, action) => {
      state.selectedChats = [...action.payload];
    },
  },
});

export const {
  setOpenChat,
  setTdChatList,
  updateChatLastMessage,
  setChatMessages,
  deleteChatMessages,
  updateNewMessage,
  resetChatMessages,
  updateChatListFilter,
  updateAddBulkFollowUpsModalState,
  updateAddBulkTagsModalState,
  updateChatUnreadCount,
  updateChatFolders,
  updateForwardMessagesModalState,
  openImageModal,
  closeImageModal,
  updateChatReadInboxOutboxData,
  updateChatReadOutbox,
  updateChatDraftMessage,
  updateMessageText,
  setSelectedChats,
} = chatSlice.actions;
export default chatSlice.reducer;
