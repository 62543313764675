import classNames from "classnames";
import { TextareaHTMLAttributes, forwardRef, useEffect } from "react";

interface ITextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

export const Textarea = forwardRef<HTMLTextAreaElement, ITextareaProps>(
  function ({ className, value, onChange = () => {}, ...props }, ref) {
    const adjustHeight = (textarea: any) => {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 400)}px`;
    };

    const handleInput = (event: any) => {
      const textarea = event.target;
      adjustHeight(textarea);
    };

    useEffect(() => {
      // @ts-ignore
      if (ref && ref.current) {
        // @ts-ignore
        adjustHeight(ref.current);
      }
    }, [value, ref]);

    // @ts-ignore
    if (ref?.current) {
      // @ts-ignore
      ref.current.style.height = "auto";
      if (value) {
        // @ts-ignore
        ref.current.style.height = `${Math.min(
          // @ts-ignore
          ref.current.scrollHeight,
          400
        )}px`;
      } else {
        // @ts-ignore
        ref.current.style.height = "auto";
      }
    }

    return (
      <div className="relative w-full">
        <textarea
          ref={ref}
          className={classNames(
            "relative px-4 py-3 w-full outline-none text-xs leading-13 text-tgpro-grey-1 font-normal font-plus-jakarta bg-tgpro-black-2 rounded-lg h-auto",
            className
          )}
          rows={1}
          value={value}
          onChange={(event) => {
            onChange(event);
            handleInput(event);
          }}
          {...props}
          style={{
            resize: "none",
          }}
        />
      </div>
    );
  }
);
