import {
  ICreateStageReq,
  IDeleteStageReq,
  IGetUserTagsReq,
  ILinkStageReq,
  IUnLinkStageReq,
  IUpdateStageReq,
  ReduxOptions,
} from "../../types";
import { axiosInterceptors } from "../helpers";

export const getPipelineStagesApi = async (
  option: ReduxOptions<IGetUserTagsReq, {}>
) => {
  try {
    const response = await axiosInterceptors.get(
      `${process.env.REACT_APP_API_URL}/pipeline/stages`,
      {
        params: { telegram_id: option.data.telegramId },
      }
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const postCreateStageApi = async (
  option: ReduxOptions<ICreateStageReq, {}>
) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/pipeline/create_stage`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const deleteStageApi = async (
  option: ReduxOptions<IDeleteStageReq, {}>
) => {
  try {
    const response = await axiosInterceptors(
      `${process.env.REACT_APP_API_URL}/pipeline/delete_stages`,
      { method: "delete", data: { ...option.data } }
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const updateStagesApi = async (
  option: ReduxOptions<IUpdateStageReq, {}>
) => {
  try {
    const response = await axiosInterceptors.put(
      `${process.env.REACT_APP_API_URL}/pipeline/update_stages`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const postLinkStageApi = async (
  option: ReduxOptions<ILinkStageReq, {}>
) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/pipeline/link_stage`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};
export const postUnLinkStageApi = async (
  option: ReduxOptions<IUnLinkStageReq, {}>
) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/pipeline/unlink_stage`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};
