import { IconProps } from ".";

const DiamondIcon = (props: IconProps) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5138_11529)">
        <path
          d="M13.2634 2.5H8.29004L11.36 5.80333L13.2634 2.5Z"
          fill="#FCAF03"
        />
        <path
          d="M16.0001 5.93642L13.6235 2.66309L11.7368 5.93642H16.0001Z"
          fill="#FEDF9A"
        />
        <path
          d="M8.5332 14.4134L15.9399 6.33008H11.5332L8.5332 14.4134Z"
          fill="#FCAF03"
        />
        <path
          d="M0.0600586 6.33008L7.39673 14.3367L4.10339 6.33008H0.0600586Z"
          fill="#FCAF03"
        />
        <path d="M2.36 2.68652L0 5.93652H3.92L2.36 2.68652Z" fill="#FEDF9A" />
        <path
          d="M7.38321 2.5H2.70654L4.28654 5.79L7.38321 2.5Z"
          fill="#FCAF03"
        />
        <path
          d="M10.9465 5.93651L7.83652 2.58984L4.68652 5.93651H10.9465Z"
          fill="#FCAF03"
        />
        <path
          d="M4.52686 6.33008L7.99019 14.7467L11.1135 6.33008H4.52686Z"
          fill="#FEDF9A"
        />
      </g>
      <defs>
        <clipPath id="clip0_5138_11529">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default DiamondIcon;
