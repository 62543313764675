import { Provider } from "react-redux";
import "./App.css";
import store from "./redux/store";
import { TDLibContextProvider } from "./controllers/useTDLibController";
import InitialPageRender from "./pages";

function App() {
  return (
    <Provider store={store} stabilityCheck="never">
      <TDLibContextProvider>
        <InitialPageRender />
      </TDLibContextProvider>
    </Provider>
  );
}

export default App;
