import classNames from "classnames";
import { Header } from "../../components/Header";
import { FaChevronUp, FaListUl } from "react-icons/fa";
import { LuSettings } from "react-icons/lu";
import { useEffect, useRef, useState } from "react";
import Sorting from "./Sorting";
import { useDispatch } from "react-redux";
import {
  setShowBulkMessagePage,
  setShowChatSyncPage,
  setShowDraftSyncPage,
  setShowPaymentPage,
  setShowPipelinePage,
  setShowSettingsPage,
} from "../../redux/slices/authSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getUser } from "../../utils/helpers";
import useTDLibController from "../../controllers/useTDLibController";
import { getStages, unlinkStage } from "../../redux/slices/stageSlice";
import { MdStar } from "react-icons/md";
import { FollowUpTag } from "../../components/FollowUpTag";
import { FollowUp } from "../../types";
import { Checkbox } from "../../components/Checkbox";
import {
  setSelectedChats,
  updateAddBulkFollowUpsModalState,
  updateAddBulkTagsModalState,
} from "../../redux/slices/chatSlice";
import useOutsideClick from "../../hooks/useOutsideClick";
import { AddBulkTags } from "../../components/AddBulkTags";
import AddBulkFollowUps from "../../components/BulkFollowups/AddBulkFollwups";
import Tooltip from "../../components/Tooltip";

const tabsList = [
  {
    id: 1,
    title: "Notes",
  },
  {
    id: 2,
    title: "Pipeline",
  },
  {
    id: 3,
    title: "Info",
  },
];
const PipelinePage = () => {
  const [selectedTab, setSelectedTab] = useState(2);
  const [visibleTags, setVisibleTags] = useState(false);
  const dispatch = useDispatch();
  const { tdClient } = useTDLibController();

  const {
    getStagesState,
    selectedChats,
    addBulkTagsModalState,
    addBulkFollowUpsModalState,
  } = useSelector((state: RootState) => ({
    getStagesState: state.stage.getStagesState.data,
    selectedChats: state.chat.selectedChats,
    addBulkTagsModalState: state.chat.addBulkTagsModalState,
    addBulkFollowUpsModalState: state.chat.addBulkFollowUpsModalState,
  }));

  const callGetStages = async () => {
    let user = getUser();
    if (!user?.id) {
      user = await tdClient?.send({ "@type": "getMe" });
    }
    if (user?.id) {
      localStorage.setItem("user", JSON.stringify(user));
      dispatch(
        // @ts-ignore
        getStages({
          // @ts-ignore
          data: { telegramId: user.id },
          onSuccess: (res: any) => {},
          onError: (err: any) => {
            console.log(err);
          },
        })
      );
    }
  };

  useEffect(() => {
    callGetStages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const areAllStageChatsEmpty = getStagesState?.data.every(
    (stage: any) => stage.stageChats.length === 0
  );

  const [followUpMap, setFollowUpMap] = useState<{
    [chatId: number]: FollowUp | null;
  }>({});

  const stages: any[] = getStagesState?.data;

  useEffect(() => {
    let initialFollowUpMap: { [chatId: number]: FollowUp | null } = {};

    stages?.forEach((stage) => {
      stage?.stageChats.forEach((chat: any) => {
        let chatFollowUps = chat.followUps.filter((f: any) => !f.isCompleted);
        let expiredIncompleteFollowUps = chatFollowUps.filter(
          (f: any) => f.isExpired
        );

        if (expiredIncompleteFollowUps.length > 0) {
          expiredIncompleteFollowUps.sort(
            (a: any, b: any) =>
              new Date(a.followUpDate).getTime() -
              new Date(b.followUpDate).getTime()
          );
          initialFollowUpMap[chat.chatId] = expiredIncompleteFollowUps[0];
        } else if (chatFollowUps.length > 0) {
          initialFollowUpMap[chat.chatId] = chatFollowUps[0];
        } else {
          initialFollowUpMap[chat.chatId] = null;
        }
      });
    });

    setFollowUpMap(initialFollowUpMap);
  }, [stages]);

  const handleStageSelectAll = (stageId: string) => {
    const stage = getStagesState?.data?.find((s: any) => s.stageId === stageId);

    if (stage) {
      const chatIds = stage.stageChats.map((chat: any) => chat.chatId);
      const currentlySelectedChatIds = selectedChats.map(
        (chat: any) => chat.id
      );

      const shouldSelectAll = !chatIds.every((id: any) =>
        currentlySelectedChatIds.includes(id)
      );

      const newSelectedChats = shouldSelectAll
        ? [
            ...selectedChats,
            ...stage.stageChats
              .filter(
                (chat: any) => !currentlySelectedChatIds.includes(chat.chatId)
              )
              .map((chat: any) => ({ id: chat.chatId, ...chat })),
          ]
        : selectedChats.filter((chat: any) => !chatIds.includes(chat.id));

      dispatch(setSelectedChats(newSelectedChats));
    }
  };
  const [
    multipleSelectionChatActionModal,
    setMultipleSelectionChatActionModal,
  ] = useState(false);

  const multipleSelectionChatActionModalRef = useRef(null);

  useOutsideClick(multipleSelectionChatActionModalRef, () =>
    setMultipleSelectionChatActionModal(false)
  );

  const result = getStagesState?.data
    ?.flatMap((stages: any) =>
      stages?.stageChats
        ?.filter(
          (chat: any) => !!selectedChats?.find((x) => x.id === chat.chatId)
        )
        ?.map(() => stages.stageId)
    )
    ?.filter((stageId: any) => stageId !== false);

  const handleUnlinkStages = () => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      unlinkStage({
        data: {
          telegramId: user?.id,
          stageId: result,
          chatId: selectedChats?.map((chat) => chat.id) || [],
        },
        onSuccess: () => {
          dispatch(setSelectedChats([]));
          setSelectMode(false);
          callGetStages();
        },
      })
    );
  };
  const [selectMode, setSelectMode] = useState(false);

  return (
    <>
      <main className="h-[calc(100vh-49px)]">
        <Header />
        <div className="mt-10 mx-8">
          <span className="font-semibold text-xl text-tgpro-white-1">
            Pipeline
          </span>
          <div className="mt-4 flex items-center gap-4">
            <Tooltip text="Multi select">
              <div
                className={classNames(
                  "text-11 leading-13 font-semibold font-plus-jakarta text-tgpro-blue-1 cursor-pointer rounded bg-tgpro-blue-1 bg-opacity-[15%] p-2"
                )}
                onClick={() =>
                  setSelectMode((prev) => {
                    if (prev) {
                      dispatch(setSelectedChats([]));
                      return false;
                    } else return true;
                  })
                }
              >
                <FaListUl className="h-3 w-3" />
              </div>
            </Tooltip>
            <div
              className="text-tgpro-blue-1 flex items-center gap-2 px-2 py-1.5 bg-tgpro-blue-1 bg-opacity-[15%] rounded cursor-pointer"
              onClick={() => {
                dispatch(setShowSettingsPage(true));
                dispatch(setShowChatSyncPage(false));
                dispatch(setShowPaymentPage(false));
                dispatch(setShowDraftSyncPage(false));
                dispatch(setShowBulkMessagePage(false));
                dispatch(setShowPipelinePage(false));
              }}
            >
              <LuSettings className="w-3 h-3" />
              <span className="text-11 font-medium">Pipeline Settings</span>
            </div>
            {!areAllStageChatsEmpty && (
              <button
                className="text-tgpro-blue-1 text-11 font-medium underline underline-offset-2"
                onClick={() => setVisibleTags(!visibleTags)}
              >
                {`${visibleTags ? "Show" : "Hide"}  Tags`}
              </button>
            )}
          </div>
        </div>
        <div
          className={`grid grid-flow-col gap-4 justify-start text-tgpro-white-2 overflow-auto mt-5 mx-8  ${
            !areAllStageChatsEmpty && "h-[calc(100vh-245px)]"
          }`}
        >
          {getStagesState?.data?.map((stage: any) => (
            <div
              key={stage.stageId}
              className="flex flex-col gap-4 min-w-[300px]"
            >
              <div className="flex items-center justify-between sticky top-0 bg-tgpro-black-1">
                <span className="text-base font-bold">
                  {stage?.stageName} {`(${stage?.stageChats?.length})`}
                </span>
                {stage?.stageChats?.length > 0 && selectMode && (
                  <div
                    className="flex gap-2 items-center"
                    onClick={() => handleStageSelectAll(stage.stageId)}
                  >
                    <Checkbox
                      checked={stage.stageChats.every((chat: any) =>
                        selectedChats.find((x) => x.id === chat.chatId)
                      )}
                      className="!rounded"
                    />
                    <span className="text-xs cursor-default">Select All</span>
                  </div>
                )}
              </div>

              {stage.stageChats.length > 0 && (
                <div
                  className={`text-white  ${
                    selectMode && selectedChats?.length && "pb-16"
                  }`}
                >
                  {stage.stageChats.map((stageChat: any) => (
                    <div className="flex items-center gap-4">
                      {selectMode && (
                        <Checkbox
                          checked={
                            !!selectedChats.find(
                              (x) => x.id === stageChat.chatId
                            )
                          }
                          onClick={() => {
                            let temp = [...selectedChats];
                            const index = temp.findIndex(
                              (x) => x.id === stageChat.chatId
                            );
                            if (index !== -1) {
                              temp.splice(index, 1);
                            } else {
                              temp.push({
                                id: stageChat.chatId,
                                ...stageChat,
                              });
                            }
                            dispatch(setSelectedChats(temp));
                          }}
                          className="rounded"
                        />
                      )}

                      <div
                        key={stageChat.chatId}
                        className="flex flex-1 flex-col gap-2 bg-tgpro-black-2 mb-4 rounded-lg p-4"
                      >
                        {stageChat?.isFavorite && (
                          <MdStar className="text-[#FCAF03] cursor-pointer" />
                        )}

                        {!visibleTags && (
                          <div className="flex items-center gap-2 flex-wrap">
                            {(stageChat?.chatTags || []).map((tag: any) => (
                              <div
                                key={tag.tag_id}
                                className="flex items-center justify-center px-2 py-1 text-11 leading-13 font-normal font-plus-jakarta text-tgpro-blue-1 bg-tgpro-blue-1/15 w-max rounded-full"
                              >
                                {tag.tag_name}
                              </div>
                            ))}
                          </div>
                        )}

                        {followUpMap && followUpMap[stageChat.chatId] && (
                          <FollowUpTag
                            chatId={stageChat.chatId}
                            isFromStagesPage={true}
                            followUp={
                              followUpMap[stageChat.chatId] || {
                                followUpId: "",
                                followUpDate: "",
                                isCompleted: false,
                              }
                            }
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        {areAllStageChatsEmpty ? (
          <div className="flex gap-16 justify-center items-start mt-20 p-16 border border-[#2387D4] rounded-lg w-max mx-auto">
            <div className="flex flex-col gap-4 max-w-[300px]">
              <span className="text-white font-semibold text-lg">
                Get Started with Pipeline!
              </span>
              <ol className="list-decimal text-base text-tgpro-white-3 list-inside">
                <li>Open a contact from "main view" and go to "pipeline"</li>
                <li>Select a pipeline stage</li>
                <li>That's it!</li>
              </ol>
              <span className="text-base font-semibold text-tgpro-white-3">
                P-s: you can edit the pipeline stage in the{" "}
                <span
                  className="text-tgpro-blue-1 cursor-pointer"
                  onClick={() => {
                    dispatch(setShowPipelinePage(false));
                    dispatch(setShowDraftSyncPage(false));
                    dispatch(setShowChatSyncPage(false));
                    dispatch(setShowPaymentPage(false));
                    dispatch(setShowSettingsPage(true));
                    dispatch(setShowBulkMessagePage(false));
                  }}
                >
                  Pipeline Settings.
                </span>
              </span>
            </div>
            <div className="border-tgpro-black-2 border p-4 rounded-lg flex flex-col">
              <div className="text-11 font-medium text-center text-[#9AA4B2]">
                <ul className="grid grid-cols-3">
                  {tabsList.map((data) => (
                    <li
                      className={`w-[90px] ${
                        selectedTab === data.id
                          ? "border-b border-tgpro-blue-1 text-tgpro-blue-1 border-opacity-50"
                          : "border-b border-[#51627B]"
                      }`}
                      onClick={() => setSelectedTab(2)}
                      key={data.id}
                    >
                      <span className={`inline-block cursor-pointer mb-[5px] `}>
                        {data.title}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              {selectedTab === 2 && (
                <>
                  <div className="mt-4 mb-3.5 flex items-center gap-4">
                    <button
                      onClick={() => {
                        dispatch(setShowPipelinePage(false));
                        dispatch(setShowDraftSyncPage(false));
                        dispatch(setShowChatSyncPage(false));
                        dispatch(setShowPaymentPage(false));
                        dispatch(setShowSettingsPage(true));
                        dispatch(setShowBulkMessagePage(false));
                      }}
                      className="text-tgpro-blue-1 flex items-center gap-2 px-2 py-1.5 bg-tgpro-blue-1 bg-opacity-[15%] rounded"
                    >
                      <LuSettings className="w-3 h-3" />
                      <span className="text-11 font-medium">
                        Pipeline Settings
                      </span>
                    </button>
                  </div>
                  <span className="text-tgpro-white-3 mt-2 text-11">
                    Status
                  </span>
                  <div className="mt-1.5">
                    <Sorting />
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </main>
      {addBulkTagsModalState.visible && <AddBulkTags />}
      {addBulkFollowUpsModalState.visible && <AddBulkFollowUps />}
      {selectMode && selectedChats?.length && (
        <div
          className="absolute bottom-[72px] border-t border-tgpro-black-2 w-full bg-tgpro-black-1"
          onClick={() => setMultipleSelectionChatActionModal((prev) => !prev)}
        >
          <div className="w-full px-6 py-[18px] text-sm leading-6 font-semibold font-plus-jakarta text-white flex items-center justify-between relative">
            Select Action ({selectedChats.length} chats selected)
            <div className="relative">
              <FaChevronUp />
              {multipleSelectionChatActionModal && (
                <div
                  className="p-3 bg-tgpro-black-1 border border-tgpro-black-2 rounded absolute bottom-[calc(100%+12px)] right-0 w-[180px] space-y-4 z-20"
                  ref={multipleSelectionChatActionModalRef}
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(setShowDraftSyncPage(false));
                      dispatch(setShowChatSyncPage(false));
                      dispatch(setShowPaymentPage(false));
                      dispatch(setShowSettingsPage(false));
                      dispatch(setShowBulkMessagePage(true));
                    }}
                  >
                    Bulk Message
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(
                        updateAddBulkFollowUpsModalState({
                          visible: true,
                          chats: selectedChats,
                          onAddFollowUpSuccess: () => {
                            dispatch(setSelectedChats([]));
                            setSelectMode(false);
                            callGetStages();
                          },
                        })
                      );
                    }}
                  >
                    Bulk followup
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(
                        updateAddBulkTagsModalState({
                          visible: true,
                          chats: selectedChats,
                          onAddTagSuccess: () => {
                            dispatch(setSelectedChats([]));
                            setSelectMode(false);
                            callGetStages();
                          },
                        })
                      );
                    }}
                  >
                    Add Tags
                  </div>
                  <button onClick={handleUnlinkStages} disabled>
                    Unlink Stage
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default PipelinePage;
