import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { MdClose, MdMoreHoriz } from "react-icons/md";
import { PiSquaresFour } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import useOutsideClick from "../../hooks/useOutsideClick";
import { getUser } from "../../utils/helpers";
import { Checkbox } from "../../components/Checkbox";
import {
  addTag,
  editTag,
  getLinkedChats,
  getUserTags,
  linkTags,
  removeTag,
  setOpenChat,
  setTdChatList,
  unlinkTag,
} from "../../redux/slices/chatSlice";
import { HiOutlineTrash } from "react-icons/hi";
import { FaCheck } from "react-icons/fa";

export const Tags = () => {
  const dispatch = useDispatch();

  const { getUserTagsState, openedChatState, tdChatListState } = useSelector(
    (state: RootState) => ({
      getUserTagsState: state.chat.getUserTagsState.data,
      openedChatState: state.chat.openedChatState.data,
      tdChatListState: state.chat.tdChatListState.data,
    })
  );

  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const [showUserTagsPopover, setShowUserTagsPopover] = useState(false);
  const [tagText, setTagText] = useState("");
  const [editTagId, setEditTagId] = useState("");

  const tagsModalRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  useOutsideClick(tagsModalRef, () => setShowUserTagsPopover(false));

  const getCurrentUserTags = () => {
    const user = getUser();
    // @ts-ignore
    dispatch(getUserTags({ data: { telegramId: user.id } }));
    // @ts-ignore
    dispatch(getLinkedChats({ data: { telegramId: user.id } }));
  };

  useEffect(() => {
    getCurrentUserTags();
  }, []);

  const onLinkTags = (tagId?: string, tagName?: string) => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      linkTags({
        data: {
          telegramId: user.id,
          chatId: [openedChatState.id],
          tagIds: tagId ? [tagId] : selectedTagIds,
        },
        onSuccess: (res) => {
          let temp = [...tdChatListState];
          const index = temp.findIndex((x) => x.id === openedChatState.id);
          if (index !== -1) {
            let dbData = { ...temp[index].dbData };
            const chatTags =
              tagId && tagName
                ? [{ tag_id: tagId, tag_name: tagName }]
                : getUserTagsState?.data
                    ?.filter((x: any) => selectedTagIds.includes(x.tagId))
                    .map((x: any) => ({
                      tag_id: x.tagId,
                      tag_name: x.tagName,
                    }));
            let dbChatTags = [...dbData.chatTags];
            dbChatTags = [...dbChatTags];
            (chatTags || []).forEach((x: any) => {
              if (!dbChatTags.find((tag) => x.tag_id === tag.tag_id)) {
                dbChatTags.push(x);
              }
            });
            dbData = { ...dbData, chatTags: dbChatTags };
            temp[index] = { ...temp[index], dbData };
            dispatch(setTdChatList(temp));
            dispatch(setOpenChat({ ...openedChatState, dbData }));
            setSelectedTagIds([]);
            getCurrentUserTags();
          }
        },
      })
    );
  };

  const onAddTag = (value: string) => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      addTag({
        data: {
          telegramId: user.id,
          tagName: value,
        },
        onSuccess: (res) => {
          inputRef.current!.value = "";
          inputRef.current?.blur();
          setTagText("");
          getCurrentUserTags();
          if (res.data?.tagId) onLinkTags(res.data?.tagId, value);
        },
      })
    );
  };

  const onEditTag = (tagId: string, value: string) => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      editTag({
        data: {
          telegramId: user.id,
          tagId,
          tagName: value,
        },
        onSuccess: (res: any) => {
          setShowUserTagsPopover(false);
          getCurrentUserTags();
          const temp = JSON.parse(JSON.stringify(tdChatListState));
          const index = temp.findIndex((x: any) => x.id === openedChatState.id);
          const chatTags = [...temp[index].dbData.chatTags];
          const chatTagIndex = chatTags.findIndex(
            (tag: any) => tag.tag_id === tagId
          );
          chatTags[chatTagIndex].tag_name = value;
          temp[index] = {
            ...temp[index],
            dbData: { ...temp[index].dbData, chatTags },
          };
          setEditTagId("");
          dispatch(setTdChatList(temp));
          dispatch(
            setOpenChat({
              ...openedChatState,
              dbData: { ...temp[index].dbData, chatTags },
            })
          );
        },
      })
    );
  };

  const onRemoveTag = (tagId: string) => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      removeTag({
        data: {
          telegramId: user.id,
          tagId,
        },
        onSuccess: (res) => {
          let temp = [...tdChatListState];
          let updatedTDChatList = [];
          for (let chat of temp) {
            let dbData = { ...chat.dbData };
            let dbChatTags = [...dbData.chatTags];
            dbChatTags = [...dbChatTags];
            const dbTagIndex = dbChatTags.findIndex(
              (x: any) => x.tag_id === tagId
            );
            if (dbTagIndex !== -1) {
              dbChatTags.splice(dbTagIndex, 1);
            }
            dbData = { ...dbData, chatTags: dbChatTags };
            updatedTDChatList.push({ ...chat, dbData });
          }
          dispatch(setTdChatList(updatedTDChatList));
          const index = updatedTDChatList.findIndex(
            (x) => x.id === openedChatState.id
          );
          dispatch(
            setOpenChat({
              ...openedChatState,
              dbData: { ...updatedTDChatList[index].dbData },
            })
          );
          getCurrentUserTags();
          setSelectedTagIds([]);
          //   }
        },
      })
    );
  };

  const onUnLinkTag = (tagId: string) => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      unlinkTag({
        data: {
          telegramId: user.id,
          chatId: openedChatState.id,
          tagIds: [tagId],
        },
        onSuccess: (res) => {
          let temp = [...tdChatListState];
          const index = temp.findIndex((x) => x.id === openedChatState.id);
          if (index !== -1) {
            let dbData = { ...temp[index].dbData };
            let dbChatTags = [...dbData.chatTags];
            dbChatTags = [...dbChatTags];
            const dbTagIndex = dbChatTags.findIndex(
              (x: any) => x.tag_id === tagId
            );
            if (dbTagIndex !== -1) {
              dbChatTags.splice(dbTagIndex, 1);
            }
            dbData = { ...dbData, chatTags: dbChatTags };
            temp[index] = { ...temp[index], dbData };
            dispatch(setTdChatList(temp));
            dispatch(setOpenChat({ ...openedChatState, dbData }));
            setSelectedTagIds([]);
          }
        },
      })
    );
  };

  useEffect(() => {
    setSelectedTagIds(
      openedChatState?.dbData?.chatTags?.map((tag: any) => tag.tag_id)
    );
  }, [openedChatState]);

  return (
    <div className="w-full relative flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <PiSquaresFour className="text-tgpro-blue-1" />
        <div className="text-xs leading-15 font-normal font-plus-jakarta text-tgpro-grey-1">
          Tags
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1">
          Create tag and enter to add
        </div>
        <div className="bg-tgpro-black-2 rounded-lg gap-2 px-4 py-2">
          <div
            className="flex items-center flex-wrap gap-2"
            onClick={() => inputRef.current?.focus()}
          >
            {(openedChatState?.dbData?.chatTags || []).map((tag: any) => (
              <div
                key={tag.tag_id}
                className="flex items-center justify-center gap-1 px-2 py-1 text-11 leading-13 font-normal font-plus-jakarta text-tgpro-blue-1 bg-tgpro-blue-1/15 w-max rounded-full"
              >
                {tag.tag_name}
                <div
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onUnLinkTag(tag.tag_id);
                  }}
                >
                  <MdClose />
                </div>
              </div>
            ))}
          </div>
          <div
            className="relative"
            onFocus={() => setShowUserTagsPopover(true)}
          >
            <input
              ref={inputRef}
              className="bg-transparent outline-none border-none text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-5 w-full"
              value={tagText}
              onChange={(e) => setTagText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onAddTag((e.target as any).value);
                }
              }}
              onFocus={() => setShowUserTagsPopover(true)}
            />
            {/* @ts-ignore */}
            {getUserTagsState?.data?.length && showUserTagsPopover ? (
              <div
                className="p-2 absolute top-full left-0 z-50 bg-tgpro-black-1 w-full"
                ref={tagsModalRef}
              >
                <div className="max-h-[300px] overflow-auto w-full">
                  <>
                    {/* @ts-ignore */}
                    {([...getUserTagsState?.data] || [])
                      .filter((tag) =>
                        tag.tagName
                          .toLowerCase()
                          .includes(tagText.toLowerCase())
                      )
                      .map((tag: any, i: number) => (
                        <div key={tag.tagId}>
                          <div
                            className={classNames(
                              "flex items-center justify-between px-4 py-2",
                              selectedTagIds.includes(tag.tagId)
                                ? "bg-tgpro-blue-1/15 text-tgpro-blue-1"
                                : "text-tgpro-grey-1 bg-tgpro-black-2"
                            )}
                          >
                            {editTagId === tag.tagId ? (
                              <input
                                className="h-full bg-transparent outline-none border-none text-11 leading-13 font-normal font-plus-jakarta"
                                defaultValue={tag.tagName}
                                id={`${tag.tagName}-${tag.tagId}`}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    onEditTag(
                                      tag.tagId,
                                      (e.target as any).value
                                    );
                                  }
                                }}
                              />
                            ) : (
                              <div
                                className={classNames(
                                  "flex items-center gap-2 relative w-full outline-none text-11 leading-13 font-normal font-plus-jakarta rounded-lg"
                                )}
                                onClick={() => {
                                  if (selectedTagIds.includes(tag.tagId)) {
                                    onUnLinkTag(tag.tagId);
                                  } else onLinkTags(tag.tagId, tag.tagName);
                                }}
                              >
                                <Checkbox
                                  checked={selectedTagIds.includes(tag.tagId)}
                                />
                                {tag?.tagName}
                              </div>
                            )}
                            {editTagId === tag.tagId ? (
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  const value = (
                                    document.getElementById(
                                      `${tag.tagName}-${tag.tagId}`
                                    )! as HTMLInputElement
                                  )?.value;
                                  onEditTag(tag.tagId, value);
                                }}
                              >
                                <FaCheck />
                              </div>
                            ) : (
                              <div
                                className="cursor-pointer"
                                onClick={() =>
                                  setEditTagId((prev) =>
                                    prev === tag.tagId ? "" : tag.tagId
                                  )
                                }
                              >
                                <MdMoreHoriz />
                              </div>
                            )}
                          </div>
                          {editTagId === tag.tagId && (
                            <div
                              className="flex items-center justify-center gap-1 px-4 py-2 text-11 leading-13 font-normal font-plus-jakarta text-tgpro-red-1 bg-tgpro-red-1/15 w-max rounded-lg mt-4 cursor-pointer"
                              onClick={() => onRemoveTag(tag.tagId)}
                            >
                              <HiOutlineTrash className="h-4 w-4" />
                              <div>Delete</div>
                            </div>
                          )}
                          {i !==
                            ((getUserTagsState as []) || [])?.length - 1 && (
                            <div className="h-[0.5px] my-2 bg-tgpro-black-3"></div>
                          )}
                        </div>
                      ))}
                  </>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
