import {
  IAddTagReq,
  IEditTagReq,
  IGetUserTagsReq,
  ILinkTagReq,
  ReduxOptions,
} from "../../types";
import { axiosInterceptors } from "../helpers";

export const addTagApi = async (
  option: ReduxOptions<IAddTagReq, { tagId: string }>
) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/add_tags`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const editTagApi = async (
  option: ReduxOptions<IEditTagReq, { tagId: string }>
) => {
  try {
    const response = await axiosInterceptors.put(
      `${process.env.REACT_APP_API_URL}/edit_tag`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const removeTagApi = async (
  option: ReduxOptions<Omit<IEditTagReq, "tagName">, { tagId: string }>
) => {
  try {
    const response = await axiosInterceptors(
      `${process.env.REACT_APP_API_URL}/delete_tag`,
      { method: "delete", data: { ...option.data } }
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const getUserTagsApi = async (
  option: ReduxOptions<IGetUserTagsReq, any[]>
) => {
  try {
    const response = await axiosInterceptors.get(
      `${process.env.REACT_APP_API_URL}/tags`,
      {
        params: { telegram_id: option.data.telegramId },
      }
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const linkTagsApi = async (option: ReduxOptions<ILinkTagReq, {}>) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/link_tag`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const unlinkTagApi = async (option: ReduxOptions<ILinkTagReq, {}>) => {
  try {
    const response = await axiosInterceptors(
      `${process.env.REACT_APP_API_URL}/unlink_tag`,
      { method: "delete", data: { ...option.data } }
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};
