import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { MainPage } from "./MainPage";
import { PasswordVerificationPage } from "./PasswordVerificationPage";
import { QRScannerPage } from "./QRScannerPage";
import { SyncChatPage } from "./SyncChatPage";
import { SyncDraftPage } from "./SyncDraftPage";
import { PaymentPage } from "./PaymentPage";
import SettingsPage from "./Settings";
import BulkMessagePage from "./BulkMessagePage";
import { useEffect } from "react";
import {
  checkPaymentInfo,
  setShowChatSyncPage,
  setShowPaymentPage,
} from "../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import useTDLibController from "../controllers/useTDLibController";
import PipelinePage from "./PipelinePage";

export default function InitialPageRender() {
  const dispatch = useDispatch();
  const { tdClient, isReady } = useTDLibController();

  const {
    authState,
    showChatSyncPage,
    showDraftSyncPage,
    showPaymentPage,
    showSettingsPage,
    checkPaymentInfoState,
    showBulkMessagePage,
    showPipelinePage,
  } = useSelector((state: RootState) => ({
    authState: state.auth.authState,
    showChatSyncPage: state.auth.showChatSyncPage,
    showPhoneNumberLoginPage: state.auth.showPhoneNumberLoginPage,
    showDraftSyncPage: state.auth.showDraftSyncPage,
    showPaymentPage: state.auth.showPaymentPage,
    showSettingsPage: state.auth.showSettingsPage,
    checkPaymentInfoState: state.auth.checkPaymentInfoState.data?.data,
    showBulkMessagePage: state.auth.showBulkMessagePage,
    showPipelinePage: state.auth.showPipelinePage,
  }));

  useEffect(() => {
    if (authState?.["@type"] === "authorizationStateReady" && isReady) {
      tdClient?.send({ "@type": "getMe" }).then((user: any) => {
        dispatch(
          // @ts-ignore
          checkPaymentInfo({
            data: { telegramId: user.id, telegram_handle: user.username },
          })
        );
      });
    }
  }, [authState, tdClient]);

  useEffect(() => {
    // const params = new URLSearchParams(window.location.search);
    if (
      !!checkPaymentInfoState &&
      !checkPaymentInfoState?.isSubscribed &&
      !checkPaymentInfoState?.onTrial
      // || params.get("show_payment_page") === "true"
    ) {
      dispatch(setShowPaymentPage(true));
    }
  }, [checkPaymentInfoState]);

  const renderPage = () => {
    switch (authState?.["@type"]) {
      case "authorizationStateWaitPhoneNumber":
      case "authorizationStateWaitOtherDeviceConfirmation":
      case "authorizationStateClosed":
        return <QRScannerPage />;
      case "authorizationStateWaitPassword":
        return <PasswordVerificationPage />;
      case "authorizationStateReady":
        if (showPaymentPage) return <PaymentPage />;
        else if (showChatSyncPage) return <SyncChatPage />;
        else if (showDraftSyncPage) return <SyncDraftPage />;
        else if (showSettingsPage) return <SettingsPage />;
        else if (showBulkMessagePage) return <BulkMessagePage />;
        else if (showPipelinePage) return <PipelinePage />;
        else return <MainPage />;
      default:
        return null;
    }
  };

  return <>{renderPage()}</>;
}
