import classNames from "classnames";
import moment from "moment";
import { FaCheckCircle } from "react-icons/fa";
import { FiCalendar } from "react-icons/fi";
import { getDaysDifference, getUser } from "../utils/helpers";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  completeFolloup,
  setOpenChat,
  setTdChatList,
} from "../redux/slices/chatSlice";
import { getStages } from "../redux/slices/stageSlice";

export const FollowUpTag = ({
  followUp,
  chatId,
  isFromStagesPage,
}: {
  followUp: {
    followUpId: string;
    followUpDate: string;
    isCompleted: boolean;
  };
  isFromStagesPage?: boolean;
  chatId?: number;
}) => {
  const dispatch = useDispatch();

  const { openedChatState, tdChatListState } = useSelector(
    (state: RootState) => ({
      openedChatState: state.chat.openedChatState.data,
      tdChatListState: state.chat.tdChatListState.data,
    })
  );

  const onCompleteFolloUp = () => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      completeFolloup({
        data: {
          telegramId: user.id,
          chatId: chatId || openedChatState?.id,
          followupId: followUp.followUpId,
        },
        onSuccess: () => {
          const temp = JSON.parse(JSON.stringify(tdChatListState));
          const index = temp.findIndex(
            (x: any) => x.id === (chatId || openedChatState.id)
          );
          const followUps = [...temp[index].dbData.followUps];
          const chatFollowUpIndex = followUps.findIndex(
            (fU) => fU.followUpId === followUp.followUpId
          );
          if (chatFollowUpIndex !== -1)
            followUps[chatFollowUpIndex].isCompleted =
              !followUps[chatFollowUpIndex].isCompleted;
          dispatch(setTdChatList(temp));
          dispatch(
            setOpenChat({
              ...openedChatState,
              dbData: {
                ...temp[index].dbData,
                followUps,
              },
            })
          );
          if (isFromStagesPage) {
            dispatch(
              // @ts-ignore
              getStages({
                // @ts-ignore
                data: { telegramId: user.id },
                onSuccess: (res: any) => {},
                onError: (err: any) => {
                  console.log(err);
                },
              })
            );
          }
        },
      })
    );
  };

  const days = getDaysDifference(followUp.followUpDate);
  const isToday = days === 0;

  return (
    <div
      className={classNames(
        "flex cursor-pointer items-center gap-4 justify-between px-2 py-1 text-11 leading-13 font-normal font-plus-jakarta w-max rounded-full",
        days > 0
          ? "text-tgpro-blue-1 bg-tgpro-blue-1/15"
          : "text-tgpro-red-1 bg-tgpro-red-1/15"
      )}
      onClick={onCompleteFolloUp}
    >
      <div className="flex items-center gap-2 relative">
        <span>
          <FiCalendar />
        </span>
        {days >= 0 ? (
          <span>
            Follow up{" "}
            {isToday ? "today" : `in ${days} day${days > 1 ? "s" : ""}`}
          </span>
        ) : (
          <span>
            {followUp?.isCompleted
              ? `Followed up on ${moment(followUp.followUpDate).format(
                  "DD/MM/YYYY"
                )}`
              : `Follow up past ${-days} days`}
          </span>
        )}
        {followUp.isCompleted && (
          <div
            className={classNames(
              "absolute top-1/2 -translate-y-1/2 left-0 h-px w-full",
              days > 0 ? "bg-tgpro-blue-1" : "bg-tgpro-red-1"
            )}
          ></div>
        )}
      </div>
      {followUp.isCompleted ? (
        <FaCheckCircle />
      ) : (
        <div
          className={classNames(
            "h-2.5 w-2.5 border rounded-full",
            days > 0 ? "border-tgpro-blue-1" : "border-tgpro-red-1"
          )}
        ></div>
      )}
    </div>
  );
};
