import { FaPlus } from "react-icons/fa";
import { Checkbox } from "../../components/Checkbox";
import { Header } from "../../components/Header";
import { getUser } from "../../utils/helpers";
import useTDLibController from "../../controllers/useTDLibController";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDrafts, postLinkDrafts } from "../../redux/slices/chatSlice";
import { Draft } from "../../types";
import ShowFollowUpTag from "./ShowFollowUpTag";
import classNames from "classnames";
import { DraftIcon } from "../../icons";
import {
  setShowChatSyncPage,
  setShowDraftSyncPage,
} from "../../redux/slices/authSlice";
import { PiSpinnerGapBold } from "react-icons/pi";
import { HiUserGroup } from "react-icons/hi";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { MdStar } from "react-icons/md";

export const SyncDraftPage = () => {
  const { tdClient } = useTDLibController();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [allDrafts, setAllDrafts] = useState<Draft[]>([]);
  const [selectedChatIds, setSelectedChatIds] = useState<number>(0);
  const [linkDraftsLoading, setLinkDraftsLoading] = useState<boolean>(false);
  const [selectedDraftsIds, setSelectedDraftsIds] = useState<string[]>([]);

  const { tdChatListState } = useSelector((state: RootState) => ({
    tdChatListState: state.chat.tdChatListState.data,
  }));
  const getLinkedDraftsData = async () => {
    let user = getUser();
    if (!user?.id) {
      user = await tdClient?.send({ "@type": "getMe" });
    }

    if (user?.id) {
      localStorage.setItem("user", JSON.stringify(user));
      setLoading(true);
      dispatch(
        // @ts-ignore
        getDrafts({
          // @ts-ignore
          data: { telegramId: user.id },
          onSuccess: (res: any) => {
            // @ts-ignore
            setAllDrafts(res?.data);
            setLoading(false);
          },
          onError: () => {
            setLoading(false);
          },
        })
      );
    }
  };

  useEffect(() => {
    getLinkedDraftsData();
  }, []);

  const onLinkDrafts = () => {
    setLinkDraftsLoading(true);
    const user = getUser();
    dispatch(
      // @ts-ignore
      postLinkDrafts({
        data: {
          telegramId: user.id,
          chatId: selectedChatIds,
          draftIds: selectedDraftsIds,
        },
        onSuccess: (res: any) => {
          dispatch(setShowChatSyncPage(false));
          dispatch(setShowDraftSyncPage(false));
          setLinkDraftsLoading(false);
        },
        onError: (err: any) => {
          console.log(err);
          setLinkDraftsLoading(false);
        },
      })
    );
  };

  return (
    <>
      <main className="min-h-screen">
        <Header />
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col items-center justify-center mt-24 gap-5 w-[800px] relative">
            <h1 className="text-xl leading-[25.2px] font-normal font-plus-jakarta text-tgpro-white-1">
              Sync Drafts
            </h1>
            <button
              className={classNames(
                "text-11 text-white py-3 px-8 rounded-lg",
                selectedDraftsIds?.length > 0 &&
                  selectedChatIds !== 0 &&
                  !linkDraftsLoading
                  ? "bg-tgpro-blue-1"
                  : "bg-[#626F84]"
              )}
              onClick={() => {
                selectedDraftsIds?.length > 0 &&
                  selectedChatIds !== 0 &&
                  !linkDraftsLoading &&
                  onLinkDrafts();
              }}
              disabled={
                selectedDraftsIds?.length <= 0 ||
                selectedChatIds === 0 ||
                linkDraftsLoading
              }
            >
              Sync Now
            </button>
            {loading ? (
              <div className="flex  items-center gap-1 text-11 leading-16 text-tgpro-grey-2 font-normal font-plus-jakarta">
                <PiSpinnerGapBold className="text-tgpro-grey-2 animate-spin" />
                Please wait, we are syncing your drafts notes.
              </div>
            ) : (
              <>
                <div className="w-full relative mt-2">
                  <div className="grid grid-cols-2 gap-8">
                    <span className="text-tgpro-grey-1 text-xs">
                      List of drafts
                    </span>
                    <span className="text-tgpro-grey-1 text-xs">
                      List of contacts
                    </span>
                  </div>
                  <div className="w-full mt-4">
                    <div className="grid grid-cols-2 gap-8">
                      <div className="max-h-[487px] overflow-auto flex flex-col gap-2">
                        {allDrafts?.length >= 1 ? (
                          allDrafts
                            ?.slice()
                            ?.reverse()
                            ?.map((data) => (
                              <div
                                className="cursor-pointer flex items-center gap-4 border border-tgpro-black-2 py-2 px-4 rounded-lg mr-2"
                                key={data?.draftId}
                                onClick={() => {
                                  let temp = [...selectedDraftsIds];
                                  const index = temp.findIndex(
                                    (id) => id === data.draftId
                                  );
                                  if (index !== -1) {
                                    temp.splice(index, 1);
                                  } else {
                                    temp.push(data.draftId);
                                  }
                                  setSelectedDraftsIds(temp);
                                }}
                              >
                                <div>
                                  <Checkbox
                                    checked={selectedDraftsIds.includes(
                                      data.draftId
                                    )}
                                    className="!rounded-full shrink-0"
                                  />
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                  <span className="text-white text-xs">
                                    {data?.contact}
                                  </span>
                                  <span className="text-11 text-tgpro-grey-3">
                                    {data?.noteMessage}
                                  </span>
                                  <div className="flex items-start justify-between mt-2">
                                    <div className="flex items-center gap-2">
                                      {(data?.tags || [])
                                        .slice(0, 2)
                                        .map((tag: any) => (
                                          <div
                                            key={tag.tag_id}
                                            className="flex items-center justify-center px-2 py-1 text-11 leading-13 font-normal font-plus-jakarta text-tgpro-blue-1 bg-tgpro-blue-1/15 w-max rounded-full"
                                          >
                                            {tag}
                                          </div>
                                        ))}
                                      {data?.tags?.length - 2 > 0 && (
                                        <div className="flex items-center gap-px text-11 leading-13 font-normal font-plus-jakarta text-tgpro-blue-1">
                                          <FaPlus className="h-2 w-2" />{" "}
                                          {data?.tags?.length - 2}
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      <ShowFollowUpTag draftData={data} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <div className="text-tgpro-blue-1 w-full h-full flex flex-col gap-3 justify-center items-center">
                            <DraftIcon />
                            <span className="text-white text-sm">
                              No draft notes found
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="max-h-[487px] overflow-auto flex flex-col gap-4">
                        {(tdChatListState || []).map((chat: any, i) => (
                          <div key={chat.id} className="mr-2">
                            <div
                              className={classNames(
                                "flex items-center gap-2 relative p-4 w-full outline-none text-xs leading-13 font-normal font-plus-jakarta rounded-lg text-white border border-tgpro-black-2 cursor-pointer"
                              )}
                              onClick={() => {
                                setSelectedChatIds(chat.id);
                              }}
                            >
                              <Checkbox
                                checked={selectedChatIds === chat.id}
                                className="!rounded-full"
                              />
                              {chat?.dbData?.isFavorite && (
                                <MdStar className="text-[#FCAF03] cursor-pointer" />
                              )}
                              <HiUserGroup className="text-tgpro-grey-1" />
                              {chat?.title}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
};
