import { IAddNoteReq, IRemoveNoteReq, ReduxOptions } from "../../types";
import { axiosInterceptors } from "../helpers";

export const addNoteApi = async (
  option: ReduxOptions<IAddNoteReq, { noteId: string }>
) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/add_note`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const removeNoteApi = async (
  option: ReduxOptions<Omit<IRemoveNoteReq, "tagName">, { tagId: string }>
) => {
  try {
    const response = await axiosInterceptors(
      `${process.env.REACT_APP_API_URL}/delete_note`,
      { method: "delete", data: { ...option.data } }
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};
