import classNames from "classnames";
import { InputHTMLAttributes, ReactNode } from "react";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  postFix?: string | number | ReactNode;
  hintText?: string;
}

export const Input = ({
  postFix,
  className,
  hintText,
  ...props
}: IInputProps) => {
  return (
    <div className="relative">
      <input
        type="text"
        className={classNames(
          "relative px-4 py-3 w-full outline-none text-11 leading-13 text-tgpro-grey-1 font-normal font-plus-jakarta bg-tgpro-black-2 rounded-lg",
          className
        )}
        {...props}
      />
      {postFix && (
        <div className="absolute top-1/2 -translate-y-1/2 right-4">
          {postFix}
        </div>
      )}
      {hintText && (
        <div className="text-xs leading-13 text-tgpro-grey-1 font-normal font-plus-jakarta mt-2 px-3">
          {hintText}
        </div>
      )}
    </div>
  );
};
