/* eslint-disable react-hooks/exhaustive-deps */
import { getDaysDifference, getUser } from "../../utils/helpers";
import {
  getUserTags,
  updateChatListFilter,
} from "../../redux/slices/chatSlice";
import { useEffect } from "react";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import useTDLibController from "../../controllers/useTDLibController";
import { MdOutlineMarkChatUnread, MdStarOutline } from "react-icons/md";
import { Tags } from "./Tags";
import { Menu } from "./Menu";
import { FiCalendar, FiMessageSquare } from "react-icons/fi";
import SortBy from "./Sortby";
import { setShowPaymentPage } from "../../redux/slices/authSlice";
import DiamondIcon from "../../icons/DiamondIcon";

export const Header = () => {
  const dispatch = useDispatch();

  const {
    chatListFilterState,
    showChatSyncPage,
    showDraftSyncPage,
    showPaymentPage,
    showSettingsPage,
    showBulkMessagePage,
    checkPaymentInfoState,
  } = useSelector((state: RootState) => ({
    getUserTagsState: state.chat.getUserTagsState.data,
    chatListFilterState: state.chat.chatListFilterState,
    showChatSyncPage: state.auth.showChatSyncPage,
    showDraftSyncPage: state.auth.showDraftSyncPage,
    showPaymentPage: state.auth.showPaymentPage,
    showSettingsPage: state.auth.showSettingsPage,
    showBulkMessagePage: state.auth.showBulkMessagePage,
    checkPaymentInfoState: state.auth.checkPaymentInfoState.data?.data,
  }));

  const { tdClient, isReady } = useTDLibController();

  const getCurrentUserTags = async () => {
    let user = getUser();

    if (!user?.id) user = await tdClient?.send({ "@type": "getMe" });
    // @ts-ignore
    dispatch(getUserTags({ data: { telegramId: user.id } }));
  };

  useEffect(() => {
    if (isReady && !showDraftSyncPage) getCurrentUserTags();
  }, [tdClient]);

  return (
    <div className="flex justify-between items-center border-b border-tgpro-black-2">
      <div className="flex items-center gap-2 xl:gap-10 h-12 w-full pl-4 pr-0 xl:pr-8">
        {!(
          !checkPaymentInfoState?.isSubscribed &&
          !checkPaymentInfoState?.onTrial
        ) && <Menu />}
        {!showChatSyncPage &&
          !showDraftSyncPage &&
          !showPaymentPage &&
          !showSettingsPage &&
          !showBulkMessagePage && (
            <div className="flex items-center gap-1 xl:gap-2 ">
              <Tags />
              <SortBy />
              <div
                className={classNames(
                  "flex items-center justify-center gap-1 xl:gap-2 px-3.5 xl:px-4 py-2 text-11 leading-13 font-normal font-plus-jakarta w-max rounded-full cursor-pointer",
                  chatListFilterState.favorite
                    ? "text-tgpro-blue-1 bg-tgpro-blue-1/15"
                    : "text-tgpro-grey-1 bg-tgpro-grey-1/15"
                )}
                onClick={() =>
                  dispatch(
                    updateChatListFilter({
                      favorite: !chatListFilterState.favorite,
                    })
                  )
                }
              >
                <MdStarOutline className="h-4 w-4" />
                Favorite
              </div>
              <div
                className={classNames(
                  "flex items-center justify-center gap-1 xl:gap-2 px-3.5 xl:px-4 py-2 text-11 leading-13 font-normal font-plus-jakarta w-max rounded-full cursor-pointer",
                  chatListFilterState.followups
                    ? "text-tgpro-blue-1 bg-tgpro-blue-1/15"
                    : "text-tgpro-grey-1 bg-tgpro-grey-1/15"
                )}
                onClick={() =>
                  dispatch(
                    updateChatListFilter({
                      followups: !chatListFilterState.followups,
                    })
                  )
                }
              >
                <FiCalendar />
                Reminder
              </div>
              <div
                className={classNames(
                  "flex items-center justify-center gap-1 xl:gap-2 px-3.5 xl:px-4 py-2 text-11 leading-13 font-normal font-plus-jakarta w-max rounded-full cursor-pointer",
                  chatListFilterState.unansweredByMe
                    ? "text-tgpro-blue-1 bg-tgpro-blue-1/15"
                    : "text-tgpro-grey-1 bg-tgpro-grey-1/15"
                )}
                onClick={() =>
                  dispatch(
                    updateChatListFilter({
                      unansweredByMe: !chatListFilterState.unansweredByMe,
                    })
                  )
                }
              >
                <FiMessageSquare />
                Unanswered by me
              </div>

              <div
                className={classNames(
                  "flex items-center justify-center gap-1 xl:gap-2 px-3.5 xl:px-4 py-2 text-11 leading-13 font-normal font-plus-jakarta w-max rounded-full cursor-pointer",
                  chatListFilterState.unansweredByThem
                    ? "text-tgpro-blue-1 bg-tgpro-blue-1/15"
                    : "text-tgpro-grey-1 bg-tgpro-grey-1/15"
                )}
                onClick={() =>
                  dispatch(
                    updateChatListFilter({
                      unansweredByThem: !chatListFilterState.unansweredByThem,
                    })
                  )
                }
              >
                <FiMessageSquare />
                Unanswered by them
              </div>
              <div
                className={classNames(
                  "flex items-center justify-center gap-1 xl:gap-2 px-3.5 xl:px-4 py-2 text-11 leading-13 font-normal font-plus-jakarta w-max rounded-full cursor-pointer",
                  chatListFilterState.unread
                    ? "text-tgpro-blue-1 bg-tgpro-blue-1/15"
                    : "text-tgpro-grey-1 bg-tgpro-grey-1/15"
                )}
                onClick={() =>
                  dispatch(
                    updateChatListFilter({
                      unread: !chatListFilterState.unread,
                    })
                  )
                }
              >
                <MdOutlineMarkChatUnread />
                Unread
              </div>
            </div>
          )}
      </div>

      {!showPaymentPage && !showSettingsPage && !showBulkMessagePage && (
        <div className="flex items-center ml-2 xl:ml-0  mr-4 overflow-auto xl:overflow-visible">
          {checkPaymentInfoState?.isSubscribed ? (
            <div className="flex gap-2 items-center">
              <DiamondIcon />
              <span className="text-tgpro-orange-1 text-11 leading-13 font-normal whitespace-nowrap">
                Premium
              </span>
            </div>
          ) : checkPaymentInfoState?.onTrial ? (
            <div className="flex gap-2 items-center">
              <div className="w-5 h-5 rounded-full  border-4 border-yellow-500 border-opacity-25">
                <div className="w-3 h-3 bg-yellow-500 rounded-full" />
              </div>
              <span className="text-tgpro-grey-1 text-11 leading-13 font-normal whitespace-nowrap">
                Trial ends in{" "}
                {(() => {
                  const difference = getDaysDifference(
                    checkPaymentInfoState?.nextPaymentDate
                  );
                  return (
                    <>
                      <span className="text-white font-bold">{difference}</span>{" "}
                      day{difference > 1 ? "s" : ""}
                    </>
                  );
                })()}
              </span>
            </div>
          ) : checkPaymentInfoState?.trialApplicable ? (
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => dispatch(setShowPaymentPage(true))}
            >
              <div className="w-5 h-5 rounded-full  border-4 border-yellow-500 border-opacity-25">
                <div className="w-3 h-3 bg-yellow-500 rounded-full" />
              </div>
              <span className="text-white text-11 whitespace-nowrap text-opacity-50">
                Beta Testing Mode
              </span>
            </div>
          ) : null}
          <div className="border-l border-white border-opacity-25 h-3 mx-3" />
          <span className="text-tgpro-blue-1 border-b border-tgpro-blue-1 text-11">
            <a
              href="https://t.me/TGprosupport_bot"
              target="_blank"
              rel="noreferrer"
            >
              Help
            </a>
          </span>
          <div className="border-l border-white border-opacity-25 h-3 mx-3" />
          <span className="text-tgpro-blue-1 border-b border-tgpro-blue-1 text-11">
            <a
              href="https://form.typeform.com/to/MvHEV699"
              target="_blank"
              rel="noreferrer"
            >
              Feedback
            </a>
          </span>
        </div>
      )}
    </div>
  );
};
