import {
  ICheckPaymentInfoReq,
  ICheckPaymentInfoRes,
  ICreateCheckoutSessionReq,
  ReduxOptions,
} from "../../types";
import { axiosInterceptors } from "../helpers";

export const getPaymentPlansApi = async (
  option: ReduxOptions<ICreateCheckoutSessionReq, {}>
) => {
  try {
    const response = await axiosInterceptors.get(
      `${process.env.REACT_APP_API_URL}/get_prices`,
      { params: { telegram_id: option.data.telegramId } }
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const createCheckoutSessionApi = async (
  option: ReduxOptions<ICreateCheckoutSessionReq, { checkoutUrl: string }>
) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/create_checkout_session`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const checkPaymentInfoApi = async (
  option: ReduxOptions<ICheckPaymentInfoReq, ICheckPaymentInfoRes>
) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/payment_info`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const cancelSubscriptionApi = async (
  option: ReduxOptions<ICheckPaymentInfoReq, {}>
) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/cancel_subscription`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};
