/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import { useDispatch, useSelector } from "react-redux";
import useTDLibController from "../../controllers/useTDLibController";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import {
  addTag,
  getLinkedChats,
  getUserTags,
  linkChats,
  linkTags,
} from "../../redux/slices/chatSlice";
import { setShowChatSyncPage } from "../../redux/slices/authSlice";
import { Header } from "../../components/Header";
import { Input } from "../../components/Input";
import { PiMagnifyingGlass, PiSpinnerGapBold } from "react-icons/pi";
import { Button } from "../../components/Button";
import classNames from "classnames";
import { Checkbox } from "../../components/Checkbox";
import { getUser } from "../../utils/helpers";

export const SyncChatPage = () => {
  const { tdClient, isReady } = useTDLibController();
  const dispatch = useDispatch();

  const [selectedChatIds, setSelectedChatIds] = useState<number[]>([]);
  const [chats, setChats] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState<number>(0);
  const [folders, setFolders] = useState<any[]>([]);
  const [completedAddTagForFolderApis, setCompletedAddTagForFolderApis] =
    useState<number | undefined>(undefined);

  const [chatFoldersState, setChatFoldersState] = useState<any[]>([]);

  const { linkChatsStateLoading, getLinkedChatsStateLoading, chatFolders } =
    useSelector((state: RootState) => ({
      linkChatsStateLoading: state.chat.linkChatsState.loading,
      getLinkedChatsStateLoading: state.chat.getLinkedChatsState.loading,
      chatFolders: state.chat.chatFoldersState.data,
    }));

  useEffect(() => {
    if (chatFolders?.length) {
      setChatFoldersState(chatFolders);
    } else {
      const localChatFolders = JSON.parse(
        localStorage.getItem("chat-folders") || "[]"
      );

      if (localChatFolders?.length) {
        setChatFoldersState(localChatFolders);
      }
    }
  }, [chatFolders]);

  const getLinkedChatsData = async () => {
    let user = getUser();
    if (!user?.id) {
      user = await tdClient?.send({ "@type": "getMe" });
    }

    if (user?.id) {
      localStorage.setItem("user", JSON.stringify(user));
      setLoading(true);
      dispatch(
        // @ts-ignore
        getLinkedChats({
          // @ts-ignore
          data: { telegramId: user.id },
          onSuccess: (res) => {
            // @ts-ignore
            setSelectedChatIds(res?.data?.map?.((x: any) => x.chatId));
            setLoading(false);
          },
          onError: () => {
            setLoading(false);
          },
        })
      );
    }
  };

  useEffect(() => {
    getLinkedChatsData();
  }, []);

  const getTDChats = async () => {
    let chatListType: {
      "@type": string;
      chat_filter_id?: number;
    } = { "@type": "chatListMain" };
    setLoading(true);
    try {
      const result: any = await tdClient?.send({
        "@type": "getChats",
        chat_list: chatListType,
        limit: 2000,
      });
      let temp: any = [];
      for (const chatId of result?.chat_ids) {
        const chat = await tdClient?.send({
          "@type": "getChat",
          chat_id: chatId,
        });
        temp.push(chat);
      }
      setChats(temp);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tdClient) {
      getTDChats();
    }
  }, [tdClient]);

  const getFolderChats = async () => {
    setLoading(true);
    try {
      let temp: any = [];
      for (const folder of chatFoldersState) {
        let newFolder = { ...folder };
        const result: any = await tdClient?.send({
          "@type": "getChats",
          chat_list: {
            "@type": "chatListFilter",
            chat_filter_id: folder.id,
          },
          limit: 1000,
        });
        newFolder.chat_ids = result?.chat_ids;
        temp.push(newFolder);
      }
      setFolders(temp);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tdClient) {
      getFolderChats();
    }
  }, [tdClient, chatFoldersState]);

  const dispatchLinkChats = ({
    tgproBotChatId,
  }: {
    tgproBotChatId?: number;
  }) => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      linkChats({
        // @ts-ignore
        data: {
          telegramId: user.id,
          telegram_handle: user.username,
          chatIds: tgproBotChatId
            ? [tgproBotChatId, ...selectedChatIds]
            : selectedChatIds,
        },
        onSuccess: () => {
          if (!folders?.length) {
            setLoading(false);
            localStorage.setItem(
              "settings",
              JSON.stringify({ areChatsLinked: true })
            );

            const params = new URLSearchParams(window.location.search);
            if (params.get("show_payment_page") === "true") {
              window.location.href = window.location.origin;
            } else {
              dispatch(setShowChatSyncPage(false));
            }
          } else {
            dispatch(
              // @ts-ignore
              getUserTags({
                data: { telegramId: user.id },
                onSuccess: (res) => {
                  const tags = res.data || [];
                  for (const folder of folders) {
                    const selectedFolderChatIds = folder?.chat_ids.filter(
                      (chatId: number) => selectedChatIds?.includes(chatId)
                    );

                    if (selectedFolderChatIds?.length) {
                      let tag = tags?.find((t) => t.tagName === folder?.title);
                      if (tag && tag?.tagId) {
                        dispatch(
                          // @ts-ignore
                          linkTags({
                            data: {
                              telegramId: user.id,
                              chatId: selectedFolderChatIds,
                              tagIds: [tag.tagId],
                            },
                            onSuccess: (res) => {
                              setCompletedAddTagForFolderApis((prev) =>
                                !prev ? 1 : prev + 1
                              );
                            },
                            onError: (res) => {
                              setCompletedAddTagForFolderApis((prev) =>
                                !prev ? 1 : prev + 1
                              );
                            },
                          })
                        );
                      } else {
                        const user = getUser();
                        dispatch(
                          // @ts-ignore
                          addTag({
                            data: {
                              telegramId: user.id,
                              tagName: folder.title,
                            },
                            onSuccess: (res) => {
                              if (res.data?.tagId)
                                dispatch(
                                  // @ts-ignore
                                  linkTags({
                                    data: {
                                      telegramId: user.id,
                                      chatId: selectedFolderChatIds,
                                      tagIds: [res.data?.tagId],
                                    },
                                    onSuccess: (res) => {
                                      setCompletedAddTagForFolderApis((prev) =>
                                        !prev ? 1 : prev + 1
                                      );
                                    },
                                    onError: (res) => {
                                      setCompletedAddTagForFolderApis((prev) =>
                                        !prev ? 1 : prev + 1
                                      );
                                    },
                                  })
                                );
                            },
                            onError: (res) => {
                              setCompletedAddTagForFolderApis((prev) =>
                                !prev ? 1 : prev + 1
                              );
                            },
                          })
                        );
                      }
                    } else {
                      setCompletedAddTagForFolderApis((prev) =>
                        !prev ? 1 : prev + 1
                      );
                    }
                  }
                },
              })
            );
          }
        },
      })
    );
  };

  const onNext = async () => {
    let user = JSON.parse(localStorage.getItem("user")!);
    if (!user?.id) {
      user = await tdClient?.send({ "@type": "getMe" });
      localStorage.setItem("user", JSON.stringify(user));
    }

    setLoading(true);
    tdClient
      ?.send({
        "@type": "searchPublicChats",
        query: "TGproCopilot_bot",
      })
      .then((res: any) => {
        if (res?.chat_ids?.length) {
          const tgproBotChatId = res.chat_ids[0];
          tdClient
            ?.send({
              "@type": "getChatHistory",
              chat_id: tgproBotChatId,
              from_message_id: 0,
              offset: 0,
              limit: 1,
              only_local: false,
            })
            .then((result: any) => {
              if (!result?.messages?.length) {
                tdClient
                  ?.send({
                    "@type": "sendMessage",
                    chat_id: res.chat_ids[0],
                    reply_to_message_id: 0,
                    input_message_content: {
                      "@type": "inputMessageText",
                      text: {
                        "@type": "formattedText",
                        text: "/start",
                        entities: [],
                      },
                      disable_web_page_preview: true,
                      clear_draft: true,
                    },
                  })
                  .then((res) => {
                    dispatchLinkChats({ tgproBotChatId });
                  })
                  .catch((err) => {
                    console.log({ err });
                    setLoading(false);
                    dispatchLinkChats({});
                  });
              } else {
                dispatchLinkChats({});
              }
            })
            .catch((error: any) => {
              console.log({ error });
              dispatchLinkChats({});
            });
        } else {
          dispatchLinkChats({});
        }
      })
      .catch((err) => {
        console.log({ err });
        dispatchLinkChats({});
        setLoading(false);
      });
  };

  useEffect(() => {
    if (completedAddTagForFolderApis === folders?.length) {
      setLoading(false);
      localStorage.setItem(
        "settings",
        JSON.stringify({ areChatsLinked: true })
      );

      const params = new URLSearchParams(window.location.search);
      if (params.get("show_payment_page") === "true") {
        window.location.href = window.location.origin;
      } else {
        dispatch(setShowChatSyncPage(false));
      }
    }
  }, [completedAddTagForFolderApis]);

  const selectedFolder = folders?.find(
    (folder) => folder.id === selectedFolderId
  );

  const visibleChats = chats?.filter((chat: any) =>
    selectedFolder?.chat_ids?.includes(chat.id)
  );

  const handleSelectAll = () => {
    const allVisibleChatIds = visibleChats?.map((chat: any) => chat.id);
    setSelectedChatIds(
      Array.from(new Set([...selectedChatIds, ...allVisibleChatIds]))
    );
  };

  const handleUnselectAll = () => {
    const filteredChatIds = selectedChatIds?.filter(
      (id) => !visibleChats?.map((chat: any) => chat.id)?.includes(id)
    );
    setSelectedChatIds(filteredChatIds);
  };

  const allSelected =
    visibleChats?.length > 0 &&
    visibleChats?.every((chat: any) => selectedChatIds?.includes(chat.id));

  return (
    <main className="min-h-screen">
      <Header />
      <div className="flex flex-col items-center gap-[104px] pt-[32.5px]">
        <div className="text-13 leading-16 text-tgpro-grey-2 font-normal font-plus-jakarta">
          TGpro will never have access to your chats and conversations. Learn
          more{" "}
          <a
            href="https://core.telegram.org/tdlib"
            className="text-tgpro-blue-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </div>
        <div className="flex flex-col items-center gap-4 w-[400px] relative">
          <h1 className="text-xl leading-[25.2px] font-normal font-plus-jakarta text-tgpro-white-1">
            Sync your Chats
          </h1>
          <div className="w-full relative">
            <select
              className="w-full border-none outline-none bg-tgpro-black-2 px-4 py-3 rounded-lg text-11 leading-13 text-tgpro-grey-1 font-normal font-plus-jakarta"
              value={selectedFolderId}
              onChange={(e) => setSelectedFolderId(+e.target.value)}
            >
              <option
                value={""}
                className="text-sm leading-13 text-tgpro-grey-1 font-normal font-plus-jakarta"
              >
                Select folder
              </option>
              {chatFoldersState.map((folder) => (
                <option
                  key={folder.id}
                  value={folder.id}
                  className="text-sm leading-13 text-tgpro-grey-1 font-normal font-plus-jakarta"
                >
                  {folder.title}
                </option>
              ))}
            </select>
            <div className="absolute top-0 left-[calc(100%+8px)] w-[120px]">
              <Button
                name="Next"
                onClick={onNext}
                loading={linkChatsStateLoading}
              />
            </div>
          </div>
          {selectedFolderId !== 0 && (
            <div className="w-full">
              {allSelected ? (
                <div
                  className="w-fit text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1 underline cursor-pointer text-start"
                  onClick={handleUnselectAll}
                >
                  Unselect all
                </div>
              ) : (
                <div
                  className="w-fit text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1 underline cursor-pointer"
                  onClick={handleSelectAll}
                >
                  Select all
                </div>
              )}
            </div>
          )}

          <div className="w-full relative">
            <Input
              placeholder="Search"
              postFix={<PiMagnifyingGlass className="text-tgpro-grey-1" />}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {/* <div className="absolute top-0 left-[calc(100%+8px)] w-[120px]">
              <Button
                name="Next"
                onClick={onNext}
                loading={linkChatsStateLoading}
              />
            </div> */}
          </div>
          {/* <div className="w-full pl-2">
            {(selectedFolderId &&
              folders
                .find((x) => x.id === selectedFolderId)
                ?.chat_ids.some((x: any) => selectedChatIds.includes(x))) ||
            (!selectedFolderId && selectedChatIds.length >= 1) ? (
              <div
                className="text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1 underline cursor-pointer"
                onClick={() => {
                  let temp = [...selectedChatIds];
                  if (selectedFolderId) {
                    const folder = folders.find(
                      (fld) => fld.id === selectedFolderId
                    );
                    temp = temp.filter((x) => !folder.chat_ids.includes(x));
                    setSelectedChatIds(temp);
                  } else {
                    setSelectedChatIds([]);
                  }
                }}
              >
                Unselect all
              </div>
            ) : (
              <div
                className="text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1 underline cursor-pointer"
                onClick={() => {
                  let temp = [...selectedChatIds];
                  if (selectedFolderId) {
                    const folder = folders.find(
                      (fld) => fld.id === selectedFolderId
                    );
                    const newSelected = [...temp, ...folder.chat_ids];
                    temp = newSelected.filter(
                      (item, index) => newSelected.indexOf(item) === index
                    );
                    setSelectedChatIds(temp);
                  } else {
                    setSelectedChatIds(chats.map((x: any) => x.id));
                  }
                }}
              >
                Select all
              </div>
            )}
          </div> */}
          {loading || getLinkedChatsStateLoading ? (
            <div className="flex  items-center gap-1 text-11 leading-16 text-tgpro-grey-2 font-normal font-plus-jakarta">
              <PiSpinnerGapBold className="text-tgpro-grey-2 animate-spin" />
              Please wait, we are sync your Telegram chat. This can take a few
              minutes.
            </div>
          ) : (
            <div className="max-h-[416px] overflow-auto w-full pr-2">
              {(chats || [])
                .filter((x: any) => {
                  let searchTextFlag = true;
                  let folderIdFlag = true;

                  if (searchText)
                    searchTextFlag = x.title
                      .toLowerCase()
                      .includes(searchText.toLowerCase());

                  if (selectedFolderId)
                    folderIdFlag = folders
                      ?.find((folder) => folder.id === selectedFolderId)
                      ?.chat_ids?.includes(x.id);

                  return searchTextFlag && folderIdFlag;
                })
                .map((chat: any, i) => (
                  <div key={chat.id}>
                    <div
                      className={classNames(
                        "flex items-center gap-2 relative px-4 py-2 w-full outline-none text-11 leading-13 font-normal font-plus-jakarta rounded-lg",
                        selectedChatIds.includes(chat.id)
                          ? "bg-tgpro-blue-1/15 text-tgpro-blue-1"
                          : "text-tgpro-grey-1 bg-tgpro-black-2"
                      )}
                      onClick={() => {
                        let temp = [...selectedChatIds];
                        const index = temp.findIndex((id) => id === chat.id);
                        if (index !== -1) {
                          temp.splice(index, 1);
                        } else {
                          temp.push(chat.id);
                        }
                        setSelectedChatIds(temp);
                      }}
                    >
                      <Checkbox checked={selectedChatIds.includes(chat.id)} />
                      {chat?.title}
                    </div>
                    {i !== chats.length - 1 && (
                      <div className="h-[0.5px] my-2 bg-tgpro-black-3"></div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </main>
  );
};
