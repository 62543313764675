import { useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { RiFilterLine } from "react-icons/ri";
import { FaChevronDown } from "react-icons/fa";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { updateChatListFilter } from "../../redux/slices/chatSlice";

const SortBy = () => {
  const dispatch = useDispatch();
  const [showSortbyPopover, setShowSortbyPopover] = useState(false);
  const filterOptions = [
    { name: "Recent", value: "recent" },
    { name: "Oldest", value: "oldest" },
    { name: "Follow-up due", value: "follow-up-due" },
  ];
  const [selectedFilter, setSelectFilter] = useState(filterOptions[0]);

  const sortingModalRef = useRef<HTMLInputElement>(null);
  useOutsideClick(sortingModalRef, () => setShowSortbyPopover(false));

  const handleFilterChange = (data: any) => {
    setSelectFilter(data);
    dispatch(updateChatListFilter({ sorting: data }));
    setShowSortbyPopover(false);
  };

  return (
    <div className="relative" ref={sortingModalRef}>
      <div
        className={classNames(
          "flex items-center justify-center gap-2 px-4 py-2 text-11 leading-13 font-normal font-plus-jakarta w-max rounded-full cursor-pointer",
          showSortbyPopover
            ? "text-tgpro-blue-1 bg-tgpro-blue-1/15"
            : "text-tgpro-grey-1 bg-tgpro-grey-1/15"
        )}
        onClick={() => setShowSortbyPopover(true)}
      >
        <RiFilterLine className="h-4 w-4" />
        <span>Sort by</span>
        <span>{selectedFilter?.name}</span>
        <FaChevronDown />
      </div>
      <div className="w-full">
        {showSortbyPopover && (
          <div className=" absolute top-full left-0 z-[999] w-max bg-tgpro-black-1 rounded-xl">
            <div className="max-h-[300px] overflow-auto w-52 mt-2 rounded-xl">
              {filterOptions?.map((data, index) => (
                <div
                  onClick={() => handleFilterChange(data)}
                  key={index}
                  className={classNames(
                    "flex items-center gap-2 relative px-4 py-2 w-full outline-none text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-1 bg-tgpro-black-2 cursor-pointer",
                    selectedFilter?.name === data?.name
                      ? "bg-tgpro-blue-1/15"
                      : "text-tgpro-grey-1 bg-tgpro-black-2"
                  )}
                >
                  {data?.name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default SortBy;
