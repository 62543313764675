import classNames from "classnames";
import React from "react";

const Tooltip = ({
  children,
  text,
  className,
}: {
  children: React.ReactNode;
  text: string;
  className?: string;
}) => (
  <div className={classNames("relative group inline-block", className)}>
    {children}
    <span className="group-hover:visible invisible absolute bottom-full left-1/2 transform -translate-x-1/2 rounded-full whitespace-nowrap text-10 text-tgpro-grey-6 border px-2 py-1 border-tgpro-black-2 z-50 bg-tgpro-black-1 mb-0.5">
      {text}
    </span>
  </div>
);

export default Tooltip;
