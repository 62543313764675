import {
  ConnReq,
  IAddFavouriteChat,
  IGetLinkedChatsReq,
  ILinkChatsReq,
  ILinkDraftsReq,
  IUnLinkChatsReq,
  ReduxOptions,
} from "../../types";
import { axiosInterceptors } from "../helpers";

export const linkChatsApi = async (option: ReduxOptions<ILinkChatsReq, {}>) => {
  console.log({ option });
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/link_chats`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const unlinkChatsApi = async (
  option: ReduxOptions<IUnLinkChatsReq, {}>
) => {
  console.log({ option });
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/unlink_chats`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const checkConnection = async (option: ReduxOptions<ConnReq, {}>) => {
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/conn`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const getLinkedChatsApi = async (
  option: ReduxOptions<IGetLinkedChatsReq, {}>
) => {
  try {
    const response = await axiosInterceptors.get(
      `${process.env.REACT_APP_API_URL}/chats`,
      {
        params: { telegram_id: option.data.telegramId },
      }
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const addFavouriteChatApi = async (
  option: ReduxOptions<IAddFavouriteChat, {}>
) => {
  try {
    const response = await axiosInterceptors.put(
      `${process.env.REACT_APP_API_URL}/favorite`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const getDraftsAPI = async (
  option: ReduxOptions<IGetLinkedChatsReq, {}>
) => {
  try {
    const response = await axiosInterceptors.get(
      `${process.env.REACT_APP_API_URL}/drafts`,
      {
        params: { telegram_id: option.data.telegramId },
      }
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const postLinkDraftsApi = async (
  option: ReduxOptions<ILinkDraftsReq, {}>
) => {
  console.log({ option });
  try {
    const response = await axiosInterceptors.post(
      `${process.env.REACT_APP_API_URL}/link_drafts`,
      option.data
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};

export const getKeyAPI = async (option: ReduxOptions<ConnReq, {}>) => {
  try {
    const response = await axiosInterceptors.get(
      `${process.env.REACT_APP_API_URL}/get_api`,
      {
        params: { telegram_id: option.data.telegramId },
      }
    );
    option.onSuccess && option.onSuccess(response.data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    option.onError && option.onError(error?.response.data);
  }
};
