import React, { useRef, useState } from "react";
import { FaRegStickyNote } from "react-icons/fa";
import { getUser } from "../../utils/helpers";
import { useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import {
  addNote,
  setOpenChat,
  setTdChatList,
} from "../../redux/slices/chatSlice";
import { Textarea } from "../../components/Textarea";

export const Notes = () => {
  const dispatch = useDispatch();

  const { openedChatState, tdChatListState } = useSelector(
    (state: RootState) => ({
      openedChatState: state.chat.openedChatState.data,
      tdChatListState: state.chat.tdChatListState.data,
    })
  );

  const [noteText, setNoteText] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const onAddNote = (message: string) => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      addNote({
        data: {
          telegramId: user.id,
          chatId: openedChatState?.id,
          message,
        },
        onSuccess: (res) => {
          setNoteText("");
          let temp = [...tdChatListState];
          const index = temp.findIndex((x) => x.id === openedChatState.id);
          if (index !== -1) {
            let dbData = { ...temp[index].dbData };
            let dbChatNotes = [...dbData.notes];
            dbChatNotes = [
              {
                createdAt: new Date().toISOString(),
                isEdited: false,
                message,
                noteId: res?.data?.noteId,
              },
              ...dbChatNotes,
            ];
            dbData = { ...dbData, notes: dbChatNotes };
            temp[index] = { ...temp[index], dbData };
            dispatch(setTdChatList(temp));
            dispatch(setOpenChat({ ...openedChatState, dbData }));
          }
        },
      })
    );
  };

  return (
    <div className="w-full relative flex flex-col gap-4 mt-8">
      <div className="flex items-center gap-2">
        <FaRegStickyNote className="text-tgpro-blue-1 h-3 w-3" />
        <div className="text-xs leading-15 font-normal font-plus-jakarta text-tgpro-grey-1">
          Add a note
        </div>
      </div>
      <div className="bg-tgpro-black-2 rounded-lg flex items-center flex-wrap gap-2 relative">
        <Textarea
          ref={textareaRef}
          placeholder="Write your note here..."
          value={noteText}
          className="min-h-16 max-h-52"
          onChange={(e) => setNoteText(e.target.value)}
          onKeyDown={(e) => {
            if (
              e.key === "Enter" &&
              !!(e.target as any)?.value.trim() &&
              !e.shiftKey
            ) {
              e.preventDefault();

              onAddNote((e.target as any)?.value);
            }
          }}
        />
        <div className="w-full mt-5">
          <div
            className="flex items-center justify-center gap-1 px-2 py-1 text-11 leading-13 font-normal font-plus-jakarta text-tgpro-blue-1 bg-tgpro-blue-1/15 w-max rounded-lg absolute right-6 bottom-4 !outline-none !focus:outline-none !border-none focus:border-none !outline-transparent cursor-pointer"
            onClick={() => {
              if (noteText) onAddNote(noteText);
            }}
          >
            Press “Enter” to add note
          </div>
        </div>
      </div>
    </div>
  );
};
