import { IoMdCloseCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";
import { HiUserGroup } from "react-icons/hi";
import { MdStar } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  addBulkFolloup,
  getLinkedChats,
  updateAddBulkFollowUpsModalState,
} from "../../redux/slices/chatSlice";
import { FiCalendar } from "react-icons/fi";
import { useRef, useState } from "react";
import moment from "moment";
import { getDaysDifference, getUser } from "../../utils/helpers";
import useTDLibController from "../../controllers/useTDLibController";
import { Button } from "../Button";

const AddBulkFollowUps = () => {
  const { addBulkFollowUpsModalState, addFollowupsStateLoading } = useSelector(
    (state: RootState) => ({
      addBulkFollowUpsModalState: state.chat.addBulkFollowUpsModalState,
      addFollowupsStateLoading: state.chat.addBulkFollowupState.loading,
    })
  );

  const { tdClient } = useTDLibController();

  const dispatch = useDispatch();
  const dateTimeRef = useRef<HTMLInputElement>(null);
  const [date, setDate] = useState(new Date().toString());

  const getLinkedChatsData = async () => {
    let user = getUser();
    if (!user?.id) {
      user = await tdClient?.send({ "@type": "getMe" });
    }
    if (user?.id) {
      localStorage.setItem("user", JSON.stringify(user));
      dispatch(
        // @ts-ignore
        getLinkedChats({
          // @ts-ignore
          data: { telegramId: user.id },
          onSuccess: (res: any) => {},
          onError: () => {},
        })
      );
    }
  };
  const onAddFollowup = () => {
    if (date && getDaysDifference(date) > 0) {
      const user = getUser();
      dispatch(
        // @ts-ignore
        addBulkFolloup({
          data: {
            telegramId: user.id,
            chatId: addBulkFollowUpsModalState?.chats?.map((data) => data.id),
            followUpDate: moment(date).format("YYYY-MM-DDT00:00:00"),
          },
          onSuccess: () => {
            getLinkedChatsData();
            addBulkFollowUpsModalState?.onAddFollowUpSuccess?.();
            dispatch(
              updateAddBulkFollowUpsModalState({ visible: false, chats: [] })
            );
          },
        })
      );
    }
  };

  return (
    <>
      <div className="absolute top-0 left-0 h-screen w-screen bg-black/50 flex items-center justify-center">
        <div className="p-6 bg-tgpro-black-1 border border-tgpro-black-2 rounded-xl flex flex-col gap-[26px] w-[415px]">
          <div>
            <h3 className="text-sm font-semibold font-plus-jakarta text-white flex items-center justify-between">
              Add follow-up ({addBulkFollowUpsModalState?.chats?.length} chats
              selected)
              <IoMdCloseCircleOutline
                className="h-6 w-6 text-[#51627b] cursor-pointer"
                onClick={() => {
                  dispatch(
                    updateAddBulkFollowUpsModalState({
                      visible: false,
                      chats: [],
                    })
                  );
                }}
              />
            </h3>
          </div>
          <div className="space-y-2 max-h-[153px] overflow-auto">
            {addBulkFollowUpsModalState?.chats?.map((chat: any) => (
              <div className="flex items-center gap-[9px]" key={chat?.id}>
                {chat?.dbData?.isFavorite && (
                  <MdStar className="text-[#FCAF03] cursor-pointer" />
                )}
                {chat?.type?.["@type"] === "chatTypeSupergroup" && (
                  <HiUserGroup className="text-tgpro-grey-1" />
                )}
                <HiMiniChatBubbleOvalLeft className="text-tgpro-grey-1 [transform:rotateY(180deg)]" />
                <div className="text-xs leading-4 text-white font-normal font-plus-jakarta text-ellipsis whitespace-nowrap w-64 overflow-hidden">
                  {chat?.title}
                </div>
              </div>
            ))}
          </div>
          <div className="relative mt-8 flex items-center justify-between">
            <div className="flex items-center gap-[9px] text-xs leading-15 font-medium text-tgpro-blue-1 relative">
              <div>
                <FiCalendar />
              </div>
              <span className="text-tgpro-grey-1">Follow up in</span>{" "}
              <span
                className="underline"
                onClick={() => dateTimeRef.current?.showPicker()}
              >
                <input
                  type="text"
                  className="w-3 bg-transparent outline-none border-b border-tgpro-blue-1"
                  value={getDaysDifference(date)}
                  readOnly
                />
              </span>{" "}
              <span className="text-tgpro-grey-1">days</span>
              <input
                ref={dateTimeRef}
                type="date"
                className="invisible top-0 absolute bg-black"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                min={(() => {
                  return moment()
                    .add(1, "day")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .toISOString()
                    .split("T")[0];
                })()}
              />
            </div>
            <Button
              name="Add Bulk Follow-ups"
              className="!w-[132px]"
              onClick={onAddFollowup}
              loading={addFollowupsStateLoading}
              disabled={
                addFollowupsStateLoading || getDaysDifference(date) === 0
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AddBulkFollowUps;
