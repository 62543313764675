import { MdFileDownload } from "react-icons/md";
import Tooltip from "./Tooltip";
import { IoClose } from "react-icons/io5";

const PreviewImageModal = ({
  isOpen,
  onClose,
  imageUrl,
}: {
  isOpen: boolean;
  onClose: any;
  imageUrl: any;
}) => {
  if (!isOpen) return null;
  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      className="fixed inset-0 bg-black/50 bg-opacity-75 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div className="relative">
        <button
          onClick={onClose}
          className="absolute -top-10 right-0 text-white text-xl"
        >
          <IoClose />
        </button>

        <img
          src={imageUrl}
          className="max-h-screen max-w-screen"
          alt="Big Preview"
        />
      </div>
      <button
        onClick={downloadImage}
        className="bottom-5 right-10 absolute text-white text-xl"
      >
        <Tooltip text="Download">
          <MdFileDownload className="text-white" />
        </Tooltip>
      </button>
    </div>
  );
};

export default PreviewImageModal;
