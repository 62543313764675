import { MdHistory } from "react-icons/md";
import { BiTrash } from "react-icons/bi";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import moment from "moment";
import { getUser } from "../../utils/helpers";
import { useDispatch } from "react-redux";
import {
  removeFollowUp,
  removeNote,
  setOpenChat,
  setTdChatList,
} from "../../redux/slices/chatSlice";
import { FollowUpTag } from "../../components/FollowUpTag";

export const History = () => {
  const dispatch = useDispatch();

  const { openedChatState, tdChatListState } = useSelector(
    (state: RootState) => ({
      openedChatState: state.chat.openedChatState.data,
      tdChatListState: state.chat.tdChatListState.data,
    })
  );

  const onRemoveNote = (noteId: string) => {
    const user = getUser();
    dispatch(
      // @ts-ignore
      removeNote({
        data: {
          telegramId: user.id,
          chatId: openedChatState?.id,
          noteId,
        },
        onSuccess: (res: any) => {
          let temp = [...tdChatListState];
          const index = temp.findIndex((x) => x.id === openedChatState.id);
          if (index !== -1) {
            let dbData = { ...temp[index].dbData };
            let dbChatNotes = [...dbData.notes];
            const chatNoteIndex = dbChatNotes.findIndex(
              (note) => note.noteId === noteId
            );
            if (chatNoteIndex !== -1) dbChatNotes.splice(chatNoteIndex, 1);
            dbData = { ...dbData, notes: dbChatNotes };
            temp[index] = { ...temp[index], dbData };
            dispatch(setTdChatList(temp));
            dispatch(setOpenChat({ ...openedChatState, dbData }));
          }
        },
      })
    );
  };

  const onRemoveFollowup = (followUpId: string) => {
    dispatch(
      // @ts-ignore
      removeFollowUp({
        data: {
          followup_id: followUpId,
        },
        onSuccess: (res: any) => {
          let temp = [...tdChatListState];
          const index = temp.findIndex((x) => x.id === openedChatState.id);
          if (index !== -1) {
            let dbData = { ...temp[index].dbData };
            let dbChatFollowUps = [...dbData.followUps];
            const chatFollowUpIndex = dbChatFollowUps.findIndex(
              (followUp) => followUp.followUpId === followUpId
            );
            if (chatFollowUpIndex !== -1)
              dbChatFollowUps.splice(chatFollowUpIndex, 1);
            dbData = { ...dbData, followUps: dbChatFollowUps };
            temp[index] = { ...temp[index], dbData };
            dispatch(setTdChatList(temp));
            dispatch(setOpenChat({ ...openedChatState, dbData }));
          }
        },
      })
    );
  };

  return openedChatState?.dbData?.notes?.length ||
    openedChatState?.dbData?.followUps?.length ? (
    <div className="flex-1">
      <div className="flex items-center gap-2 sticky top-0 py-5 bg-tgpro-black-1 z-30">
        <MdHistory className="text-tgpro-blue-1" />
        <div className="text-xs leading-15 font-normal font-plus-jakarta text-tgpro-grey-1">
          History
        </div>
      </div>
      <div className="pr-4 flex flex-col gap-6">
        {[
          ...(openedChatState?.dbData?.notes || []).map((x: any) => ({
            ...x,
            type: "NOTE",
          })),
          ...(openedChatState?.dbData?.followUps || []).map((x: any) => ({
            ...x,
            type: "FOLLOW_UP",
          })),
        ]
          ?.sort(
            (x, y) =>
              new Date(y?.createdAt).getTime() -
              new Date(x?.createdAt).getTime()
          )
          .map((note: any) => (
            <div className="flex items-center gap-4" key={note.noteId}>
              <div className="flex-1 flex flex-col gap-2" key={note.noteId}>
                <div className="text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-5">
                  {moment(note.createdAt).format("D MMM yyyy, hh:mm A")}
                </div>
                {note.type === "FOLLOW_UP" ? (
                  <FollowUpTag followUp={note} />
                ) : (
                  <div className="bg-tgpro-black-2 rounded-lg flex items-center p-4 text-11 leading-13 font-normal font-plus-jakarta text-tgpro-grey-5 whitespace-pre-wrap">
                    {note.message}
                  </div>
                )}
              </div>
              <div
                className="flex items-center justify-center h-6 w-6 border-[0.63px] border-tgpro-black-3 rounded-full cursor-pointer hover:border-tgpro-red-1 text-tgpro-black-3 hover:text-tgpro-red-1"
                onClick={() => {
                  note.type === "FOLLOW_UP"
                    ? onRemoveFollowup(note?.followUpId)
                    : onRemoveNote(note?.noteId);
                }}
              >
                <BiTrash className="h-4 w-4" />
              </div>
            </div>
          ))}
      </div>
    </div>
  ) : null;
};
