"use client";

import { useDispatch } from "react-redux";
import useTDLibController from "../../controllers/useTDLibController";
import { useState } from "react";
import { setMe } from "../../redux/slices/authSlice";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

export const PasswordVerificationPage = () => {
  const dispatch = useDispatch();

  const { tdClient } = useTDLibController();

  const { authState } = useSelector((state: RootState) => ({
    authState: state.auth.authState,
  }));

  const [password, setPassword] = useState("");

  const onNext = () => {
    tdClient
      ?.send({
        "@type": "checkAuthenticationPassword",
        password: password,
      })
      .then(async (result) => {
        const me = await tdClient?.send({ "@type": "getMe" });
        localStorage.setItem("user", JSON.stringify(me));
        dispatch(setMe(me));
      })
      .catch((error) => {
        if (error && error["@type"] === "error" && error.message) {
          alert(error.message);
        } else {
          alert(JSON.stringify(error));
        }
      });
  };

  return (
    <main className="flex min-h-screen flex-col items-center justify-center gap-10 p-24">
      <h1 className="text-xl leading-[25.2px] font-normal font-plus-jakarta text-tgpro-white-1">
        Your account is protected with password
      </h1>
      <div className="w-[400px]">
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onNext();
            }
          }}
          // @ts-ignore
          hintText={authState?.password_hint}
        />
      </div>
      <div className="w-[120px]">
        <Button name="Next" onClick={onNext} />
      </div>
    </main>
  );
};
