import { ChatList } from "./ChatList";
import { Chat } from "./Chat";
import { History } from "./History";
import { Notes } from "./Notes";
import { AddFollowUp } from "./AddFollowUp";
import { Tags } from "./Tags";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Header } from "../../components/Header";
import {
  AiOutlineExclamationCircle,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { AddBulkTags } from "../../components/AddBulkTags";
import { useEffect, useState } from "react";
import { ForwardMessages } from "../../components/ForwardMessages";
import { CollapseIcon } from "../../icons";
import Tooltip from "../../components/Tooltip";
import AddBulkFollowUps from "../../components/BulkFollowups/AddBulkFollwups";
import { LuSettings } from "react-icons/lu";
import Sorting from "../PipelinePage/Sorting";
import { useDispatch } from "react-redux";
import {
  setShowBulkMessagePage,
  setShowChatSyncPage,
  setShowDraftSyncPage,
  setShowPaymentPage,
  setShowPipelinePage,
  setShowSettingsPage,
} from "../../redux/slices/authSlice";
import useTDLibController from "../../controllers/useTDLibController";

const tabsList = [
  {
    id: 1,
    title: "Notes",
  },
  {
    id: 2,
    title: "Pipeline",
  },
  {
    id: 3,
    title: "Info",
  },
];

export const MainPage = () => {
  const { tdClient, isReady } = useTDLibController();

  const {
    openedChatState,
    addBulkTagsModalState,
    forwardMessagesModalState,
    rightSideLoading,
    addBulkFollowUpsModalState,
  } = useSelector((state: RootState) => ({
    openedChatState: state.chat.openedChatState,
    addBulkTagsModalState: state.chat.addBulkTagsModalState,
    forwardMessagesModalState: state.chat.forwardMessagesModalState,
    addBulkFollowUpsModalState: state.chat.addBulkFollowUpsModalState,
    rightSideLoading:
      state.chat.addTagState.loading ||
      state.chat.linkTagsState.loading ||
      state.chat.unlinkTagState.loading ||
      state.chat.editTagState.loading ||
      state.chat.addFolloupState.loading ||
      state.chat.addNoteState.loading ||
      state.chat.removeNoteState?.loading ||
      state.chat.removeFollowUpState.loading,
  }));
  const [isOpen, setIsOpen] = useState(true);
  const [selectedTab, setSelectedTab] = useState(1);
  const [chatUser, setChatUser] = useState<any>(null);
  const dispatch = useDispatch();
  const toggleSection = () => {
    setIsOpen((pre) => !pre);
  };

  useEffect(() => {
    setChatUser(null);
    if (isReady && openedChatState.data?.type?.user_id) {
      tdClient
        ?.send({
          "@type": "getUser",
          user_id: openedChatState.data?.type?.user_id,
        })
        .then((user) => {
          tdClient
            ?.send({
              "@type": "getUserProfilePhotos",
              user_id: openedChatState.data?.type?.user_id,
              limit: 1,
            })
            .then(async (res) => {
              function blobToDataURL(
                blob: Blob,
                callback: (args: any) => void
              ) {
                var reader = new FileReader();
                reader.onload = function (e) {
                  callback(e.target!?.result);
                };
                reader.readAsDataURL(blob);
              }
              // @ts-ignore
              const fileId = res.photos?.[0]?.sizes?.[0]?.photo?.id;

              if (fileId) {
                try {
                  const file: any = await tdClient?.send({
                    "@type": "readFile",
                    // @ts-ignore
                    file_id: fileId,
                  });

                  blobToDataURL(file?.data, (src) => {
                    setChatUser({ ...user, image: src });
                  });
                } catch (error) {
                  try {
                    await tdClient?.send({
                      "@type": "downloadFile",
                      file_id: fileId,
                      priority: 1,
                      offset: 0,
                      synchronous: true,
                    });

                    const file: any = await tdClient?.send({
                      "@type": "readFile",
                      file_id: fileId,
                    });

                    blobToDataURL(file?.data, (src) => {
                      setChatUser({ ...user, image: src });
                    });
                  } catch (err) {}
                }
              } else {
                setChatUser(user);
              }
            });
        });
    }
  }, [openedChatState, tdClient]);

  return (
    <main className="max-h-screen">
      <Header />
      <div className="flex flex-row h-full min-w-screen">
        <ChatList />
        {!openedChatState?.data ? (
          <div className="flex-1 flex flex-col items-center justify-center gap-6 h-[calc(100vh-80px)]">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.3337 10H46.667C61.3947 10 73.3337 21.9391 73.3337 36.6667C73.3337 51.3943 61.3947 63.3333 46.667 63.3333V75C30.0003 68.3333 6.66699 58.3333 6.66699 36.6667C6.66699 21.9391 18.6061 10 33.3337 10ZM40.0003 56.6667H46.667C57.7127 56.6667 66.667 47.7123 66.667 36.6667C66.667 25.621 57.7127 16.6667 46.667 16.6667H33.3337C22.288 16.6667 13.3337 25.621 13.3337 36.6667C13.3337 48.7 21.5406 56.552 40.0003 64.9327V56.6667Z"
                fill="#5E91FF"
              />
            </svg>
            <div className="text-base leading-5 text-tgpro-white-2 font-plus-jakarta">
              Open a message to see the magic 💫
            </div>
          </div>
        ) : (
          <>
            <Chat />
            <div
              className="absolute right-6 cursor-pointer z-20 mt-4"
              onClick={toggleSection}
            >
              <Tooltip text={isOpen ? "Collapse" : "Expand"}>
                <CollapseIcon color={isOpen ? "#51627B" : "#5E91FF"} />
              </Tooltip>
            </div>

            {isOpen ? (
              <div className="transition-all duration-300 ease-linear flex-1 max-w-[480px] relative overflow-hidden">
                <div className="mt-14">
                  <div className="text-sm font-medium text-center text-[#9AA4B2] px-4">
                    <ul className="grid grid-flow-col w-full">
                      {tabsList.map((data) => (
                        <li
                          className={`w-full cursor-pointer ${
                            selectedTab === data.id
                              ? "border-b border-tgpro-blue-1 text-tgpro-blue-1 border-opacity-50"
                              : "border-b border-[#51627B]"
                          }`}
                          onClick={() => setSelectedTab(data.id)}
                          key={data.id}
                        >
                          <span className={`inline-block mb-[5px]`}>
                            {data.title}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="p-4 flex flex-col h-full max-h-[calc(100vh-140px)]">
                    {selectedTab === 1 ? (
                      <>
                        <Tags />
                        <AddFollowUp />
                        <Notes />
                        <div className="overflow-auto">
                          <History />
                        </div>
                      </>
                    ) : selectedTab === 2 ? (
                      <div className="px-6">
                        <div className="mt-4 mb-3.5 flex items-center gap-4">
                          <button
                            className="text-tgpro-blue-1 flex items-center gap-2 px-2 py-1.5 bg-tgpro-blue-1 bg-opacity-[15%] rounded"
                            onClick={() => {
                              dispatch(setShowPipelinePage(false));
                              dispatch(setShowDraftSyncPage(false));
                              dispatch(setShowChatSyncPage(false));
                              dispatch(setShowPaymentPage(false));
                              dispatch(setShowSettingsPage(true));
                              dispatch(setShowBulkMessagePage(false));
                            }}
                          >
                            <LuSettings className="w-3 h-3" />
                            <span className="text-11 font-medium">
                              Pipeline Settings
                            </span>
                          </button>
                        </div>
                        <span className="text-tgpro-white-3 mt-2 text-xs">
                          Status
                        </span>
                        <div className="mt-1.5">
                          <Sorting />
                        </div>
                      </div>
                    ) : selectedTab === 3 ? (
                      <div>
                        <div className="flex items-center gap-6">
                          {chatUser?.image && (
                            <div className="h-[59px] w-[59px] rounded-full overflow-hidden">
                              <img
                                className="h-full w-full"
                                src={chatUser?.image}
                              />
                            </div>
                          )}
                          <div className="space-y-2">
                            <div className="text-sm font-semibold font-plus-jakarta text-white">
                              {chatUser?.first_name}
                            </div>
                            <div className="text-xs leading-15 font-semibold font-plus-jakarta text-tgpro-grey-5">
                              Contact Added
                            </div>
                          </div>
                        </div>
                        <div className="mt-[31px] flex gap-4">
                          <div>
                            <AiOutlineExclamationCircle className="h-6 w-6 text-[#bdbfbc]" />
                          </div>
                          <div className="space-y-4">
                            {chatUser?.username && (
                              <div className="space-y-2">
                                <div className="text-xs font-normal font-plus-jakarta text-tgpro-grey-6">
                                  User Name
                                </div>
                                <div className="text-xs leading-15 font-semibold font-plus-jakarta text-white">
                                  {chatUser?.username}
                                </div>
                              </div>
                            )}
                            {chatUser?.bio && (
                              <div className="space-y-2">
                                <div className="text-xs font-normal font-plus-jakarta text-tgpro-grey-6">
                                  Bio
                                </div>
                                <div className="text-xs leading-6 font-semibold font-plus-jakarta text-white">
                                  {chatUser?.bio}
                                </div>
                              </div>
                            )}
                            {chatUser?.phone_number && (
                              <div className="space-y-2">
                                <div className="text-xs font-normal font-plus-jakarta text-tgpro-grey-6">
                                  Mobile
                                </div>
                                <div className="text-xs leading-6 font-semibold font-plus-jakarta text-white">
                                  +{chatUser?.phone_number}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {rightSideLoading && (
                    <div className="absolute top-0 h-full w-full flex items-center justify-center">
                      <AiOutlineLoading3Quarters className="animate-spin text-tgpro-blue-1 h-8 w-8 z-40" />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="transition-all duration-300 ease-linear w-[76px] relative bg-tgpro-black-2"></div>
            )}
          </>
        )}
      </div>
      {addBulkTagsModalState.visible && <AddBulkTags />}
      {addBulkFollowUpsModalState.visible && <AddBulkFollowUps />}
      {forwardMessagesModalState.visible && <ForwardMessages />}
    </main>
  );
};
