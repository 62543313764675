import { useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { IoMdMenu } from "react-icons/io";
import {
  setShowBulkMessagePage,
  setShowChatSyncPage,
  setShowDraftSyncPage,
  setShowPaymentPage,
  setShowPipelinePage,
  setShowSettingsPage,
} from "../../redux/slices/authSlice";
import { setSelectedChats } from "../../redux/slices/chatSlice";

export const Menu = () => {
  const dispatch = useDispatch();

  const menuItems = [
    {
      label: "Main View",
      onClick: (dispatch: any) => {
        dispatch(setShowChatSyncPage(false));
        dispatch(setShowDraftSyncPage(false));
        dispatch(setShowPaymentPage(false));
        dispatch(setShowSettingsPage(false));
        dispatch(setShowBulkMessagePage(false));
        dispatch(setSelectedChats([]));
        dispatch(setShowPipelinePage(false));
      },
    },
    {
      label: "Sync Chats",
      onClick: (dispatch: any) => {
        dispatch(setShowChatSyncPage(true));
        dispatch(setShowDraftSyncPage(false));
        dispatch(setShowPaymentPage(false));
        dispatch(setShowSettingsPage(false));
        dispatch(setShowBulkMessagePage(false));
        dispatch(setShowPipelinePage(false));
      },
    },
    {
      label: "Sync Drafts",
      onClick: (dispatch: any) => {
        dispatch(setShowDraftSyncPage(true));
        dispatch(setShowChatSyncPage(false));
        dispatch(setShowPaymentPage(false));
        dispatch(setShowSettingsPage(false));
        dispatch(setShowBulkMessagePage(false));
        dispatch(setShowPipelinePage(false));
      },
    },
    {
      label: "Bulk Message",
      onClick: (dispatch: any) => {
        dispatch(setShowDraftSyncPage(false));
        dispatch(setShowChatSyncPage(false));
        dispatch(setShowPaymentPage(false));
        dispatch(setShowSettingsPage(false));
        dispatch(setShowBulkMessagePage(true));
      },
    },
    {
      label: "Pipeline View",
      onClick: (dispatch: any) => {
        dispatch(setShowPipelinePage(true));
        dispatch(setShowDraftSyncPage(false));
        dispatch(setShowChatSyncPage(false));
        dispatch(setShowPaymentPage(false));
        dispatch(setShowSettingsPage(false));
        dispatch(setShowBulkMessagePage(false));
      },
    },
    {
      label: "Support",
      onClick: () => {
        window.open("https://t.me/TGprosupport_bot", "_");
      },
    },
    {
      label: "Feedback",
      onClick: () => {
        window.open("https://form.typeform.com/to/MvHEV699", "_");
      },
    },
    {
      label: "Settings",
      className: "text-tgpro-red-1 bg-tgpro-red-1/15",
      onClick: (dispatch: any) => {
        dispatch(setShowSettingsPage(true));
        dispatch(setShowChatSyncPage(false));
        dispatch(setShowPaymentPage(false));
        dispatch(setShowDraftSyncPage(false));
        dispatch(setShowBulkMessagePage(false));
        dispatch(setShowPipelinePage(false));
      },
    },
  ];

  const [showUserTagsPopover, setShowUserTagsPopover] = useState(false);

  const tagsModalRef = useRef<HTMLInputElement>(null);
  useOutsideClick(tagsModalRef, () => setShowUserTagsPopover(false));

  return (
    <div className="relative" ref={tagsModalRef}>
      <div
        className="bg-tgpro-black-2 h-8 w-8 rounded-full flex items-center justify-center cursor-pointer"
        onClick={() => setShowUserTagsPopover(!showUserTagsPopover)}
      >
        <IoMdMenu className="text-tgpro-grey-1 h-4 w-4" />
      </div>
      {showUserTagsPopover && (
        <div className="p-2 absolute top-full left-0 z-[999] bg-tgpro-black-1 w-max">
          <div className="max-h-[360px] overflow-auto w-52 mt-2">
            {menuItems.map((item, index) => (
              <div key={index}>
                <div
                  className={classNames(
                    "flex items-center gap-2 relative px-4 py-2 w-full outline-none text-11 leading-13 font-normal font-plus-jakarta rounded-lg text-tgpro-grey-1 bg-tgpro-black-2 hover:bg-tgpro-blue-1/15 cursor-pointer",
                    item.className
                  )}
                  onClick={() => item.onClick(dispatch)}
                >
                  {item.label}
                </div>
                {index < menuItems.length - 1 && (
                  <div className="h-[0.5px] my-2 bg-tgpro-black-3"></div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
