import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import TdClient, { TdObject } from "tdweb";
import packageJson from "../../package.json";
import QRCodeStyling from "qr-code-styling";
import { useDispatch } from "react-redux";
import {
  setQrLoading,
  setShowChatSyncPage,
  updateAuthState,
} from "../redux/slices/authSlice";
import {
  updateChatDraftMessage,
  updateChatFolders,
  updateChatLastMessage,
  updateChatReadOutbox,
  updateChatUnreadCount,
  updateNewMessage,
} from "../redux/slices/chatSlice";
import { getBrowser, getOSName } from "../utils/constant";

// @ts-ignore
const TDLibContext = createContext<{
  tdClient: TdClient | undefined;
  isReady: boolean;
}>({
  tdClient: undefined,
  isReady: false,
});

export const TDLibContextProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();

  const [tdClient, setTdClient] = useState<TdClient>();
  const [isReady, setIsReady] = useState(false);

  const onUpdate = async (update: TdObject) => {
    // console.log({ type: update["@type"], update });
    switch (update["@type"]) {
      case "updateAuthorizationState": {
        const authorizationState: any = update.authorization_state;
        console.log({ authorizationState });
        dispatch(updateAuthState(authorizationState));

        switch (
          authorizationState!["@type" as keyof typeof authorizationState]
        ) {
          case "authorizationStateLoggingOut":
            // this.loggingOut = true;
            // if (this.chatId) {
            //     this.setChatId(0);
            // }
            break;
          case "authorizationStateWaitTdlibParameters":
            break;
          case "authorizationStateWaitEncryptionKey":
            // TdLibController.send({ '@type': 'checkDatabaseEncryptionKey' });
            break;
          case "authorizationStateWaitPhoneNumber": {
            dispatch(updateAuthState(authorizationState));
            break;
          }
          case "authorizationStateWaitCode": {
            break;
          }
          case "authorizationStateWaitOtherDeviceConfirmation":
            {
              const qrCode = new QRCodeStyling({
                width: 164,
                height: 164,
                data: authorizationState!.link,
                image: "",
                dotsOptions: {
                  color: "black",
                  type: "rounded",
                },
                backgroundOptions: {
                  color: "transparent",
                },
              });

              const canvas = document.getElementById(
                "qr-canvas"
              ) as HTMLDivElement;
              canvas.innerHTML = null as unknown as string;
              qrCode.append(canvas!);
              dispatch(setQrLoading(false));
            }
            break;
          case "authorizationStateWaitPassword":
            break;
          case "authorizationStateReady":
            // this.loggingOut = false;
            // this.setPhoneNumberRequest = null;
            // subscribeNotifications();
            const settings = JSON.parse(localStorage.getItem("settings")!);
            if (settings && Object.hasOwn(settings, "areChatsLinked"))
              dispatch(setShowChatSyncPage(!settings.areChatsLinked));
            else dispatch(setShowChatSyncPage(true));
            break;
          case "authorizationStateClosing":
            break;
          case "authorizationStateClosed":
            // this.reset();

            // if (!this.loggingOut) {
            //     document.title += ': Zzz…';

            //     TdLibController.clientUpdate({
            //         '@type': 'clientUpdateAppInactive'
            //     });
            // } else {
            //     TdLibController.init();
            // }
            break;
          default:
            break;
        }

        // this.emit(update['@type'], update);
        break;
      }
      case "updateChatIsMarkedAsUnread": {
        const { chat_id, is_marked_as_unread } = update;
        // if (chat_id === this.chatId && is_marked_as_unread) {
        //     closeChat();
        // }

        break;
      }
      case "updateConnectionState": {
        // this.connectionState = update.state;

        // this.emit(update['@type'], update);
        break;
      }
      case "updateFatalError": {
        // this.emit(update['@type'], update);

        break;
      }
      case "updateServiceNotification": {
        const { type, content } = update;

        if (!content) return;
        // if (content['@type'] === 'messageText') {
        //     const { text } = content;
        //     if (!text) return;

        //     if (text['@type'] === 'formattedText' && text.text) {
        //         switch (type) {
        //             case 'AUTH_KEY_DROP_DUPLICATE':
        //                 let result = window.confirm(text.text);
        //                 if (result) {
        //                     TdLibController.logOut();
        //                 }
        //                 break;
        //             default:
        //                 showAlert({
        //                     title: LStore.getString('AppName'),
        //                     message: text,
        //                     ok: LStore.getString('OK')
        //                 });
        //                 break;
        //         }
        //     }
        // }

        break;
      }
      case "updateChatLastMessage": {
        dispatch(updateChatLastMessage(update));
        break;
      }
      case "updateNewMessage": {
        dispatch(updateNewMessage(update));
        break;
      }
      case "updateChatReadInbox": {
        dispatch(updateChatUnreadCount(update));
        break;
      }
      case "updateChatFilters": {
        dispatch(updateChatFolders(update));
        break;
      }
      case "updateChatReadOutbox": {
        dispatch(updateChatReadOutbox(update));
        break;
      }
      case "updateChatDraftMessage": {
        dispatch(updateChatDraftMessage(update));
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    (async () => {
      const client = new TdClient({
        mode: "wasm",
        useDatabase: true,
        readOnly: false,
        logVerbosityLevel: 1,
        jsLogVerbosityLevel: "warning",
        instanceName: "tdlib_test",
        onUpdate,
      });

      await client
        ?.send({
          "@type": "setTdlibParameters",
          parameters: {
            "@type": "tdParameters",
            // use_test_dc: useTestDC,
            api_id: process.env.REACT_APP_TG_API_ID,
            api_hash: process.env.REACT_APP_TG_API_HASH,
            system_language_code: navigator.language || "en",
            device_model: getBrowser(),
            system_version: getOSName(),
            application_version: packageJson.version,
            // use_secret_chats: false,
            // use_message_database: true,
            // use_file_database: false,
            // database_directory: "/db",
            // files_directory: "/",
          },
        })
        .then((res) => console.log({ res }))
        .then((err) => console.log({ err }));
      await client?.send({ "@type": "checkDatabaseEncryptionKey" });
      setTdClient(client);
      setIsReady(true);
    })();
  }, []);

  return (
    <TDLibContext.Provider value={{ tdClient: tdClient!, isReady }}>
      {children}
    </TDLibContext.Provider>
  );
};

export default function useTDLibController() {
  return useContext(TDLibContext);
}
