import axios from "axios";
import moment from "moment";

const axiosInterceptors = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 100000,
  withCredentials: true,
});

axiosInterceptors.interceptors.request.use(async function (config) {
  return config;
});

export { axiosInterceptors };

export const getUser = () => {
  const user = localStorage.getItem("user")!;
  if (user) return JSON.parse(user);
  return {};
};

export const epochToUTC = (epoch: number) => new Date(0).setUTCSeconds(epoch);

export const getFormattedTimeFromUTC = (date: number) => {
  let currentTime = "";
  var newDate = new Date(epochToUTC(date));
  var hour = newDate.getHours();
  var minutes = newDate.getMinutes();
  var meridiem = hour >= 12 ? "PM" : "AM";
  currentTime =
    ((hour + 11) % 12) +
    1 +
    ":" +
    (minutes < 10 ? `0${minutes}` : minutes) +
    " " +
    meridiem;
  return currentTime;
};

export const getDaysDifference = (date: any) => {
  return moment(date).diff(moment().format("YYYY-MM-DD"), "days");
};

// {
//     "@type": "updateNewMessage",
//     "message": {
//         "@type": "message",
//         "id": 8220835840,
//         "sender_id": {
//             "@type": "messageSenderUser",
//             "user_id": 2051284284
//         },
//         "chat_id": 7220935794,
//         "is_outgoing": true,
//         "is_pinned": false,
//         "can_be_edited": true,
//         "can_be_forwarded": true,
//         "can_be_saved": true,
//         "can_be_deleted_only_for_self": true,
//         "can_be_deleted_for_all_users": true,
//         "can_get_statistics": false,
//         "can_get_message_thread": false,
//         "can_get_viewers": false,
//         "can_get_media_timestamp_links": false,
//         "has_timestamped_media": true,
//         "is_channel_post": false,
//         "contains_unread_mention": false,
//         "date": 1720036267,
//         "edit_date": 0,
//         "reply_in_chat_id": 0,
//         "reply_to_message_id": 0,
//         "message_thread_id": 0,
//         "ttl": 0,
//         "ttl_expires_in": 0,
//         "via_bot_user_id": 0,
//         "author_signature": "",
//         "media_album_id": "0",
//         "restriction_reason": "",
//         "content": {
//             "@type": "messageText",
//             "text": {
//                 "@type": "formattedText",
//                 "text": ".",
//                 "entities": []
//             }
//         }
//     },
//     "@client_id": 1
// }

// {
//     "@type": "updateChatLastMessage",
//     "chat_id": 7220935794,
//     "last_message": {
//         "@type": "message",
//         "id": 8220835840,
//         "sender_id": {
//             "@type": "messageSenderUser",
//             "user_id": 2051284284
//         },
//         "chat_id": 7220935794,
//         "is_outgoing": true,
//         "is_pinned": false,
//         "can_be_edited": true,
//         "can_be_forwarded": true,
//         "can_be_saved": true,
//         "can_be_deleted_only_for_self": true,
//         "can_be_deleted_for_all_users": true,
//         "can_get_statistics": false,
//         "can_get_message_thread": false,
//         "can_get_viewers": false,
//         "can_get_media_timestamp_links": false,
//         "has_timestamped_media": true,
//         "is_channel_post": false,
//         "contains_unread_mention": false,
//         "date": 1720036267,
//         "edit_date": 0,
//         "reply_in_chat_id": 0,
//         "reply_to_message_id": 0,
//         "message_thread_id": 0,
//         "ttl": 0,
//         "ttl_expires_in": 0,
//         "via_bot_user_id": 0,
//         "author_signature": "",
//         "media_album_id": "0",
//         "restriction_reason": "",
//         "content": {
//             "@type": "messageText",
//             "text": {
//                 "@type": "formattedText",
//                 "text": ".",
//                 "entities": []
//             }
//         }
//     },
//     "positions": [],
//     "@client_id": 1
// }

// {
//     "@type": "updateChatLastMessage",
//     "chat_id": 600261583,
//     "last_message": {
//         "@type": "message",
//         "id": 8292139008,
//         "sender_id": {
//             "@type": "messageSenderUser",
//             "user_id": 2051284284
//         },
//         "chat_id": 600261583,
//         "is_outgoing": true,
//         "is_pinned": false,
//         "can_be_edited": true,
//         "can_be_forwarded": true,
//         "can_be_saved": true,
//         "can_be_deleted_only_for_self": true,
//         "can_be_deleted_for_all_users": true,
//         "can_get_statistics": false,
//         "can_get_message_thread": false,
//         "can_get_viewers": false,
//         "can_get_media_timestamp_links": false,
//         "has_timestamped_media": true,
//         "is_channel_post": false,
//         "contains_unread_mention": false,
//         "date": 1720275980,
//         "edit_date": 0,
//         "reply_in_chat_id": 0,
//         "reply_to_message_id": 0,
//         "message_thread_id": 0,
//         "ttl": 0,
//         "ttl_expires_in": 0,
//         "via_bot_user_id": 0,
//         "author_signature": "",
//         "media_album_id": "0",
//         "restriction_reason": "",
//         "content": {
//             "@type": "messagePhoto",
//             "photo": {
//                 "@type": "photo",
//                 "has_stickers": false,
//                 "minithumbnail": {
//                     "@type": "minithumbnail",
//                     "width": 18,
//                     "height": 40,
//                     "data": "/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAoABIDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwDWBx1NLkGmcZNBx70wH/jRSdqKAEzz1oJ9CKO/Sg/TNACjpRQOnSigBcUm3miikIXFFFFAz//Z"
//                 },
//                 "sizes": [
//                     {
//                         "@type": "photoSize",
//                         "type": "m",
//                         "photo": {
//                             "@type": "file",
//                             "id": 1452,
//                             "size": 12178,
//                             "expected_size": 12178,
//                             "local": {
//                                 "@type": "localFile",
//                                 "path": "",
//                                 "can_be_downloaded": true,
//                                 "can_be_deleted": false,
//                                 "is_downloading_active": false,
//                                 "is_downloading_completed": false,
//                                 "download_offset": 0,
//                                 "downloaded_prefix_size": 0,
//                                 "downloaded_size": 0
//                             },
//                             "remote": {
//                                 "@type": "remoteFile",
//                                 "id": "AgACAgUAAxkDAAIe5GaJVAzJhQpd-ERxoxtlafOvmn4cAAI_xzEbtFNQVFmaN2ZU-E4FAQADAgADbQADIwQ",
//                                 "unique_id": "AQADP8cxG7RTUFRy",
//                                 "is_uploading_active": false,
//                                 "is_uploading_completed": true,
//                                 "uploaded_size": 12178
//                             }
//                         },
//                         "width": 143,
//                         "height": 320,
//                         "progressive_sizes": []
//                     },
//                     {
//                         "@type": "photoSize",
//                         "type": "x",
//                         "photo": {
//                             "@type": "file",
//                             "id": 1453,
//                             "size": 46561,
//                             "expected_size": 46561,
//                             "local": {
//                                 "@type": "localFile",
//                                 "path": "",
//                                 "can_be_downloaded": true,
//                                 "can_be_deleted": false,
//                                 "is_downloading_active": false,
//                                 "is_downloading_completed": false,
//                                 "download_offset": 0,
//                                 "downloaded_prefix_size": 0,
//                                 "downloaded_size": 0
//                             },
//                             "remote": {
//                                 "@type": "remoteFile",
//                                 "id": "AgACAgUAAxkDAAIe5GaJVAzJhQpd-ERxoxtlafOvmn4cAAI_xzEbtFNQVFmaN2ZU-E4FAQADAgADeAADIwQ",
//                                 "unique_id": "AQADP8cxG7RTUFR9",
//                                 "is_uploading_active": false,
//                                 "is_uploading_completed": true,
//                                 "uploaded_size": 46561
//                             }
//                         },
//                         "width": 358,
//                         "height": 800,
//                         "progressive_sizes": []
//                     },
//                     {
//                         "@type": "photoSize",
//                         "type": "y",
//                         "photo": {
//                             "@type": "file",
//                             "id": 1454,
//                             "size": 63352,
//                             "expected_size": 63352,
//                             "local": {
//                                 "@type": "localFile",
//                                 "path": "",
//                                 "can_be_downloaded": true,
//                                 "can_be_deleted": false,
//                                 "is_downloading_active": false,
//                                 "is_downloading_completed": false,
//                                 "download_offset": 0,
//                                 "downloaded_prefix_size": 0,
//                                 "downloaded_size": 0
//                             },
//                             "remote": {
//                                 "@type": "remoteFile",
//                                 "id": "AgACAgUAAxkDAAIe5GaJVAzJhQpd-ERxoxtlafOvmn4cAAI_xzEbtFNQVFmaN2ZU-E4FAQADAgADeQADIwQ",
//                                 "unique_id": "AQADP8cxG7RTUFR-",
//                                 "is_uploading_active": false,
//                                 "is_uploading_completed": true,
//                                 "uploaded_size": 63352
//                             }
//                         },
//                         "width": 540,
//                         "height": 1206,
//                         "progressive_sizes": [
//                             6473,
//                             16557,
//                             35075,
//                             49124
//                         ]
//                     }
//                 ]
//             },
//             "caption": {
//                 "@type": "formattedText",
//                 "text": "Image testing",
//                 "entities": []
//             },
//             "is_secret": false
//         }
//     },
//     "positions": [],
//     "@client_id": 1
// }

// {
//     "@type": "updateChatFilters",
//     "chat_filters": [
//         {
//             "@type": "chatFilterInfo",
//             "id": 2,
//             "title": "Bots",
//             "icon_name": "Custom"
//         }
//     ],
//     "@client_id": 1
// }
