import { FaCheck } from "react-icons/fa";
import classNames from "classnames";

interface ICheckboxProps {
  checked?: boolean;
  onClick?: (value: boolean) => void;
  className?: string;
}

export const Checkbox = ({ checked, onClick, className }: ICheckboxProps) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-center relative h-4 w-4 rounded-md cursor-pointer",
        checked ? "bg-tgpro-blue-1" : "border-[0.67px] border-tgpro-grey-1",
        className
      )}
      onClick={() => onClick?.(!checked)}
    >
      {checked && <FaCheck className="h-2.5 w-2.5 text-tgpro-white-1" />}
    </div>
  );
};
